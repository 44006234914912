'use strict';

import { Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { FilesService } from 'src/app/services/FilesService';
import { SolarPvSurplusService } from 'src/app/services/cadastre-solaire/cadastre-solaire-pv-surplus.service';
import { SolarService } from 'src/app/services/cadastre-solaire/cadastre-solaire.service';
import { UsefulService } from 'src/app/services/UsefulService';

@Component({
    selector: 'cadastreSolairePvSurplusImport',
    templateUrl: './cadastre-solaire-pv-surplus-import.template.html',
    styleUrls: ['./cadastre-solaire-pv-surplus-import.component.scss'],
})
export class CadastreSolairePvSurplusImportComponent implements OnInit {
    public importedElectricLoad: {
        isLoading: boolean;
        start: Date;
        end: Date;
        fillRate: number;
        isValid: boolean;
        error: string;
        load: Array<{ date: Date; value: number }>;
        energy: number;
        subscribedPower: number;
    };

    public fillRateMin = 80;

    constructor(
        private notification: ToastrService,
        @Inject(FilesService) public filesService: FilesService,
        @Inject(SolarPvSurplusService)
        public solarPvSurplusService: SolarPvSurplusService,
        @Inject(SolarService) public solarService: SolarService,
        @Inject(UsefulService) public usefulService: UsefulService,
    ) {}

    ngOnInit(): void {
        this.importedElectricLoad = JSON.parse(
            JSON.stringify(this.solarPvSurplusService.importedElectricLoad),
        );
        // this.importedElectricLoad.isValid = null;
        // this.importedElectricLoad.error = '';
    }

    async importConsumptionFile(event: any, fileInput: HTMLInputElement) {
        try {
            this.importedElectricLoad.isLoading = true;
            this.importedElectricLoad.isValid = null;
            this.importedElectricLoad.error = '';

            const file = event.target.files[0];

            if (!file) {
                this.importedElectricLoad.isLoading = false;
                return;
            }
            const electricLoad = await this._convertToJson(file);
            await this._analyseElectricLoad(electricLoad);

            this.importedElectricLoad.load = electricLoad;
            this.importedElectricLoad.isValid = true;
        } catch (error) {
            console.error('Error importConsumptionFile', error);
            this.notification.error(`Le fichier importé n'a pas pu être analysé.`);
            this.importedElectricLoad.isValid = false;
        } finally {
            this.importedElectricLoad.isLoading = false;
            fileInput.value = '';
        }
    }

    private async _convertToJson(file: any) {
        try {
            const sheetToJsonConverters = [
                this.solarPvSurplusService.convertImportElectricLoadType1ToJson,
                this.solarPvSurplusService.convertImportElectricLoadType2ToJson,
                this.solarPvSurplusService.convertImportElectricLoadType3ToJson,
            ];
            const electricLoad = await this.filesService.readXlsxFile(file, sheetToJsonConverters);
            return electricLoad;
        } catch (error) {
            console.error('Error _convertToJson', error);
            this.importedElectricLoad.error = "Le fichier CSV importé n'a pas le format adéquat.";
            throw new Error(error);
        }
    }

    private async _analyseElectricLoad(electricLoad: Array<{ date: Date; value: number }>) {
        try {
            const info = await this.solarPvSurplusService.analyseElectricLoad(electricLoad);
            const fillrate = info.indicators_before_interpolation.completness_ratio * 100;
            const start = info.period.start_date;
            const end = info.period.end_date;
            const annualConsumption = info.indicators_after_interpolation.annual_consumption;
            const subscribedPower = info.indicators_after_interpolation.demand_peak;

            this.importedElectricLoad.fillRate = this.usefulService.round(fillrate);
            this.importedElectricLoad.start = new Date(start);
            this.importedElectricLoad.end = new Date(end);
            this.importedElectricLoad.energy = this.usefulService.ceil(annualConsumption);
            this.importedElectricLoad.subscribedPower = this.usefulService.ceil(subscribedPower);
        } catch (error) {
            console.error('Error _analyseElectricLoad', error);
            this.importedElectricLoad.error = "Le fichier importé n'a pas pu être analysé.";
            throw new Error(error);
        }
    }

    cancel() {
        const isImportedElectricLoadDefined =
            this.solarPvSurplusService.importedElectricLoad.load.length > 0;
        if (isImportedElectricLoadDefined) {
            this.solarPvSurplusService.userPvData.electricity.energyConsumed.value = null;
            this.solarPvSurplusService.userPvData.electricity.subscribedPower.value = null;
        }
        this.solarPvSurplusService.initImportedElectricLoad();
        this.solarPvSurplusService.updateStage('equipmentsStage');
    }

    public goToStage(stage: string) {
        this.solarPvSurplusService.importedElectricLoad = this.importedElectricLoad;
        this.solarPvSurplusService.updateStage(stage);
    }
}
