import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Feature } from 'geojson';
import { Indicator } from 'src/app/models/Indicator';
import { TerritoryScale } from 'src/app/models/TerritoryTypes';

import { CatalogService } from 'src/app/services/catalog.service';
import { DataService } from 'src/app/services/DataService';
import { FilesService } from 'src/app/services/FilesService';
import { GranularityService } from 'src/app/services/granularity.service';
import { ImportedDataService } from 'src/app/services/ImportedDataService';
import { ShapeFileService } from 'src/app/services/ShapeFileService';
import { TerService } from 'src/app/services/TerService';

@Component({
    selector: 'importModal',
    templateUrl: './import-modal.template.html',
    styleUrls: ['./import-modal.component.css'],
})
export class ImportModalComponent {
    isLoading: boolean;
    step = 'init';
    previewMaxRowCount: number = 3;

    rows = [];
    unmatchedRows = [];
    header = [];
    failedSetup = [];

    error = { msg: '' };
    loadedLinesCount = {
        err: 0,
        ok: 0,
    };
    dataToPlot: any;
    selected = {
        granularity: { label: '', scale: null },
        year: {
            value: null,
            options: [],
        },
    };
    geojsonByShape: {};
    geojson: {};
    totalRowCount: number;
    territoryScales: any;
    indicatorShapes: { indicatorLabel: string; value: string; label: string }[] = [];
    shapeOptions = [
        { value: 'poly', label: 'Aplat de couleur' },
        { value: 'point_proportional', label: 'Cercle proportionnel' },
        { value: 'point', label: 'Point de couleur' },
    ];
    territoryIds: string[] = [];

    urlGeojson =
        'https://opendata.paris.fr/api/records/1.0/search/?dataset=les-arbres&rows=10000&facet=typeemplacement&facet=domanialite&facet=arrondissement&facet=libellefrancais&facet=genre&facet=espece';
    territoryLabels: string;

    constructor(
        private http: HttpClient,
        private modalService: NgbModal,
        public activeModal: NgbActiveModal,
        @Inject(CatalogService) private catalogService: CatalogService,
        @Inject(DataService) private dataService: DataService,
        @Inject(FilesService) private filesService: FilesService,
        @Inject(GranularityService) private granularityService: GranularityService,
        @Inject(ImportedDataService) private importedDataService: ImportedDataService,
        @Inject(ShapeFileService) private shapeFileService: ShapeFileService,
        @Inject(TerService) private terService: TerService,
    ) {}

    async uploadFiles(event: any) {
        const file = event.target.files[0];
        if (!file) {
            return;
        }

        this._init();
        this.isLoading = true;
        this.step = 'loaded';

        try {
            const rows = await this.filesService.readCsv(file);
            this.header = rows[0].map((label: string) => label.trim());
            this._loadBody(rows);

            if (rows.length <= 1) {
                this.error.msg = "Aucune données n'a été détecter dans le fichier.";
                return;
            }

            if (this.header.length <= 1) {
                this.error.msg = "L'en-tête du fichier ne contient pas les données attendues.";
                return;
            }

            // check header duplicate column
            const duplicateColumns = this.header.filter(
                (column, index) => this.header.indexOf(column) !== index,
            );
            if (duplicateColumns.length) {
                this.error.msg = "L'en-tête du fichier contient des champs dupliqués.";
                return;
            }

            // check wrong size rows
            const wrongSizeRows = this.rows.filter((row) => row.length != this.header.length);
            this.loadedLinesCount.err = wrongSizeRows.length;
            this.loadedLinesCount.ok = this.rows.length - wrongSizeRows.length;
            if (wrongSizeRows.length) {
                this.loadedLinesCount.err = wrongSizeRows.length;
                this.error.msg =
                    'Des lignes du fichier ne comportent pas le nombre de champ attendu.';
                // if (wrongSizeRows.length == 1) {
                //     this.error.msg = `${wrongSizeRows.length} ligne du fichier ne comporte pas le nombre de champs attendu.`;
                // } else {
                //     this.error.msg = `${wrongSizeRows.length} lignes du fichier ne comportent pas le nombre de champ attendu.`;
                // }
                return;
            }

            // check if numeric value - not first column as it can have letters in it
            const wrongFomatRows = this.rows.filter((row) =>
                row.slice(1).some((value: string) => isNaN(Number(value))),
            );
            this.loadedLinesCount.err = wrongFomatRows.length;
            this.loadedLinesCount.ok = this.rows.length - wrongFomatRows.length;
            if (wrongFomatRows.length) {
                this.error.msg = 'Des lignes du fichier contiennent des valeurs non numériques.';
                return;
            }

            // check duplicate insee code
            const codes = this.rows.map((row) => row[0]);
            const duplicateCodes = codes.filter((code, index) => codes.indexOf(code) !== index);
            this.loadedLinesCount.err = duplicateCodes.length;
            this.loadedLinesCount.ok = this.rows.length - duplicateCodes.length;
            if (duplicateCodes.length) {
                this.error.msg = 'La première colonne contient des champs dupliqués.';
                return;
            }
        } catch (error) {
            console.error('Error uploadFiles', error);
            this.error.msg = 'Une erreur est survenue. Impossible charger le fichier.';
            this.step = 'init';
        } finally {
            event.target.value = null;
            this.isLoading = false;
        }
    }

    private _loadBody(rows: string[][]) {
        this.dataToPlot = this.header.slice(1).reduce((dataToPlot, label) => {
            return { ...dataToPlot, [label]: {} };
        }, {});

        this.territoryIds = [];

        const body = rows.slice(1).filter((row) => !(row.length == 1 && row[0] == ''));
        this.rows = body.map((row) => {
            const territoryId = row[0];
            this.territoryIds.push(territoryId);

            const values = row.slice(1);

            const cleanedRow = values.reduce(
                (cleanedRow: any[], rawValue: string, index: number) => {
                    const value = parseFloat(rawValue.replace(',', '.'));
                    const label = this.header[index + 1];

                    this.dataToPlot[label][territoryId] = { value: value };
                    cleanedRow.push(value || rawValue);
                    return cleanedRow;
                },
                [territoryId],
            );

            return cleanedRow;
        });
    }

    goToSetup() {
        this.step = 'setup';
        this.indicatorShapes = this.header.slice(1).map((indicatorLabel: string) => ({
            indicatorLabel: indicatorLabel,
            value: 'poly',
            label: 'Aplat de couleur',
        }));
    }

    updateGranularity() {
        const label = this.selected.granularity.label;
        this.selected.granularity.scale = this.territoryScales.find(
            (scale: TerritoryScale) => scale.labelLong == label,
        );
    }

    back() {
        this._init();
    }

    isSetupFailed() {
        const year = this.selected.year.value;
        const granularity = this.selected.granularity.label;

        return (
            this.unmatchedRows.length &&
            this.failedSetup.some((setup) => setup.year == year && setup.granularity == granularity)
        );
    }

    async checkAndPlot() {
        this.isLoading = true;
        try {
            // check
            await this._checkDataFitGeojsons();

            if (this.unmatchedRows.length) {
                this.failedSetup.push({
                    year: this.selected.year.value,
                    granularity: this.selected.granularity.label,
                });
                return;
            }

            const indicators: Indicator[] = this.header
                .slice(1)
                .map((label: string) => this._plotIndicator(label));

            this.catalogService.addImportedIndicators(indicators, 'Import CSV');
            this.terService.updateGeoYear(this.selected.year.value);

            this.modalService.dismissAll();
        } catch (error) {
            console.error('Error checkAndPlot', error);
            this.step = 'setup';
            this.error = {
                msg: 'Une erreur inconnue est survenue.',
            };
            this.unmatchedRows = this.rows;
            this.failedSetup.push({
                year: this.selected.year.value,
                granularity: this.selected.granularity.label,
            });
        } finally {
            this.isLoading = false;
        }
    }

    async uploadFromApi() {
        try {
            const result: any = await lastValueFrom(this.http.get(this.urlGeojson));

            if (result.records) {
                const features: Feature[] = result.records.map((feature: any) => {
                    feature.type = 'Feature';
                    feature.properties = feature.fields;
                    return feature;
                });

                this.shapeFileService.addIndicatorsFromGeojson({
                    features: features,
                    type: 'FeatureCollection',
                    fileName: 'GeoJson Importé',
                });
            } else {
                this.shapeFileService.addIndicatorsFromGeojson(result.records);
            }
            this.modalService.dismissAll();
        } catch (error) {
            console.error('Error importFromUrl', error);
        }
    }

    private async _checkDataFitGeojsons() {
        this.isLoading = true;
        this.unmatchedRows = [];
        this.totalRowCount = this.rows.length;
        this.geojson = {};

        await this._getGeojsons();

        for (let label in this.dataToPlot) {
            for (let territoryId in this.dataToPlot[label]) {
                const indicatorShape = this.indicatorShapes.find(
                    (shape) => shape.indicatorLabel == label,
                );
                const shape = indicatorShape.value;
                const geojson = this.geojsonByShape[shape];
                if (!this.geojson[shape]) {
                    this.geojson[shape] = {
                        type: 'FeatureCollection',
                        features: [],
                    };
                }

                const feature = geojson.features.find(
                    (feature: GeoJSON.Feature) => feature.properties.id_ter == territoryId,
                );
                if (feature) {
                    this.geojson[shape].features.push(feature);
                } else {
                    const isAlreadyUnmatched = this.unmatchedRows.some(
                        (row) => row[0] == territoryId,
                    );
                    if (!isAlreadyUnmatched) {
                        this.unmatchedRows.push(this.rows.find((row) => row[0] == territoryId));
                    }
                }
            }
        }
        this.step = 'checked';
    }

    private async _getGeojsons() {
        const shapes = this.indicatorShapes
            .map((indicatorShape) => indicatorShape.value)
            .filter((value, index, self) => self.indexOf(value) === index);

        try {
            const promises = shapes.map(this._getGeojsonByShape.bind(this));
            await Promise.all(promises);
        } catch (error) {
            console.error('Error _getGeojsons', error);
            throw error;
        }
    }

    private async _getGeojsonByShape(shape: string) {
        const scale = this.selected.granularity.scale;
        const year = this.selected.year.value;
        this.geojsonByShape[shape] = await this.importedDataService.getGeojsonByTerritoryIds(
            scale,
            shape,
            this.territoryIds,
            year,
        );
    }

    private _plotIndicator(label: string) {
        const indicatorShape = this.indicatorShapes.find((shape) => shape.indicatorLabel == label);
        const shape = indicatorShape.value;
        const year = this.selected.year.value;

        const indicatorData = this.dataService.addActiveIndicatorFromFile(year);
        indicatorData.active = false;
        indicatorData.form = shape;
        indicatorData.isCustomTerritory = false;
        indicatorData.granularity = this.selected.granularity.scale;
        indicatorData.availableGranularities = [this.selected.granularity.scale];

        indicatorData.libelle_indic_short = label;
        indicatorData.libelle_indic_complet = label;

        this.importedDataService.create(
            indicatorData.id_indicateur,
            this.dataToPlot[label],
            indicatorData,
            this.geojson[shape],
        );

        return indicatorData;
    }

    private _init() {
        this.isLoading = false;
        this.step = 'init';

        this.error = {
            msg: '',
        };
        this.loadedLinesCount = {
            ok: 0,
            err: 0,
        };

        this.territoryIds = [];
        this.dataToPlot = {};
        this.geojsonByShape = {};
        this.totalRowCount = 0;

        this.rows = [];
        this.unmatchedRows = [];
        this.failedSetup = [];
        this.header = [];

        this.geojson = null;

        const territoryScaleOrder = this.terService.territoryScale.order;
        this.territoryScales = this.terService.territoryScales.filter(
            (t) => t.order <= territoryScaleOrder,
        );
        this.territoryScales.sort((a: TerritoryScale, b: TerritoryScale) => b.order - a.order);
        this.territoryLabels = this.terService.territories.map((t) => t.label).join(', ');

        // const defaultGranularity = this._setDefaultGranularity();
        const defaultGranularity = this.terService.territoryScale;

        const year = this.terService.geoYear;
        const yearOptions = this.terService.geoYears.map((year: number) => ({
            label: year,
            value: year,
        }));

        this.selected = {
            granularity: {
                label: defaultGranularity.labelLong,
                scale: defaultGranularity,
            },
            year: {
                value: year,
                options: yearOptions,
            },
        };
    }

    private _setDefaultGranularity() {
        const territoryScaleOrder = this.terService.territoryScale.order;

        const defaultGranularity = this.granularityService.findNearestSmaller(
            this.terService.territoryScales,
            territoryScaleOrder,
        );
        return this.terService.territoryScale;
    }
}
