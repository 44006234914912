('use strict');

import { Inject, Injectable } from '@angular/core';
import { RestService } from 'src/app/services/RestService';
import { User } from 'src/app/models/User';
import { LocalStorageService } from './local-storage.service';
import { PlausibleAnalyticsService } from 'src/app/services/plausible-analytics.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public connexion = 0;
    public user: User = {};

    constructor(
        private router: Router,
        @Inject(PlausibleAnalyticsService)
        private plausibleAnalyticsService: PlausibleAnalyticsService,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(RestService) private restService: RestService,
    ) {}

    async logIn(credentials: { login: string; password: string }) {
        this.clear();

        try {
            const data = await this.restService.logIn(credentials);
            this.connexion = 1;
            this.user = data.user;

            this.plausibleAnalyticsService.trackEvent('Connexion', {
                utilisateur_id: this.user.id,
            });

            this.localStorageService.set('token', data.token);
            this.localStorageService.set('user', data.user);
            this.localStorageService.set('logo', data.user.group.logo);
            this.localStorageService.set('name_site', data.user.group.name_site);
            this.localStorageService.set('prosper_url', data.user.group.prosper_url);

            this.localStorageService.set('profile', 'siterre');

            if (this.user.d_last_con === null) {
                //Forcer le changement du mot de passe
                return this.user.id;
            }
        } catch (error) {
            this.connexion = 0;
            console.error('Error logIn', error);
            throw error;
        }
    }

    async externalLogIn(group: string, token: string) {
        this.clear();

        try {
            const data = await this.restService.externalLogIn(group, token);
            // sauvegarde du token
            this.localStorageService.set('prosperToken', token);
            this.localStorageService.set('prosperGroup', group);
            this.localStorageService.set('token', data.token);
            this.localStorageService.set('user', data.user);
            this.localStorageService.set('logo', data.user.group.logo);
            this.localStorageService.set('name_site', data.user.group.name_site);
            this.localStorageService.set('prosper_url', data.user.group.prosper_url);
            this.connexion = 1;
            this.user = data.user;
            await this.getUserPreferences();
            this.router.navigate(['main']);
            return;
        } catch (error) {
            this.connexion = 0;
            throw error;
        }
    }

    logOut() {
        this.clear();
        this.router.navigate(['/']);
    }

    checkRouting(role: number) {
        if ((!this.user || !this.user.id) && this.localStorageService.get('user')) {
            this.user = this.localStorageService.get('user');
            this.connexion = 1;
        }

        if (!this.connexion || !this.user) {
            this.logOut();
            return false;
        } else if (role < this.user.role) {
            this.router.navigate(['/404']);
            return false;
        }
        return true;
    }

    clear() {
        this.connexion = 0;
        this.user = {};
        this.localStorageService.remove('token');
        this.localStorageService.remove('prosperToken');
        this.localStorageService.remove('prosperGroup');
        this.localStorageService.remove('user');
    }

    async getUserPreferences() {
        try {
            const preferences = await this.restService.getUserPreference();
            this.localStorageService.set('preferences', preferences);
        } catch (error) {
            console.error('Error getUserPreferences', error);
            throw error;
        }
    }
}
