'use strict';

import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    Input,
    OnInit,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Control, ControlPosition, DomUtil, Map } from 'leaflet';
import { NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, OperatorFunction, Subject, combineLatest, merge, of } from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    startWith,
    switchMap,
    tap,
} from 'rxjs/operators';
import * as L from 'leaflet';

import { Indicator } from 'src/app/models/Indicator';
import { Territory, TerritorySearchBar } from 'src/app/models/TerritoryTypes';

import { ConfirmationModalComponent } from '../../modals/confirmation/confirmation-modal.component';

import { EventService } from 'src/app/services/event.service';
import { LoaderService } from 'src/app/services/LoaderService';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MapService } from 'src/app/services/map.service';
import { PlausibleAnalyticsService } from 'src/app/services/plausible-analytics.service';
import { PlotIndicatorService } from 'src/app/services/plotIndicator/plot-indicator.service';
import { SearchService } from 'src/app/services/search.service';
import { TerService } from 'src/app/services/TerService';
import { TranslationService } from 'src/app/services/translation.service';
import { UsefulService } from 'src/app/services/UsefulService';

@UntilDestroy()
@Component({
    selector: 'search-bar',
    templateUrl: './search-bar.template.html',
    styleUrls: ['./search-bar.component.scss'],
})
export class SearchComponent implements AfterViewInit, OnDestroy, OnInit {
    public searchBarControl: Control;
    @Input() position: ControlPosition = 'topleft';

    @ViewChild('instance', { static: true }) instance: NgbTypeahead;
    @ViewChild('instance', { read: ElementRef }) typeahead: ElementRef;

    focus$ = new Subject<string>();
    click$ = new Subject<string>();

    private territories: TerritorySearchBar[] = [];
    public areAddressAndParcelFound: boolean;
    public areAddressOrParcelAndTerritoryFound: boolean;
    public placeholder: string;

    public isDisabled: boolean;
    public isAvailable: boolean;
    public isSearchBarAvailable: boolean;
    public isSearchBarOpen: boolean;
    public isSearchGeolocationAvailable: boolean;
    private isPopupOpen: boolean = false;

    public isAnyResultFound: boolean;

    constructor(
        private modalService: NgbModal,
        private notification: ToastrService,
        @Inject(EventService) private eventService: EventService,
        @Inject(LoaderService) private loaderService: LoaderService,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(MapService) private mapService: MapService,
        @Inject(PlausibleAnalyticsService)
        private plausibleAnalyticsService: PlausibleAnalyticsService,
        @Inject(PlotIndicatorService) private plotIndicatorService: PlotIndicatorService,
        @Inject(SearchService) private searchService: SearchService,
        @Inject(TerService) private terService: TerService,
        @Inject(TranslationService) private translationService: TranslationService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {}

    ngOnInit(): void {
        this.searchService.setIsSearchAvailable();
        this.isSearchGeolocationAvailable = this.searchService.isSearchGeolocationAvailable;

        this.isAvailable =
            this.isSearchGeolocationAvailable ||
            this.searchService.isSearchAddressAvailable ||
            this.searchService.isSearchParcelAvailable ||
            this.searchService.isSearchTerritoryAvailable;

        this.isSearchBarAvailable = [
            this.searchService.isSearchAddressAvailable,
            this.searchService.isSearchParcelAvailable,
            this.searchService.isSearchTerritoryAvailable,
        ].some((search) => search === true);

        this.isSearchBarOpen = this.isSearchGeolocationAvailable && !this.isSearchBarAvailable;

        this._initControl();
        this._listenToEvents();
    }

    ngAfterViewInit(): void {
        if (!this.isSearchBarAvailable) {
            return;
        }

        const inputElement = this.typeahead.nativeElement as HTMLInputElement;
        // inputElement.addEventListener('blur', () => (this.isPopupOpen = false));
        // inputElement.addEventListener('focus', () => {
        //     this.isPopupOpen = false;
        //     this.search(new Observable<string>((observer) => observer.next('')));
        // });

        // add events to prevent map from dragging when dragging from inside the input
        const map = this.mapService.map;
        inputElement.addEventListener('mousedown', () => {
            map.dragging.disable();
            L.DomEvent.disableClickPropagation(inputElement);
        });
        inputElement.addEventListener('mouseup', () => {
            map.dragging.enable();
        });
        map.addEventListener('mouseup', () => {
            map.dragging.enable();
        });
        // this.click$.subscribe((value) => {
        // console.log(value);
        // });
    }

    ngOnDestroy() {
        if (this.searchBarControl) {
            this.mapService.map.removeControl(this.searchBarControl);
        }
    }

    private _initControl() {
        this._setPlaceholder();

        if (this.isAvailable) {
            this.mapService.removeMainControls();
            this._initSearchBarControl();
            this.mapService.setMainControls();
        }
    }

    private _setPlaceholder() {
        this.isDisabled = false;
        const items = [];

        if (this.searchService.isSearchAddressAvailable) {
            items.push('une adresse');
        }
        if (this.searchService.isSearchParcelAvailable) {
            items.push('une parcelle');
        }
        if (this.searchService.isSearchTerritoryAvailable) {
            const indicatorPlots = Object.values(this.plotIndicatorService.plotedIndicators);
            const territoryIndicatorPlots = indicatorPlots.filter(
                (indicator: Indicator) => indicator.vector_base === 'territories',
            );
            const isBuildingGranularity = territoryIndicatorPlots.some(
                (indicator: Indicator) => indicator.granularity.typeId == 1,
            );

            if (!!territoryIndicatorPlots.length && !isBuildingGranularity) {
                items.push('un territoire');
            }
        }

        if (items.length > 1) {
            this.placeholder = `Rechercher ${items.slice(0, -1).join(', ')} ou ${
                items[items.length - 1]
            }`;
        } else if (items.length == 1) {
            this.placeholder = `Rechercher ${items[0]}`;
        } else {
            if (this.searchService.isSearchTerritoryAvailable) {
                this.placeholder = 'Pour rechercher un territoire, afficher un indicateur';
                this.isDisabled = true;
            }
        }
    }

    private _initSearchBarControl() {
        let SearchBarControl = Control.extend({
            onAdd(map: Map) {
                const element = DomUtil.get('search-bar');
                L.DomEvent.disableClickPropagation(element);
                return element;
            },
            onRemove(map: Map) {},
        });
        this.searchBarControl = new SearchBarControl({
            position: this.position,
        }).addTo(this.mapService.map);
    }

    private _listenToEvents() {
        this.eventService.indicatorPloted.pipe(untilDestroyed(this)).subscribe(() => {
            this.isSearchBarOpen = true;
            this._updateTerritories();
        });

        this.eventService.indicatorUnploted
            .pipe(untilDestroyed(this))
            .subscribe(() => this._updateTerritories());
    }

    private async _updateTerritories() {
        try {
            this._setPlaceholder();

            const isSearchTerritoryAvailable = this.searchService.isSearchTerritoryAvailable;
            this.territories = [];

            if (isSearchTerritoryAvailable) {
                const indicatorPlots = Object.values(this.plotIndicatorService.plotedIndicators);
                const territoryIndicatorsPloted = indicatorPlots.filter(
                    (indicator: Indicator) => indicator.vector_base === 'territories',
                );

                const territories = territoryIndicatorsPloted.reduce(
                    (territories: TerritorySearchBar[], indicator: Indicator) => {
                        const territory = indicator.geojson.features.map((feature) => ({
                            id: feature.properties.id_ter,
                            id_ter: feature.properties.id_ter,
                            lib_ter: feature.properties.lib_ter,
                            label: feature.properties.lib_ter,
                            labelId: `${feature.properties.lib_ter} (${feature.properties.id_ter})`,
                            type: indicator.granularity.labelLong,
                        }));
                        territories.push(...territory);
                        return territories;
                    },
                    [],
                );

                this.territories = this.usefulService.removeDuplicateObjects(
                    territories as TerritorySearchBar[],
                );
                this.usefulService.sortAlphabetically(this.territories, 'label');

                // const isTerritoryIndicatorPloted = territoryIndicatorsPloted.length > 0;
                // if (isTerritoryIndicatorPloted) {
                //     const data = indicatorPlots
                //         .filter(
                //             (indicator: Indicator) =>
                //                 !['none', 'solaire'].includes(indicator.vector_base),
                //         )
                //         .filter((indicator: Indicator) => indicator.granularity.typeId != 1)
                //         .map((indicator: Indicator) => {
                //             const type = indicator.granularity.labelLong;
                //             const promise = this.terService.getTerritoryLabelByYear(
                //                 this.terService.geoYear,
                //                 indicator.granularity.type,
                //                 this.terService.territoryScale.type,
                //                 this.terService.territories.map((t) => t.id),
                //             );
                //             return {
                //                 type: type,
                //                 promise: promise,
                //             };
                //         });

                //     const results = await Promise.all(data.map((d) => d.promise));

                //     const territories = results.map((result: any, index: number) => {
                //         result.forEach((r: any) => (r.type = data[index].type));
                //         this.usefulService.sortAlphabetically(result, 'label');
                //         return result;
                //     });
                //     this.territories = this.usefulService.removeDuplicateObjects(
                //         territories.flat(),
                //     );

                //     const indicatorTerritoryIds = indicatorPlots.map((indicatorPlot: Indicator) =>
                //         indicatorPlot.geojson.features.map((feature) => feature.properties.id_ter),
                //     );
                //     const uniqueIndicatorTerritoryIds = this.usefulService.removeDuplicateObjects(
                //         indicatorTerritoryIds.flat(),
                //     );
                //     this.territories = this.territories.filter((territory) =>
                //         uniqueIndicatorTerritoryIds.includes(territory.id_ter),
                //     );
                //     console.log('territories', this.territories);
                // }
            }
        } catch (error) {
            console.error('Error _updateTerritories', error);
            this.notification.error(
                'Une erreur est survenue. Impossible de charger la liste des territoires.',
            );
        }
    }

    toggleSearchBar() {
        this.isSearchBarOpen = !this.isSearchBarOpen;

        if (this.isSearchBarOpen) {
            setTimeout(() => this.typeahead.nativeElement.focus(), 0);
        }
    }

    onMouseWheel(event: WheelEvent) {
        event.stopPropagation();
    }

    search: OperatorFunction<
        string,
        readonly { label: string; type: string; source: string; number: number }[]
    > = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.isPopupOpen));
        const inputFocus$ = this.focus$;

        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            switchMap((term) => {
                // if (term.length < 3) {
                //     return [];
                // }
                const territoryOptions = this._setTerritoryOptions(term);
                const searchAddress = this._searchAddress(term).pipe(startWith([]));
                const searchParcel = this._searchParcel(term).pipe(startWith([]));

                return combineLatest([searchAddress, searchParcel]).pipe(
                    map(([addressOptions, parcelOptions]) => {
                        const options = addressOptions
                            .concat(parcelOptions)
                            .concat(territoryOptions);

                        this.areAddressAndParcelFound =
                            options.some((option: any) => option.source == 'address') &&
                            options.some((option: any) => option.source == 'parcel');

                        this.areAddressOrParcelAndTerritoryFound =
                            options.some((option: any) =>
                                ['address', 'parcel'].includes(option.source),
                            ) && options.some((option: any) => option.source == 'territory');

                        this.isAnyResultFound = options.length > 0;
                        if (this.isAnyResultFound && term.length >= 3) {
                            options.push({ isLast: true });
                        }
                        return options;
                    }),
                );
            }),
            tap(() => this._manageScrolling()),
        );
    };

    private _setTerritoryOptions(term: string) {
        if (!this.searchService.isSearchTerritoryAvailable) {
            return [];
        }

        if (term.length < 3) {
            return [];
        }

        const territories =
            term === ''
                ? this.territories
                : this.territories.filter(
                      (territory) =>
                          this.searchService
                              .toNoCase(territory.label)
                              .indexOf(this.searchService.toNoCase(term)) > -1 ||
                          this.searchService
                              .toNoCase(territory.id)
                              .indexOf(this.searchService.toNoCase(term)) > -1,
                  );

        if (!territories.length) {
            return [];
        }

        // display only first five results
        territories.splice(5);

        const territoryOptions = territories.map((territory, index) => ({
            label: territory.labelId,
            type: territory.type,
            source: 'territory',
            number: index + 1,
            data: territory,
        }));
        // this.usefulService.sortAlphabetically(territoryOptions, 'label');

        const options = [
            {
                label: 'Territoires',
                type: null,
                source: 'territory',
                number: 0,
            },
        ].concat(territoryOptions);
        return options;
    }

    private _searchAddress(term: string) {
        if (!this.searchService.isSearchAddressAvailable) {
            return of([]);
        }

        return this.searchService.searchAddress(term).pipe(
            map((features) => this._setAddressOptions(features)),
            catchError((error) => {
                console.error('Error _searchAddress', error);
                this.notification.warning(
                    'Une erreur est survenue. Impossible de charger la liste des adresses.',
                );
                return of([]);
            }),
        );
    }

    private _setAddressOptions(features: GeoJSON.Feature[]) {
        const addresseOptions = features.map((feature: GeoJSON.Feature, index: number) => ({
            label: feature.properties.label,
            type: this.translationService.toFR(feature.properties.type),
            source: 'address',
            number: index + 1,
            data: feature,
        }));

        if (!addresseOptions.length) {
            return [];
        }

        const options = [
            {
                label: 'Adresses',
                type: null,
                source: 'address',
                number: 0,
            },
        ].concat(addresseOptions);

        return options;
    }

    private _searchParcel(term: string) {
        if (!this.searchService.isSearchParcelAvailable) {
            return of([]);
        }

        return this.searchService.searchParcel(term).pipe(
            map((features) => this._setParcelOptions(features)),
            catchError((error) => {
                console.error('Error _searchParcel', error);
                this.notification.warning(
                    'Une erreur est survenue. Impossible de charger la liste des parcelles.',
                );
                return of([]);
            }),
        );
    }

    private _setParcelOptions(features: GeoJSON.Feature[]) {
        const parcelOptions = features.map((feature: GeoJSON.Feature, index: number) => ({
            label: `${feature.properties.label} (code commune : ${feature.properties.citycode})`,
            type: null,
            source: 'parcel',
            number: index + 1,
            data: feature,
        }));

        if (!parcelOptions.length) {
            return [];
        }

        const options = [
            {
                label: 'Parcelles',
                type: null,
                source: 'parcel',
                number: 0,
            },
        ].concat(parcelOptions);
        return options;
    }

    private _manageScrolling() {
        setTimeout(() => {
            const optionsListElement = window.document.getElementsByClassName(
                'dropdown-menu search-bar-options',
            );
            if (optionsListElement.length) {
                optionsListElement[0].removeEventListener('weel', this._handleScrolling);
                optionsListElement[0].addEventListener('wheel', this._handleScrolling, {
                    passive: true,
                });
            }
        }, 0);
    }

    private _handleScrolling(event: WheelEvent) {
        event.stopPropagation();
    }

    formatter(x: any) {
        return x.label;
    }

    async onOptionSelected(item: any) {
        const source = item.source;
        const data = item.data;

        if (source == 'address') {
            const user = this.localStorageService.get('user');
            this.plausibleAnalyticsService.trackEvent('Adresse', {
                utilisateur_id: user.id,
                Adresse: data.properties.label,
                Commune: data.properties.city,
                Source: 'Recherche',
            });
        }

        if (['address', 'parcel'].includes(source)) {
            this._manageSelectAddress(data);
        } else {
            const indicatorPlots = Object.values(this.plotIndicatorService.plotedIndicators);
            this.searchService.selectTerritory(data, indicatorPlots);
        }
    }

    async geolocalizeMe() {
        const successCallback = async (latitude: number, longitude: number) => {
            try {
                const features = await this.searchService.reverseAddress(latitude, longitude);
                const feature = features[0];
                await this._manageSelectAddress(feature);

                const user = this.localStorageService.get('user');
                this.plausibleAnalyticsService.trackEvent('Adresse', {
                    utilisateur_id: user.id,
                    Adresse: feature.properties.label,
                    Commune: feature.properties.city,
                    Source: 'Géolocalisation',
                });
            } catch (error) {
                console.error('Error geolocalizeMe', error);
                throw error;
            }
        };
        const errorCallback = () => this.notification.error('La géolocalisation est indisponible.');

        try {
            this.searchService.readGeolocation(successCallback, errorCallback);
        } catch (error) {
            console.error('Error geolocalizeMe', error);
            this.notification.error('La géolocalisation est indisponible.');
        }
    }

    private async _manageSelectAddress(feature: GeoJSON.Feature) {
        const cityCode = feature.properties.citycode;
        const longitude = (feature.geometry as GeoJSON.Point).coordinates[0];
        const latitude = (feature.geometry as GeoJSON.Point).coordinates[1];

        const newTerritory = await this.terService.getTerritory(cityCode, longitude, latitude);
        const isAlreadyDefined = this.terService.territories.some(
            (territory) => territory.id == newTerritory.id,
        );

        if (isAlreadyDefined) {
            this._selectAddress(feature);
            return;
        }

        const preferences = this.localStorageService.get('preferences');
        const modulesAccess = preferences.modules;
        if (!modulesAccess.territorySelection) {
            this._selectAddress(feature, newTerritory);
            return;
        }

        const newTerritoryLabel = newTerritory.label;
        const scaleLabel = this.terService.territoryScale.labelLong.toLocaleLowerCase();
        const addressLabel = feature.properties.label;

        const body = `
            <div>
                Vous êtes sur le point de changer de territoire.
                Le nouveau territoire sera <span class="text-st-active">${newTerritoryLabel}</span>,
                ${scaleLabel} de \u00ab<span class="text-st-primary">${addressLabel}</span>\u00bb.
            </div>
            <div>
                Voulez-vous continuer ?
            </div>
        `;

        const modal = this.modalService.open(ConfirmationModalComponent, { size: 'lg' });
        modal.componentInstance.header = 'Changer de territoire';
        modal.componentInstance.body = body;
        modal.result.then(
            async () => this._selectAddress(feature, newTerritory),
            () => {},
        );
    }

    private async _selectAddress(feature: any, newTerritory?: Territory) {
        this.searchService.selectAddress(feature);

        if (newTerritory) {
            await this._updateIndicators(newTerritory);
        }
    }

    private async _updateIndicators(newTerritory: Territory) {
        try {
            this.loaderService.start('updateAllIndicators');
            await Promise.all([
                this.terService.replaceTerritories([newTerritory]),
                this.plotIndicatorService.replotAllIndicators(true),
            ]);
        } catch (error) {
            console.error('Error _updateIndicators', error);
            this.notification.error(
                'Une erreur est survenue. Impossible de mettre à jour les indicateurs.',
            );
        } finally {
            this.loaderService.stop('updateAllIndicators');
        }
    }
}
