'use strict';

import { Component, Inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ToastrService } from 'ngx-toastr';

import { FilterCategory, FilterCriteria } from 'src/app/models/FilterCategory';
import { Indicator } from 'src/app/models/Indicator';

import { DataService } from 'src/app/services/DataService';
import { EventService } from 'src/app/services/event.service';
import { FilterDataService } from 'src/app/services/FilterDataService';
import { LoaderService } from 'src/app/services/LoaderService';
import { ModuleService } from 'src/app/services/module.service';
import { PlotIndicatorService } from 'src/app/services/plotIndicator/plot-indicator.service';

@UntilDestroy()
@Component({
    selector: 'indicator-filter',
    templateUrl: './indicator-filter.template.html',
    styleUrls: ['./indicator-filter.component.scss'],
})
export class IndicatorFilterComponent implements OnInit {
    filtersByIndicator: { [id: number]: Array<FilterCategory> };
    indicatorsData: Indicator[] = [];
    selectedIndicator: Indicator = null;
    unit: string = '';
    selectionLabelByMethod = {
        sum: 'Somme',
        mean: 'Moyenne',
    };
    infoByIndicatorId: any = {};

    private indicatorPlotedFromFilter: boolean = false;

    public isLoading = false;

    constructor(
        private notification: ToastrService,
        @Inject(DataService) private dataService: DataService,
        @Inject(EventService) private eventService: EventService,
        @Inject(FilterDataService) private filterDataService: FilterDataService,
        @Inject(LoaderService) private loaderService: LoaderService,
        @Inject(ModuleService) public moduleService: ModuleService,
        @Inject(PlotIndicatorService) private plotIndicatorService: PlotIndicatorService,
    ) {}

    ngOnInit(): void {
        this.filtersByIndicator = this.filterDataService.filtersByIndicator;

        this.eventService.indicatorPloted
            .pipe(untilDestroyed(this))
            .subscribe(async (indicatorId: any) => {
                if (this.indicatorPlotedFromFilter) {
                    return;
                }
                const indicatorPlot = this.plotIndicatorService.plotedIndicators[indicatorId];
                const indicatorData = this.dataService.activeIndicators[indicatorId];

                const isEmpty = !indicatorPlot.hasOwnProperty('dataToPlot');
                const isFilterable = indicatorPlot.static_dynamic == 'dyn';

                if (!isEmpty && isFilterable) {
                    this.selectedIndicator = indicatorData;
                    this.isLoading = true;

                    try {
                        const response = await Promise.all([
                            this.filterDataService.getUnit(this.selectedIndicator),
                            this._updateAggregationInfo(),
                            this.filterDataService.init(indicatorPlot),
                        ]);
                        this.unit = response[0];

                        // user can uncheck indicator checkbox during the request
                        // so we must check if the indicator is still checked before continuing
                        if (!this.dataService.activeIndicators[indicatorId]) return;

                        this.addDynIndicator(indicatorData);
                        this.filtersByIndicator = this.filterDataService.filtersByIndicator;
                    } catch (error) {
                        console.error('Error filter indicatorPloted', error);
                        this.notification.error(
                            "Une erreur est survenue. Impossible d'initialiser les filtres.",
                        );
                    } finally {
                        this.isLoading = false;
                    }
                }
            });

        this.eventService.indicatorUnploted
            .pipe(untilDestroyed(this))
            .subscribe(async (indicatorId: any) => {
                this.removeDynIndicator(indicatorId);

                if (this.selectedIndicator) {
                    if (this.selectedIndicator.id_indicateur === indicatorId) {
                        const isAnyIndicatorDefined = this.indicatorsData.length > 0;
                        if (isAnyIndicatorDefined) {
                            this.selectedIndicator = this.indicatorsData[0];
                            this.unit = await this.filterDataService.getUnit(
                                this.selectedIndicator,
                            );
                        } else {
                            this.selectedIndicator = null;
                            this.unit = '';
                        }
                    }
                }
            });

        this.eventService.modelingDataUpdated
            .pipe(untilDestroyed(this))
            .subscribe(async (indicatorId: any) => {
                const indicatorData = this.dataService.activeIndicators[indicatorId];

                const isFilterable = indicatorData.static_dynamic == 'dyn';
                if (isFilterable) {
                    this.selectedIndicator = indicatorData;
                    this.unit = await this.filterDataService.getUnit(this.selectedIndicator);
                    this.addDynIndicator(indicatorData);

                    try {
                        await Promise.all([
                            this._updateAggregationInfo(),
                            this.filterDataService.updateCriteria(indicatorId),
                        ]);
                        await Promise.all([
                            this.filterDataService.updateCriteriaInitValue(indicatorId),
                            this.filterDataService.updateCriteriaValue(indicatorId),
                        ]);
                    } catch (error) {
                        console.error('Error filter modelingDataUpdated', error);
                        this.notification.error(
                            "Une erreur est survenue. Impossible d'initialiser les filtres.",
                        );
                    }
                }
            });

        this.eventService.filterCriteriaUpdated.pipe(untilDestroyed(this)).subscribe(async () => {
            if (this.selectedIndicator) {
                await this._updateAggregationInfo();
            }
        });

        this.moduleService.getNewIndicatorId().subscribe((newIndicatorId) => {
            const indicator = this.indicatorsData.find(
                (indicator) => indicator.id_indicateur == newIndicatorId,
            );
            if (indicator) {
                this.changeSelectedIndicator(indicator);
            }
        });
    }

    addDynIndicator(indicator: Indicator) {
        const indicatorId = indicator.id_indicateur;
        const isAlreadyAdded = this.indicatorsData.some(
            (indicator) => indicator.id_indicateur == indicatorId,
        );
        if (!isAlreadyAdded) {
            this.indicatorsData.push(indicator);
        }
    }

    removeDynIndicator(indicatorId: number) {
        const indicator = this.indicatorsData.find(
            (indicator) => indicator.id_indicateur == indicatorId,
        );
        if (indicator) {
            this.filterDataService.clearBase(indicatorId);
            const index = this.indicatorsData.indexOf(indicator);
            this.indicatorsData.splice(index, 1);
        }
    }

    async changeSelectedIndicator(indicatorData: Indicator) {
        this.selectedIndicator = indicatorData;
        this.unit = await this.filterDataService.getUnit(this.selectedIndicator);
    }

    async validate() {
        this.loaderService.start('applyFilters');

        try {
            const indicatorId = this.selectedIndicator.id_indicateur;
            const indicatorData = this.dataService.activeIndicators[indicatorId];
            const indicatorPlot = this.plotIndicatorService.plotedIndicators[indicatorId];

            const filters = this.filterDataService.createFiltersArray(indicatorId);
            const isFiltered = filters.length > 0;
            indicatorData.isFiltered = isFiltered;
            indicatorPlot.isFiltered = isFiltered;

            this.indicatorPlotedFromFilter = true;

            const refreshIntervals = false;
            const broadcastEvent = true;
            const updateGeojson = !['territories', 'none'].includes(indicatorData.vector_base);

            this.filterDataService.setCurrentFilteredIndicatorId(indicatorId);
            await this.plotIndicatorService.updateIndicator(
                indicatorId,
                refreshIntervals,
                broadcastEvent,
                updateGeojson,
            );
        } catch (error: any) {
            console.error('Error validate', error);
            this.notification.error(
                "Une erreur est survenue. Impossible de d'appliquer les filtres.",
            );
        } finally {
            this.loaderService.stop('applyFilters');
            this.indicatorPlotedFromFilter = false;
        }
    }

    reinitAllFilters() {
        const indicatorId = this.selectedIndicator.id_indicateur;
        this.filterDataService.reinitCriteriaValue(indicatorId);
        this._updateAggregationInfo();
    }

    exportCsv() {
        const headers = this._getFilterCsvHeader();
        const data = this._getFilterToCsv();
        const options = {
            title: 'filter-export',
            fieldSeparator: ';',
            decimalseparator: ',',
            headers: headers,
        };
        return new ngxCsv(data, 'filter-export', options);
    }

    private _getFilterToCsv() {
        const csv = [];

        const indicatorId = this.selectedIndicator.id_indicateur;
        this.filtersByIndicator[indicatorId].forEach((category: FilterCategory) => {
            category.criteria.forEach((criteria: FilterCriteria) => {
                csv.push({
                    filter: category.label,
                    critere: criteria.label,
                    active: criteria.active,
                    filteredData: criteria.absoluteValue,
                    totalData: criteria.initAbsoluteValue,
                });
            });
        });

        return csv.sort((a, b) => {
            let retVal = 0;
            for (let i = 0; i < a.length; i++) {
                if (a[i] != b[i] && a.length > i) {
                    retVal = a[i] > b[i] ? 1 : -1;
                    break;
                }
            }
            return retVal;
        });
    }

    private _getFilterCsvHeader() {
        const header = ['Filtre', 'Critère', 'Actif', 'Donnée filtrée', 'Donnée totale'];
        return header;
    }

    private async _updateAggregationInfo() {
        try {
            const indicatorId = this.selectedIndicator.id_indicateur;
            const indicatorPlot = this.plotIndicatorService.plotedIndicators[indicatorId];
            if (indicatorPlot) {
                const aggregationInfo = await this.filterDataService.setAggregationValue(
                    indicatorPlot,
                );
                this.infoByIndicatorId[indicatorId] = aggregationInfo;
            }
        } catch (error) {
            console.error('Error _updateAggregationInfo', error);
            this.notification.error(
                'Une erreur est survenue. Impossible de mettre à jour le total filtré.',
            );
            return Promise.reject(error);
        }
    }
}
