<div class="d-flex flex-column h-100">
    <div class="flex-grow-1 overflow-auto">
        <div class="w-100 p-3">
            <div class="header mb-3">
                <div class="container-fluid">
                    <div class="row d-flex align-items-center solar-card">
                        <div class="col-auto px-0 text-center">
                            <span class="fa-stack">
                                <i class="fa fa-circle-thin fa-stack-2x"></i>
                                <i class="fa fa-info fa-stack-1x"></i>
                            </span>
                        </div>
                        <div class="col">
                            <!-- <p>
                                Le panneau solaire thermique vous permet de produire de l'Eau Chaude
                                Sanitaire.
                            </p> -->
                            <p>
                                Un panneau couvre en général approximativement les besoins en eau
                                chaude pour un ménage de 3 personnes.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="body">
                <div class="my-2 solar-loader-icon" [hidden]="!showLoader">
                    <div>
                        <i class="fa fa-spinner fa-pulse fa-4x fa-fw"></i>
                    </div>
                    <div>
                        Simulation de votre <br />
                        projet en cours
                    </div>
                </div>

                <div [hidden]="showLoader">
                    <h5 class="text-st-primary">{{ adjustInstallationSizeLabel }}</h5>

                    <div
                        class="container-fluid position-relative solar-card mb-3 p-2"
                        *ngIf="slider"
                    >
                        <div class="row d-flex justify-content-center my-3">
                            <div class="col-11">
                                <stRange
                                    *ngIf="!showLoader"
                                    innerId="thermal-module-count"
                                    [min]="1"
                                    [max]="slider.maxModuleCount"
                                    [step]="1"
                                    [value]="slider.moduleCount"
                                    [(ngModel)]="slider.moduleCount"
                                    (change)="updateModuleCountByBuilding()"
                                >
                                </stRange>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center my-2">
                            <div class="col-11">
                                <div class="row">
                                    <div class="col-auto panel-count">
                                        {{ slider.moduleCount }} {{ slider.moduleCount == 1 ?
                                        'panneau' : 'panneaux' }}
                                    </div>
                                    <div class="col d-flex justify-content-end panel-area">
                                        {{ slider.moduleArea }} m²
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container-fluid">
                        <div
                            class="row d-flex align-items-center justify-content-between mb-3 solar-card investment"
                        >
                            <div class="col-1 d-flex justify-content-center">
                                <i class="fa fa-tasks fa-2x" [title]="tooltip.investment"></i>
                            </div>
                            <div class="col pe-0">
                                <div class="row gy-2 gx-4 d-flex align-items-center">
                                    <div class="col-lg" [innerHtml]="htmlThInvestment"></div>
                                    <div class="col-lg-auto question-mark-container">
                                        <stButton
                                            btnLabel="+&nbsp;d'infos"
                                            btnClass="btn-st-default w-100"
                                            (onClick)="openInfoModal(tooltipThInvestmentDetail, titleInvestment)"
                                        >
                                        </stButton>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="row d-flex align-items-center justify-content-between mb-3 solar-card production"
                        >
                            <div class="col-1 d-flex justify-content-center">
                                <i class="fa fa-bolt fa-2x" [title]="tooltip.production"> </i>
                            </div>
                            <div class="col pe-0">
                                <div class="row gy-2 gx-4 d-flex align-items-center">
                                    <div class="col-lg" [innerHtml]="htmlThProduction"></div>
                                    <div class="col-lg-auto question-mark-container">
                                        <stButton
                                            btnLabel="+&nbsp;d'infos"
                                            btnClass="btn-st-default w-100"
                                            (onClick)="openInfoModal(tooltipThProductionDetail, titleProduction)"
                                        >
                                        </stButton>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="row d-flex align-items-center justify-content-between solar-card economy"
                        >
                            <div class="col-1 d-flex justify-content-center">
                                <i class="fa fa-eur fa-2x" [title]="tooltip.economy"></i>
                            </div>
                            <div class="col pe-0">
                                <div class="row gy-2 gx-4 d-flex align-items-center">
                                    <div class="col-lg" [innerHtml]="htmlThEconomy"></div>
                                    <div class="col-lg-auto question-mark-container">
                                        <stButton
                                            btnLabel="+&nbsp;d'infos"
                                            btnClass="btn-st-default w-100"
                                            (onClick)="openInfoModal(tooltipThEconomyDetail, titleEconomy)"
                                        >
                                        </stButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="!modularity.simulations">
                        <ng-container *ngIf="!!htmlThWarning">
                            <div class="mt-3" [innerHtml]="htmlThWarning"></div>
                        </ng-container>
                        <ng-container *ngIf="!htmlThWarning">
                            <div class="text-center mt-3">
                                <small>
                                    Ces données sont issues d'un traitement automatisé et sont un
                                    premier ordre d'idée. Cela ne remplace pas la visite d'un
                                    technicien agréé.
                                </small>
                            </div>
                        </ng-container>
                    </ng-container>

                    <div class="mt-3" *ngIf="modularity.downloadResults && !modularity.simulations">
                        <button
                            type="button"
                            class="btn btn-st-primary solar-pdf"
                            (click)="downloadPdf()"
                            [disabled]="isResultsDownloading"
                        >
                            <span *ngIf="!isResultsDownloading">Télécharger le récapitulatif</span>
                            <i class="fa fa-spinner fa-pulse" *ngIf="isResultsDownloading"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer border-top" [hidden]="showLoader">
        <div class="container-fluid py-3">
            <div class="row">
                <div class="col-5">
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-default w-100 h-100"
                        btnLabel="Retour"
                        (onClick)="cancel()"
                    >
                    </stButton>
                </div>
                <div class="col-7">
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-primary w-100"
                        btnLabel="Valider ma simulation"
                        (onClick)="validate()"
                        *ngIf="modularity.simulations"
                    >
                    </stButton>
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-primary w-100"
                        [btnLabel]="contactButtonLabel"
                        (onClick)="openContactModal()"
                        *ngIf="!modularity.simulations"
                    >
                    </stButton>
                </div>
            </div>
        </div>
    </div>
</div>
