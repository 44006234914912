('use strict');

import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
    debounceTime,
    distinctUntilChanged,
    Observable,
    OperatorFunction,
    Subject,
    switchMap,
    tap,
    catchError,
    of,
    map,
} from 'rxjs';

import { ResetPasswordModal } from './modals/reset-password/reset-password.component';
import { ForgotPasswordModal } from './modals/forgot-password/forgot-password.component';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

import { environment } from 'src/environments/environment';

import { AuthService } from 'src/app/services/AuthService';
import { ClearService } from 'src/app/services/ClearService';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PlausibleAnalyticsService } from 'src/app/services/plausible-analytics.service';
import { RestService } from 'src/app/services/RestService';
import { SearchService } from 'src/app/services/search.service';
import { SolarService } from 'src/app/services/cadastre-solaire/cadastre-solaire.service';
import { ThemeService } from 'src/app/services/ThemeService';
import { UsefulService } from 'src/app/services/UsefulService';

@Component({
    selector: 'login',
    templateUrl: environment.login,
    encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
    userId: number;
    formModal: any;

    address: any = {
        label: '',
    };
    userCredentials = {
        login: '',
        password: '',
    };
    focus$ = new Subject<string>();
    click$ = new Subject<string>();
    isSearching: boolean;
    isAnyResultFound: boolean;

    isFetchingAddress = false;
    msg = {
        errorConnection: false,
        errorback: false,
        errorMessage: '',
    };
    headerTemplate = environment.header;

    showMobileUi: boolean = false;

    constructor(
        private modalService: NgbModal,
        private notification: ToastrService,
        private offcanvasService: NgbOffcanvas,
        private router: Router,
        @Inject(AuthService) private authService: AuthService,
        @Inject(ClearService) private clearService: ClearService,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(PlausibleAnalyticsService)
        private plausibleAnalyticsService: PlausibleAnalyticsService,
        @Inject(RestService) private restService: RestService,
        @Inject(SearchService) private searchService: SearchService,
        @Inject(SolarService) private solarService: SolarService,
        @Inject(ThemeService) private themeService: ThemeService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {
        this.showMobileUi = this.usefulService.showMobileUi;
    }

    async ngOnInit(): Promise<void> {
        this.clearService.clearApp();
        const profileName = environment.name;
        this.localStorageService.set('profile', profileName);

        if (profileName !== 'siterre') {
            await this._setProfileParameters();

            this.themeService.get();
            this.themeService.save();
            this.themeService.apply();

            const isSolar = environment.isSolar;
            const isLandingPageDefined = environment.isLandingPageDefined;

            if (!isLandingPageDefined) {
                if (isSolar) {
                    this.accessToMainForSolar();
                } else {
                    this._setPath('/main');
                }
            }
        }
    }

    formatter(x: any) {
        return x.label;
    }

    search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => (this.isSearching = true)),
            switchMap((term) => {
                return this.searchService.searchAddress(term).pipe(
                    map((response) => {
                        this.isAnyResultFound = response.length > 0;
                        if (this.isAnyResultFound && term.length >= 3) {
                            response.push({ isLast: true });
                        }
                        return response;
                    }),
                    catchError((error) => {
                        console.error('Error search', error);
                        this.notification.warning(
                            "Une erreur s'est produite. Impossible d'afficher la liste des adresses.",
                        );
                        this.isAnyResultFound = false;
                        return of([]);
                    }),
                );
            }),
            tap(() => (this.isSearching = false)),
        );

    private _setPath(path: string) {
        this.router.navigate([path]);
    }

    pressEnter(keyEvent: KeyboardEvent) {
        if (keyEvent.key === 'Enter' && Object.values(this.userCredentials).every((x) => !!x)) {
            this.validate();
        }
    }

    async validate() {
        if (!Object.values(this.userCredentials).every((x) => !!x)) return;

        try {
            const resetUserId = await this.authService.logIn(this.userCredentials);
            if (resetUserId) {
                await this.updateUserPassword(resetUserId);
            }
            await this.authService.getUserPreferences();
            this._setPath('/main');
        } catch (error) {
            console.error('Error validate', error);
            this.msg.errorConnection = true;
            this.msg.errorMessage = error.error.message;
        }
    }

    async updateUserPassword(resetUserId: number) {
        this.userId = resetUserId;
        const modalReference = this.modalService.open(ResetPasswordModal);
        modalReference.componentInstance.userId = resetUserId;
        await modalReference.result.then(
            async () => {
                return;
            },
            () => {},
        );
    }

    resetErrorMessage() {
        this.msg.errorback = false;
        this.msg.errorConnection = false;
    }

    private async _setProfileParameters() {
        const token = environment.token;
        const userId = environment.userId;
        const role = environment.role;

        this.authService.user = { id: userId, role: role };
        this.authService.connexion = 1;

        this.localStorageService.set('token', token);
        this.localStorageService.set('user', this.authService.user);

        try {
            await this.authService.getUserPreferences();
        } catch (error) {
            console.error('Error setProfileParameters', error);
            this.notification.error(
                "Une erreur est survenue lors de l'initialisation de l'application.",
            );
        }
    }

    selectSolarAddress(address: any) {
        const userId = environment.userId;
        this.plausibleAnalyticsService.trackEvent('Accès', { methode: 'Adresse' });

        this.plausibleAnalyticsService.trackEvent('Adresse', {
            utilisateur_id: userId,
            Adresse: address.properties?.label,
            Commune: address.properties?.city,
            Source: 'Recherche',
        });

        this.solarService.setAddress(address);
        this.address = address;
        const lat = address.geometry.coordinates[1];
        const lng = address.geometry.coordinates[0];
        const label = address.properties?.label;

        this._saveSolarConnection(lat, lng, label);
        this._setPath('/main');
    }

    accessToMainForSolar() {
        this.plausibleAnalyticsService.trackEvent('Accès', { Méthode: 'Direct' });

        const preferences = this.localStorageService.get('preferences');
        const lat = preferences.territory.latitude;
        const lng = preferences.territory.longitude;
        const label = preferences.territory.territoryNames[0];

        this._saveSolarConnection(lat, lng, label);
        this._setPath('/main');
    }

    private _saveSolarConnection(latitude: number, longitude: number, label: string) {
        const data = {
            userId: this.authService.user.id,
            latitude: latitude,
            longitude: longitude,
            label: label,
        };
        this.restService.saveSolarLogIn(data);
    }

    async geolocalizeMe() {
        const successCallback = async (latitude: number, longitude: number) => {
            try {
                const features = await this.searchService.reverseAddress(latitude, longitude);
                const feature = features[0];
                this.solarService.setAddress(feature);

                const label = feature.properties.label;
                this._saveSolarConnection(latitude, longitude, label);

                const userId = environment.userId;
                this.plausibleAnalyticsService.trackEvent('Adresse', {
                    utilisateur_id: userId,
                    Adresse: feature.properties?.label,
                    Commune: feature.properties?.city,
                    Source: 'Géolocalisation',
                });

                this.plausibleAnalyticsService.trackEvent('Accès', { Méthode: 'Géolocalisation' });

                this._setPath('/main');
            } catch (error) {
                console.error('Error geolocalizeMe', error);
                throw error;
            }
        };
        const errorCallback = () => this.notification.error('La géolocalisation est indisponible.');

        try {
            this.searchService.readGeolocation(successCallback, errorCallback);
        } catch (error) {
            console.error('Error geolocalizeMe', error);
            this.notification.error('La géolocalisation est indisponible.');
        }
    }

    openSielOffCanvas(content: any) {
        this.offcanvasService.open(content, {
            position: 'end',
            panelClass: 'siel-offcanvas',
            backdropClass: 'siel-backdrop',
        });
    }

    openForgetPasswordModal() {
        const modalRef = this.modalService.open(ForgotPasswordModal);
        modalRef.componentInstance.login = this.userCredentials.login;
        modalRef.result.then(
            (result) => {},
            (dismiss) => modalRef.componentInstance.ngOnInit(),
        );
    }
}
