<div class="modal-header">
  <h4 class="modal-title">Import d'un fichier </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body">
  <div *ngIf="['init', 'loaded'].includes(step)">
    <div class="container-fluid">
      <div class="row">
        <div class="col-6">
          <h4>
            Importer un fichier CSV
          </h4>

          <div class="csv-import">
            <p>
              Sélectionner et importer un fichier CSV depuis votre ordinateur.
            </p>
            <button class="btn btn-st-primary w-100" (click)="hiddenFileInput.click();">
              <input type="file" accept=".csv" (change)="uploadFiles($event)" #hiddenFileInput [hidden]="true" />
              <span>
                Sélectionner un fichier CSV
              </span>
              <!-- <span *ngIf="loadedLinesCount.err > 0">
                Re-Import du fichier
              </span> -->
            </button>

            <div *ngIf="isLoading">
              <span class="fa fa-spinner fa-spin fa-lg"></span>
            </div>
          </div>

          <div *ngIf="step == 'loaded'">
            <hr class="separator">

            <div class="alert alert-danger" role="alert" *ngIf="error.msg">
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
              <span class="ms-3">
                {{ error.msg }}
              </span>
            </div>

            <div class="csv-description mb-3">
              <div>
                Nombre de lignes chargées : {{ loadedLinesCount.ok }}
              </div>
              <div>
                Nombre de lignes contenant une erreur : {{ loadedLinesCount.err }}
              </div>
            </div>

            <div class="csv-preview">
              <i>Extrait des données chargées</i>
              <div class="panel panel-default bg-inherit">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th *ngFor=" let label of header">{{ label }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let row of rows.slice(0, previewMaxRowCount)">
                      <td *ngFor="let value of row">{{ value }}</td>
                    </tr>
                    <tr *ngIf="rows.length > previewMaxRowCount">
                      <td>...</td>
                      <td>...</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <stButton btnLabel="Suivant" btnClass="btn-st-primary w-100 mb-3" (onClick)="goToSetup()"
              [isDisabled]="!!error.msg">
            </stButton>
          </div>

          <div class="csv-notice">
            <hr class="separator">

            <div class="csv-structure">
              <p>
                Le fichier à charger doit avoir la structure suivant :
              </p>
              <ul>
                <li>
                  <b>1<sup>ère</sup> colonne : </b>identifiant du territoire (code INSEE)
                </li>
                <li>
                  <b>2<sup>ème</sup> colonne : </b>valeur numérique
                </li>
                <li>
                  <b>n<sup>ème</sup> colonne : </b>valeur numérique
                </li>
              </ul>
            </div>

            <div class="csv-syntactic-rules">
              <p>
                Règles syntaxiques du fichier à charger :
              </p>
              <ul>
                <li>Format du fichier : *.csv</li>
                <li>Format de données : UTF-8</li>
                <li>Séparateur de champs : &laquo; ; &raquo;</li>
                <li>Séparateur de décimales : &laquo; . &raquo; ou &laquo; , &raquo; </li>
              </ul>
            </div>

            <div class="csv-example">
              <i>Exemple de structure</i>
              <div class="panel panel-default bg-inherit">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th>Code_commune_INSEE</th>
                      <th>Mon indicateur</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>01123</td>
                      <td>13.6</td>
                    </tr>
                    <tr>
                      <td>01130</td>
                      <td>2.6</td>
                    </tr>
                    <tr>
                      <td>...</td>
                      <td>...</td>
                    </tr>
                  </tbody>

                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6">
          <h4>
            Importer un fichier SIG
          </h4>

          <div class="gis-drag-drop mb-5">
            <h5 class="text-st-primary fs-5">
              Glisser-déposer
            </h5>
            <p>
              Vous avez la possibilité de glisser-déposer un fichier directement sur la carte. Les fichiers SIG
              pris
              en charge sont :
            </p>
            <ul>
              <li>
                fichier shapefile archivé au format *.zip
              </li>
              <li>
                fichier geojson
              </li>
            </ul>
          </div>

          <div class="gis-api">
            <h5 class="text-st-primary fs-5">
              Depuis une API
            </h5>
            <p>
              Vous pouvez importer un fichier GeoJSON depuis une API.
            </p>
            <form>
              <div class="row mb-3 d-flex align-items-center">
                <label for="gis-api" class="col-1 control-label text-start fw-normal py-0">
                  URL
                </label>
                <div class="col-11">
                  <stInput placeholder="URL de l'api" inner-id="gis-api" [(ngModel)]="urlGeojson" type="url">
                  </stInput>
                </div>
              </div>
            </form>
            <stButton btnLabel="Importer le GeoJSON" btnClass="btn-st-primary w-100" (click)="uploadFromApi()">
            </stButton>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid" *ngIf="['setup', 'checked'].includes(step)">
    <div class="shape mb-3">
      <h5 class="text-st-primary fs-5">Choisir une représentation graphique</h5>

      <div class="container-fluid">
        <form>
          <ng-container *ngFor="let label of header.slice(1);let index = index">
            <div class="row mb-3 d-flex align-items-center" *ngIf="indicatorShapes[index]">
              <label for="imported-shape" class="col-7 control-label text-start fw-normal py-0">
                {{ label }}
              </label>
              <div class="col-5">
                <stSelect innerId="imported-shape" [(ngModel)]="indicatorShapes[index].value" [data]="shapeOptions"
                  labelField="label" valueField="value" type="primary">
                </stSelect>
              </div>
            </div>
          </ng-container>
        </form>
      </div>
    </div>

    <div class="millesime-granularity mb-3">
      <h5 class="text-st-primary fs-5">Choisir un périmètre temporel et un découpage géographique</h5>

      <div class="container-fluid">
        <form class="mb-3">
          <div class="row d-flex align-items-center">
            <label for="csv-millesime" class="col-7 control-label text-start fw-normal py-0">
              Millésime
            </label>
            <div class="col-5">
              <stSelect innerId="csv-millesime" [(ngModel)]="selected.year.value" [data]="selected.year.options"
                labelField="label" valueField="value" type="primary">
              </stSelect>
            </div>
          </div>
        </form>

        <form class="mb-3" *ngIf="territoryScales">
          <div class="row d-flex align-items-center">
            <label for="csv-granularity" class="col-7 control-label text-start fw-normal py-0">
              Granularité de territoire du fichier
            </label>
            <div class="col-5">
              <stSelect innerId="csv-granularity" (change)="updateGranularity()"
                [(ngModel)]="selected.granularity.label" [data]="territoryScales" labelField="labelLong"
                valueField="labelLong" type="primary">
              </stSelect>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="checked mb-3">
      <!-- <div class="success-import" *ngIf="step == 'checked' && !unmatchedRows.length">
        <div class="alert alert-success" role="alert">
          <i class="fa fa-check-circle" aria-hidden="true"></i>
          <span class="mx-3">
            Toutes les lignes du fichier ont pu correctement être traitées. Veuillez patienter pendant la mise à
            jour de la carte...
          </span>
          <span class="fa fa-spinner fa-spin fa-lg"></span>
        </div>
      </div> -->

      <div class="fail-import" *ngIf="isSetupFailed()">
        <div class="alert alert-danger" role="alert">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          <span class="ms-3">
            {{ unmatchedRows.length }} {{ unmatchedRows.length == 1 ? 'ligne' : 'lignes' }} sur {{ totalRowCount
            }}
            n'ont pas pu être {{ unmatchedRows.length == 1 ? 'affectée' : 'affectées' }} au territoire &laquo; {{
            territoryLabels }} &raquo; à l'échelle &laquo; {{ selected.granularity.scale.labelLong }} &raquo;.
          </span>
        </div>

        <p>
          Il peut s'agir :
        </p>
        <ul>
          <li>
            d'un problème de format de code INSEE (exemple : 1102 au lieu de 01102)
          </li>
          <li>
            de territoires extérieurs au territoire &laquo; {{ territoryLabels }} &raquo;
          </li>
          <li>
            d'année de référence différente des codes de territoire INSEE de l'année {{ selected.year.value }}
          </li>
        </ul>

        <p>
          {{ unmatchedRows.length == 1 ? 'Ligne' : 'Lignes' }} n'ayant pas {{ unmatchedRows.length == 1 ?
          'trouvée' :
          'trouvées' }} de {{ unmatchedRows.length == 1 ? 'correspondance' : 'correspondances' }} :
        </p>
        <div class="w-100">
          <table class="unmatched-rows">
            <thead>
              <tr>
                <th *ngFor="let label of header">
                  {{ label }}
                </th>
              </tr>
            </thead>
          </table>
        </div>

        <div style="max-height: 150px; overflow-y: scroll">
          <table class="unmatched-rows">
            <tbody>
              <tr *ngFor="let row of unmatchedRows">
                <td *ngFor="let value of row">
                  {{ value }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="text-center mt-5">
      <stButton btnLabel="Retour" btnClass="btn-st-default me-3" (click)="back()">
      </stButton>
      <stButton btnLabel="Cartographier les données" btnClass="btn-st-primary ms-3" (onClick)="checkAndPlot()"
        [isDisabled]="isLoading || step == 'checked' && !unmatchedRows.length || isSetupFailed()">
      </stButton>

      <div class="mt-3" *ngIf="isLoading">
        <i class="me-3">Veuillez patienter, traitement en cours...</i>
        <span class="fa fa-spinner fa-spin fa-lg text-st-active"></span>
      </div>
    </div>
  </div>

</div>

<div class="modal-footer">
  <stButton btnLabel="Fermer" btnClass="btn-st-default" (onClick)="activeModal.close()">
  </stButton>
</div>