<div class="d-flex flex-column h-100">
    <div class="flex-grow-1 overflow-auto">
        <div class="w-100 p-3">
            <div class="solar-loader-icon" *ngIf="showLoader">
                <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
            </div>

            <ng-container *ngIf="!showLoader">
                <ng-container *ngIf="isZoneConstraint">
                    <div class="solar-card text-st-active mb-3">
                        Le bâtiment sélectionné se trouve dans une zone sujette à des contraintes
                        architecturales. Rapprochez-vous de votre mairie afin d'étudier la
                        faisabilité de votre projet solaire.
                    </div>
                </ng-container>

                <ng-container *ngIf="selectedElements.length > 0">
                    <div class="solar-card">
                        <h5 class="text-st-primary mb-3">Périmètre de mon projet</h5>
                        <div class="mb-3">
                            <h6>{{ selectedRoofTitle }}</h6>
                            <div class="container-fluid solar-card p-2">
                                <ng-container *ngFor="let element of selectedElements">
                                    <div class="row d-flex align-items-center selected-element">
                                        <div class="col-lg">
                                            <div class="text-st-primary">
                                                {{ element.addressLabel }}
                                            </div>
                                            <div class="text-st-muted fs-65">
                                                {{ element.parcelLabel }}
                                            </div>
                                        </div>
                                        <div class="col-lg-auto">
                                            <div class="row gx-0">
                                                <div class="col text-center">
                                                    <button
                                                        type="button"
                                                        class="btn btn-st-inverted-primary"
                                                        title="Localiser la toiture"
                                                        (click)="findElementOnMap(element.id)"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="25"
                                                            height="25"
                                                            fill="currentColor"
                                                            class="bi bi-geo-alt-fill"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path
                                                                d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                                <div class="col text-center">
                                                    <button
                                                        type="button"
                                                        class="btn btn-st-inverted-primary remove-button"
                                                        title="Retirer des toitures sélectionnées"
                                                        (click)="removeElement(element.id)"
                                                    >
                                                        <svg
                                                            width="27"
                                                            height="26"
                                                            viewBox="0 0 27 26"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M19.0687 7.79199L7.93359 18.2087M7.93359 7.79199L19.0687 18.2087"
                                                                stroke-width="3"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                        <div
                            class="solar-loader-icon"
                            *ngIf="solarService.selectionInfo.showLoader"
                        >
                            <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                        </div>

                        <ng-container *ngIf="!solarService.selectionInfo.showLoader">
                            <div class="selection-info">
                                <span> {{ solarService.selectionInfo.myRoof }} </span>
                                <span class="text-st-active">
                                    {{ solarService.selectionInfo.totalModuleArea }}&nbsp;m²
                                </span>
                                <span>de panneaux sur</span>
                                <span class="text-st-active">
                                    {{ solarService.selectionInfo.totalGrossRoofArea }}&nbsp;m²
                                </span>
                                <span>
                                    {{ solarService.selectionInfo.direction }} {{
                                    solarService.selectionInfo.tilt }}
                                </span>
                            </div>

                            <stButton
                                btnLabel="Personnaliser la surface disponible"
                                btnClass="btn-st-default w-100"
                                (click)="goToStage('parametersStage')"
                                *ngIf="!solarService.results.length"
                            >
                            </stButton>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>

    <div class="footer border-top" *ngIf="selectedElements.length && !showLoader">
        <div class="container-fluid text-center py-3">
            <div class="row">
                <div class="col-5" *ngIf="solarService.results.length > 0">
                    <stButton
                        btnClass="btn-st-default w-100 h-100"
                        btnLabel="Retour"
                        (onClick)="goToStage('resultsStage')"
                    >
                    </stButton>
                </div>
                <div
                    [ngClass]="{'col-7': solarService.results.length > 0, 'col-12': solarService.results.length == 0}"
                >
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-primary w-100"
                        btnLabel="Démarrer ma simulation de projet solaire"
                        (onClick)="startSimulation()"
                        [isDisabled]="selectedElements.length == 0"
                    >
                    </stButton>
                </div>
            </div>
        </div>
    </div>
</div>
