('use strict');

import { Inject, Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { Indicator } from 'src/app/models/Indicator';

import { EventService } from 'src/app/services/event.service';
import { MapService } from 'src/app/services/map.service';

@Injectable({
    providedIn: 'root',
})
export class ModuleService {
    private indicatorIdSelected: BehaviorSubject<number> = new BehaviorSubject<number>(null);

    private plugins = ['atac', 'solar', 'prosperReseaux'];
    public isBaseModuleOpen = false;
    public isPluginOpen = false;
    public isAnyModuleOpen = false;

    public territorySelectionModal: NgbModalRef;
    public isModuleActive = {
        atac: false,
        catalog: false,
        download: false,
        electricLoad: false,
        filters: false,
        indicatorParameters: false,
        informations: false,
        mapParameters: false,
        ovelo: false,
        prosperBatiments: false,
        prosperReseaux: false,
        solar: false,
        // upload: false,
    };
    public isTerritorySelectionActive = false;
    public isUploadModalActive = false;

    public showIcon = {
        atac: false,
        download: false,
        filters: false,
        indicatorParameters: false,
        prosperBatiments: false,
        prosperReseaux: false,
        ovelo: false,
        solar: false,
        upload: false,
    };

    public showTerritorySearch = false;
    public showLegend = false;

    constructor(
        @Inject(EventService) private eventService: EventService,
        @Inject(MapService) private mapService: MapService,
    ) {
        this._init();
    }

    clear() {
        this._init();
    }

    private _init() {
        this.hideAllModules();
        this.closeAllModules();

        this.showTerritorySearch = false;
        this.showLegend = false;
    }

    openModule(moduleName: string) {
        if (this.isModuleActive[moduleName]) {
            return;
        }
        this.closeAllModules();
        this.isModuleActive[moduleName] = true;
        this._setIsModuleOpen();
    }

    closeModule(moduleName: string) {
        this.isModuleActive[moduleName] = false;
        this._setIsModuleOpen();
    }

    toggleModule(moduleName: string) {
        const isActivate = this.isModuleActive[moduleName];
        this.closeAllModules();
        this.isModuleActive[moduleName] = !isActivate;
        this._setIsModuleOpen();
    }

    closeAllModules() {
        for (let module in this.isModuleActive) {
            this.isModuleActive[module] = false;
        }
        this.isTerritorySelectionActive = false;
        this.isUploadModalActive = false;
        this._setIsModuleOpen();
    }

    hideAllModules() {
        for (let module in this.showIcon) {
            this.showIcon[module] = false;
        }
        this.isTerritorySelectionActive = false;
        this.isUploadModalActive = false;
        this._setIsModuleOpen();
    }

    updateAvailableModule(indicatorPlots: Indicator[]) {
        const isIndicatorPloted = indicatorPlots.length > 0;

        this.showLegend = isIndicatorPloted;
        this.showIcon.upload = isIndicatorPloted;
        this.showIcon.download = isIndicatorPloted;
        this.showIcon.indicatorParameters = isIndicatorPloted;

        this.showIcon.prosperBatiments = false;
        this.showIcon.ovelo = false;

        const isIndicatorFilterable = indicatorPlots.some(
            (indicatorPlot: Indicator) => indicatorPlot.static_dynamic == 'dyn',
        );
        this.showIcon.filters = isIndicatorFilterable;

        const isPrIndicator = indicatorPlots.some(
            (indicatorPlot: Indicator) => indicatorPlot.plugin == 'prosper_reseaux',
        );
        this.showIcon.prosperReseaux = isPrIndicator;

        const isSolarIndicator = indicatorPlots.some(
            (indicatorPlot: Indicator) => indicatorPlot.plugin == 'solaire',
        );
        this.showIcon.solar = isSolarIndicator;

        const isAtacIndicator = indicatorPlots.some(
            (indicatorPlot: Indicator) => indicatorPlot.plugin == 'atac',
        );
        this.showIcon.atac = isAtacIndicator;
        this.showTerritorySearch = isIndicatorPloted && !isSolarIndicator;
    }

    private _setIsModuleOpen() {
        this.isPluginOpen = this.plugins.some((plugin) => this.isModuleActive[plugin]);
        this.isBaseModuleOpen = Object.keys(this.isModuleActive)
            .filter((module) => !this.plugins.includes(module))
            .some((module) => this.isModuleActive[module]);

        this.isAnyModuleOpen = this.isPluginOpen || this.isBaseModuleOpen;
        this.mapService.invalidateSize();

        if (this.isAnyModuleOpen) {
            this.isTerritorySelectionActive = false;
        }
    }

    setNewIndicatorId(newIndicatorId: number): void {
        this.indicatorIdSelected.next(newIndicatorId);
    }

    getNewIndicatorId(): Observable<number> {
        return this.indicatorIdSelected.asObservable();
    }

    setTerritorySelectionModal(modalRef: NgbModalRef) {
        this.territorySelectionModal = modalRef;
    }
}
