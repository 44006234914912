('use strict');

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'stButton',
    templateUrl: './st-button.template.html',
    styleUrls: ['./st-button.component.scss'],
})
export class StButtonComponent {
    @Input() btnLabel: string;
    @Input() btnClass: string;
    @Input() iconFaLeft: string;
    @Input() iconFaRight: string;
    @Input() iconGlyphLeft: string;
    @Input() iconGlyphRight: string;
    @Input() isDisabled: boolean;

    @Output() onClick: EventEmitter<any> = new EventEmitter();

    click() {
        this.onClick.emit();
    }

    constructor() {}
}
