'use strict';

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ReversePipe } from 'ngx-pipes';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Indicator } from 'src/app/models/Indicator';
import { TerritoryScale } from 'src/app/models/TerritoryTypes';
import { SubTheme, Theme } from 'src/app/models/Theme';

import { CatalogService } from 'src/app/services/catalog.service';
import { DataService } from 'src/app/services/DataService';
import { EventService } from 'src/app/services/event.service';
import { LoaderService } from 'src/app/services/LoaderService';
import { MapService } from 'src/app/services/map.service';
import { ModuleService } from 'src/app/services/module.service';
import { PlotIndicatorService } from 'src/app/services/plotIndicator/plot-indicator.service';
import { TerService } from 'src/app/services/TerService';

import { StAccordionComponent } from 'src/app/components/forms/st-accordion/st-accordion.component';

@UntilDestroy()
@Component({
    selector: 'indicator-catalog',
    templateUrl: './indicator-catalog.template.html',
    styleUrls: ['./indicator-catalog.component.scss'],
    providers: [ReversePipe],
})
export class IndicatorCatalogComponent implements OnInit {
    @ViewChild(StAccordionComponent) accordionComponent!: StAccordionComponent;

    public indicatorsByTheme = [];
    public selectedTheme = null;
    private selectedFlatIndicators = [];
    public isModuleDisplayed = null;
    public theme: Theme = null;

    public availableGranularities: TerritoryScale[];
    public granularity: TerritoryScale;

    constructor(
        private notification: ToastrService,
        @Inject(CatalogService) private catalogService: CatalogService,
        @Inject(DataService) private dataService: DataService,
        @Inject(EventService) private eventService: EventService,
        @Inject(LoaderService) private loaderService: LoaderService,
        @Inject(MapService) private mapService: MapService,
        @Inject(ModuleService) public moduleService: ModuleService,
        @Inject(PlotIndicatorService) private plotIndicatorService: PlotIndicatorService,
        @Inject(TerService) private terService: TerService,
    ) {}

    ngOnInit(): void {
        this._listenToEvents();
    }

    private _listenToEvents() {
        this.eventService.mainInitialized
            .pipe(untilDestroyed(this))
            .subscribe(() => this._initialization());

        this.eventService.customIndicatorsAdded
            .pipe(untilDestroyed(this))
            .subscribe((data: any) => {
                this.indicatorsByTheme = data.indicatorsByTheme;
                this.theme = data.theme;

                let newIndicator: Indicator;
                this.theme.ss_theme.forEach((subTheme) => {
                    subTheme.indicators.forEach((indicator) => {
                        indicator.popoverInfo = this.catalogService.setPopoverInfo(indicator);
                        // indicator.availableShape = this.catalogService.setAvailableShape(indicator);
                    });
                    subTheme.indicators.forEach((indicator) =>
                        this.catalogService.manageIsAvailableAtThisScale(indicator),
                    );
                    subTheme.indicators.forEach((indicator) => (newIndicator = indicator));
                });

                if (newIndicator) {
                    newIndicator.active = true;
                }

                this.openTheme(this.theme);
                this.moduleService.openModule('catalog');
                this.accordionComponent.updateActiveIds();
            });

        this.eventService.selectionTerritoryUpdated
            .pipe(untilDestroyed(this))
            .subscribe(async () => {
                this.catalogService.setAvailableIndicator();
                this.catalogService.setGranularities();
                const indicatorPlots = Object.values(this.plotIndicatorService.plotedIndicators);

                if (indicatorPlots.length) {
                    indicatorPlots.forEach((indicatorPlot: Indicator) => {
                        if (!indicatorPlot.isCustomTerritory) {
                            this.catalogService.updateAfterTerritoryChange(indicatorPlot);
                        }
                    });

                    const callback = async () => {
                        this.loaderService.start('updateAllIndicators');
                        try {
                            const broadcastEvent = true;
                            await this.plotIndicatorService.replotAllIndicators(broadcastEvent);
                        } catch (error) {
                            console.error('Error updateAllIndicators', error);
                            this.notification.error(
                                'Une erreur est survenue. Impossible de mettre à jour les indicateurs.',
                            );
                        } finally {
                            this.loaderService.stop('updateAllIndicators');
                        }
                    };
                    this.mapService.activateMoveEventOnce(callback);
                }
                this.terService.flyToLayerBounds();
            });

        this.eventService.indicatorsPlotedFromScenario
            .pipe(untilDestroyed(this))
            .subscribe((indicatorsInfo: any) => {
                this.catalogService.setAvailableIndicator();
                this.catalogService.setGranularities();

                if (indicatorsInfo.length) {
                    indicatorsInfo.forEach((indicatorInfo: any) => {
                        const indicatorId = indicatorInfo.indicatorId;
                        const indicator = this.catalogService.findIndicator(indicatorId);
                        indicator.active = true;
                    });

                    // open theme on the first indicators
                    const indicatorId = indicatorsInfo[0].indicatorId;
                    const theme = this.indicatorsByTheme.find((theme: Theme) =>
                        theme.ss_theme.some((subTheme) =>
                            subTheme.indicators.some(
                                (indicator) => indicator.id_indicateur == indicatorId,
                            ),
                        ),
                    );
                    if (theme) {
                        this.openTheme(theme);
                    }
                }
            });

        this.eventService.indicatorPloted
            .pipe(untilDestroyed(this))
            .subscribe((indicatorId: any) => {
                const indicatorPlot = this.plotIndicatorService.plotedIndicators[indicatorId];
                const isShapImported = indicatorPlot.vector_base == 'imported'; // only when importing shp for now

                if (isShapImported) {
                    this.mapService.flyToBounds(indicatorPlot.geojsonLayer.getBounds());
                }
            });
    }

    private _initialization() {
        this.indicatorsByTheme = this.catalogService.indicatorsByTheme;
        this.isModuleDisplayed = this.indicatorsByTheme.length > 1;

        const initIndicatorId = this.catalogService.initIndicatorId;

        if (!!initIndicatorId) {
            this.selectedTheme = this.catalogService.initTheme;

            const indicator = this.catalogService.findIndicator(initIndicatorId);
            this.selectedFlatIndicators.push(indicator);
        } else {
            this.selectedTheme = this.indicatorsByTheme[0];
            this.indicatorsByTheme[0].open = true;
        }
        this._openSubTheme();
    }

    async toggleFlatIndicator(newIndicator: Indicator) {
        const isNewActive = newIndicator.active;
        const indicatorId = newIndicator.id_indicateur;

        if (isNewActive) {
            const loaderId = `${indicatorId}_flat`;
            this.loaderService.start(loaderId);
            this.selectedFlatIndicators.push(newIndicator);

            try {
                const granularity = newIndicator.granularity;
                await this._activateNewFlatIndicator(newIndicator, granularity);
            } catch (error) {
                console.error('Error toggleFlatIndicator', error);
                this._deactivateFlatIndicator(newIndicator);
                this.notification.error(
                    "Une erreur est survenue. Impossible de charger l'indicateur.",
                );
            } finally {
                this.loaderService.stop(loaderId);
            }
        } else {
            this._deactivateFlatIndicator(newIndicator);
        }
    }

    private async _activateNewFlatIndicator(newIndicator: Indicator, granularity: TerritoryScale) {
        try {
            const newIndicatorId = newIndicator.id_indicateur;

            const isAlreadyActive = !!this.plotIndicatorService.plotedIndicators[newIndicatorId];
            if (isAlreadyActive) {
                newIndicator.active = true;
                return;
            }

            await this.dataService.getIndicator(newIndicatorId);
            const indicatorPlot = await this.plotIndicatorService.createIndicator(
                newIndicatorId,
                granularity,
            );

            const isStillSelected = newIndicator.active;
            if (isStillSelected) {
                this.plotIndicatorService.setIndicator(indicatorPlot);
            }
        } catch (error) {
            console.error('Error _activateNewFlatIndicator', error);
            throw error;
        }
    }

    private _deactivateFlatIndicator(indicator: Indicator) {
        const indicatorId = indicator.id_indicateur;
        indicator.active = false;

        this.dataService.deleteIndicator(indicatorId);
        this.plotIndicatorService.removeIndicator(indicatorId);

        const index = this.selectedFlatIndicators.indexOf(indicator);
        if (index != -1) {
            this.selectedFlatIndicators.splice(index, 1);
        }
    }

    openTheme(theme: Theme) {
        this.indicatorsByTheme.forEach((theme: Theme) => (theme.open = false));
        this.selectedTheme = theme;
        this.selectedTheme.open = true;
        this._openSubTheme();
    }

    private _openSubTheme() {
        this._reorderSubTheme();
        this.selectedTheme.ss_theme.forEach((subTheme: SubTheme) => (subTheme.open = true));
    }

    private _reorderSubTheme() {
        // default order can be duplicated up so it must be clean up
        const subThemes = this.selectedTheme.ss_theme;
        subThemes.sort((a: SubTheme, b: SubTheme) => a.ordre - b.ordre);
        subThemes.forEach((subTheme: SubTheme, index: number) => (subTheme.order = index));
    }

    async selectGranularity(indicator: Indicator, granularity: TerritoryScale) {
        if (indicator.active && granularity.id == indicator.granularity.id) {
            return;
        }

        this._deactivateFlatIndicator(indicator);

        setTimeout(() => {
            indicator.active = true;
            indicator.granularity = granularity;
        }, 0);
    }
}
