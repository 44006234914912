'use strict';

import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';

import {
    GaugeChartData,
    BarChartData,
    PvSurplusResult,
    PvTotalityResult,
} from 'src/app/models/Solar';

import { CadastreSolaireContactModalComponent } from '../../../../../../modals/cadastre-solaire-contact-modal.component';
import { CadastreSolaireInfoModalComponent } from '../../../../../../modals/cadastre-solaire-info-modal.component';

import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SolarPvSurplusService } from 'src/app/services/cadastre-solaire/cadastre-solaire-pv-surplus.service';
import { SolarPvTotalityService } from 'src/app/services/cadastre-solaire/cadastre-solaire-pv-totality.service';
import { SolarService } from 'src/app/services/cadastre-solaire/cadastre-solaire.service';
import { UsefulService } from 'src/app/services/UsefulService';

@Component({
    selector: 'cadastreSolairePvSurplusSimulation',
    templateUrl: './cadastre-solaire-pv-surplus-simulation.template.html',
    styleUrls: ['./cadastre-solaire-pv-surplus-simulation.component.scss'],
})
export class CadastreSolairePvSurplusSimulationComponent implements OnInit, AfterViewInit {
    public showMobileUi: boolean = false;
    public showLoader: boolean = true;

    public modularity: any;

    public slider: {
        maxModuleCount: number;
        moduleCount: number;
        moduleArea: number;
        peakPower: number;
        stringifiedPeakPower: string;
    };

    public optimalSelfConsumption: {
        moduleCount: number;
        stringifiedModuleCount: string;
        powerPeak: number;
        rate: number;
        stringifiedRate: string;
        peakPower: number;
        stringifiedPeakPower: string;
    };

    public tooltipPvInvestmentDetail: string;
    public tooltipPvProductionDetail: string;
    public tooltipPvSellingDetail: string;
    public tooltip: {
        investment: string;
        installation: string;
        production: string;
        economy: string;
    };

    public adjustInstallationSizeLabel: string;
    public contactButtonLabel: string;

    public htmlPvInstallation: any;
    public htmlPvInvestment: string;
    public htmlPvProduction: string;
    public htmlPvProductionDetail: string;
    public htmlPvConsumptionDetail: string;
    public htmlPvEconomy: string;
    public htmlPvWarning: string;
    public htmlPvWarningSubcribedPower: string;
    public activeCollapseWarningPower: boolean = false;

    public isResultsDownloading: boolean;
    private gaugeChartData: GaugeChartData;
    private barChartData: BarChartData;

    public connectionCost: {
        isAvailable: boolean;
        isActive: boolean;
        isLoading: boolean;
        value: number;
        message: string;
    };

    public titleInvestment: string = 'Investissement';
    public titleProduction: string = 'Production';
    public titleEconomy: string = 'Economie';

    constructor(
        private modalService: NgbModal,
        private notification: ToastrService,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(SolarPvSurplusService)
        public solarPvSurplusService: SolarPvSurplusService,
        @Inject(SolarPvTotalityService) private solarPvTotalityService: SolarPvTotalityService,
        @Inject(SolarService) public solarService: SolarService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.showMobileUi = this.usefulService.showMobileUi;
        this.modularity = this.localStorageService.get('preferences').modularity.solar;

        // this.tooltipPvInfoDetail = this.solarPvTotalityService.pvInfo.message.info_detail;
        this.tooltipPvInvestmentDetail =
            this.solarPvTotalityService.pvInfo.message.investissement_detail;
        this.tooltipPvProductionDetail =
            this.solarPvTotalityService.pvInfo.message.production_detail;
        this.tooltipPvSellingDetail = this.solarPvTotalityService.pvInfo.message.economie_detail;
        this.htmlPvWarning = this.solarPvTotalityService.pvInfo.message.warning;
        this.htmlPvWarningSubcribedPower =
            this.solarPvTotalityService.pvInfo.message.warning_subcribed_power;

        this.tooltip = {
            investment: "L'investissement",
            installation: "L'installation",
            production: 'La production',
            economy: 'Les économies',
        };

        const customAdjustInstallationSizeLabel =
            this.solarService.moduleInfo.ajuster_taille_installation;
        this.adjustInstallationSizeLabel = customAdjustInstallationSizeLabel
            ? customAdjustInstallationSizeLabel
            : "J'ajuste la taille de mon installation";

        const customContactButtonLabel = this.solarService.moduleInfo.contacts_bouton;
        this.contactButtonLabel = customContactButtonLabel
            ? customContactButtonLabel
            : 'Aller plus loin';

        if (
            this.solarPvSurplusService.userPvData.housing.parameters.usableFloorArea.value == null
        ) {
            this.solarPvSurplusService.userPvData.housing.parameters.usableFloorArea.value =
                this.solarPvSurplusService.setDefaultUsableFloorArea();
            this.solarPvSurplusService.userPvData.housing.parameters.occupantCount.value =
                this.solarPvSurplusService.setDefaultOccupantCount();
        }
    }

    async ngAfterViewInit(): Promise<void> {
        await this.initPvSurplusSimulation();

        this.initConnectionCost();

        const maxResult = this.solarPvSurplusService.getMaxModuleResult();
        const optimalResult = this.solarPvSurplusService.findOptimalResult();
        this.solarPvSurplusService.setCurrentResult(optimalResult);

        const pvTotalityResult = this.solarPvTotalityService.findResultByModuleCount(
            optimalResult.moduleCount,
        );
        this.solarPvTotalityService.setCurrentResult(pvTotalityResult);

        this.slider = {
            maxModuleCount: maxResult.moduleCount,
            moduleCount: optimalResult.moduleCount,
            moduleArea: this.usefulService.round(optimalResult.totalModuleArea),
            peakPower: optimalResult.peakPower,
            stringifiedPeakPower: this.usefulService.stringifyNumber(
                this.usefulService.ceil(optimalResult.peakPower, 1),
            ),
        };

        this._updatePvIndicator();

        this.optimalSelfConsumption = {
            moduleCount: optimalResult.moduleCount,
            stringifiedModuleCount: this.usefulService.stringifyNumber(optimalResult.moduleCount),
            powerPeak: optimalResult.peakPower,
            rate: optimalResult.selfConsumptionRate,
            stringifiedRate: this.usefulService.stringifyNumber(
                this.usefulService.round(optimalResult.selfConsumptionRate * 100),
            ),
            peakPower: optimalResult.peakPower,
            stringifiedPeakPower: this.usefulService.stringifyNumber(
                this.usefulService.ceil(optimalResult.peakPower, 1),
            ),
        };

        const isNew = this.solarService.checkIfSelectedElementsIsNew();
        if (isNew) {
            this.solarService.setTotalModuleAreaToSelectionInfo(maxResult.totalModuleArea);
        }
    }

    private initConnectionCost() {
        this.connectionCost = {
            isAvailable:
                this.solarPvSurplusService.userPvData.electricity.subscribedPower.value <= 36, // api enedis does not work beyond 36kVA
            isLoading: false,
            isActive: true,
            value: null,
            message: '',
        };
    }

    private async initPvSurplusSimulation() {
        try {
            this.showLoader = true;
            const response = await this.solarPvSurplusService.simulatePvSurplus();
            this.solarPvTotalityService.setResults(response.totality);
        } catch (error) {
            console.error('Error initPvSurplusSimulation', error);
            this.notification.error(
                "Une erreur est survenue. Impossible d'initialiser la simulation photovoltaïque en autoconsommation.",
            );
            this.solarPvSurplusService.updateStage('consumptionStage');
        } finally {
            this.showLoader = false;
        }
    }

    async initPvTotalitySimulation() {
        try {
            this.showLoader = true;
            await this.solarPvTotalityService.simulatePvTotality();
        } catch (error) {
            console.error('Error initPvTotalitySimulation', error);
            this.notification.error(
                "Une erreur est survenue. Impossible d'initialiser la simulation photovoltaïque en vente totale.",
            );
        } finally {
            this.showLoader = false;
        }
    }

    cancel() {
        this.solarPvSurplusService.updateStage('consumptionStage');
    }

    private _updatePvIndicator() {
        if (this.solarPvSurplusService.currentResult.connectionCost) {
            this.connectionCost = this.solarPvSurplusService.currentResult.connectionCost;
        }

        this._updatePvInvestment();
        this._updatePvInstallation();
        this._updatePvProduction();

        this._updateAutoconsumptionGain();
        this._updateAutoConsumptionDetail();

        this.activeCollapseWarningPower = this._displayWarningSubcribedPower();
    }

    private _updatePvInvestment() {
        const splitByInvestmentTag =
            this.solarPvTotalityService.pvInfo.message.investissement.split('##pv_investment');
        if (splitByInvestmentTag.length === 2) {
            let html = splitByInvestmentTag.join(
                `<span class="text-st-active">${this._stringifyInvestment()}</span>`,
            );

            if (!!this.solarPvSurplusService.bonus) {
                html = html[html.length - 1] == '.' ? html.slice(0, -1) : html;
                html += `, après déduction des <span class="text-st-active">${this._stringifyBonus()}</span> de prime à l'autoconsommation.`;
            }

            if (this.solarService.connectionCostInfo.isEnedisAvailable) {
                if (!this.connectionCost.isAvailable) {
                    this.htmlPvInvestment = `
                        <div>
                            ${html}
                            <div>
                                <small>
                                    Le coût du raccordement ne peut être estimé par Enedis, car la puissance souscrite est supérieure à 36\u00a0kVA.
                                </small>
                            </div>
                        </div>
                    `;
                    return;
                }

                if (this.connectionCost.value) {
                    const connectionCostStringified = this.usefulService.stringifyNumber(
                        this.connectionCost.value,
                    );
                    const initHtml = html.slice(0, -1);
                    this.htmlPvInvestment = `
                        <div class="text-start">
                           ${initHtml}, dont ${connectionCostStringified}\u00a0€ de raccordement.
                            <div>
                                <small>
                                    Coût de raccordement estimé grâce à Enedis.
                                </small>
                            </div>
                        </div>
                    `;
                    return;
                }

                if (this.connectionCost.message) {
                    this.htmlPvInvestment = `
                        <div>
                            ${html}
                            <div>Le coût du raccordement n'a pas pu être estimé. ${this.connectionCost.message}</div>
                        </div>
                    `;
                    return;
                }

                this.htmlPvInvestment = `
                    <div>
                        ${html}
                    </div>
                `;
            } else {
                let message: string;
                const connectionCostInfo = this.solarService.connectionCostInfo;
                if (connectionCostInfo.contactRegie) {
                    message = connectionCostInfo.contactRegie;
                } else if (connectionCostInfo.aecEstimation) {
                    message = connectionCostInfo.aecEstimation;
                } else {
                    message = '';
                }
                const htmlPvInvestment = `
                    <div>
                        ${html}
                        <div>${message}</div>
                    </div>
                `;

                this.htmlPvInvestment = htmlPvInvestment;
            }
        }
    }

    private _stringifyInvestment() {
        const investment = this.usefulService.round(this.solarPvSurplusService.investment);
        const investmentStringified = this.usefulService.stringifyNumber(investment);
        return `${investmentStringified}\u00a0€`;
    }

    private _stringifyBonus() {
        const bonus = Math.round(this.solarPvSurplusService.bonus);
        const bonusStringified = this.usefulService.stringifyNumber(bonus);
        return `${bonusStringified}\u00a0€`;
    }

    private _updatePvInstallation() {
        let html = this.solarPvTotalityService.pvInfo.message.installation;

        // ##pv_module_count
        const isPvModuleCountDefined = html.includes('##pv_module_count');
        if (isPvModuleCountDefined) {
            let unit = this.solarPvSurplusService.totalModuleCount > 1 ? 'modules' : 'module';
            const pvModuleCountStringified = this.usefulService.stringifyNumber(
                this.solarPvSurplusService.totalModuleCount,
            );
            const placeholder = `<span class="text-st-active">${pvModuleCountStringified}&nbsp;${unit}</span>`;
            html = html.replace('##pv_module_count', placeholder);
        }

        // ##pv_total_module_area
        const isPvTotalModuleAreaDefind = html.includes('##pv_total_module_area');
        if (isPvTotalModuleAreaDefind) {
            const roundedValue = this.usefulService.round(
                this.solarPvSurplusService.totalModuleArea,
            );
            const pvTotalModuleAreaStringified = this.usefulService.stringifyNumber(roundedValue);
            const placeholder = `<span class="text-st-active">${pvTotalModuleAreaStringified}&nbsp;m²</span>`;
            html = html.replace('##pv_total_module_area', placeholder);
        }

        // ##pv_power_peak
        const isPvPowerPeakDefined = html.includes('##pv_power_peak');
        if (isPvPowerPeakDefined) {
            const placeholder = `<span class="text-st-active">${this._stringifyPeakPower()}</span>`;
            html = html.replace('##pv_power_peak', placeholder);
        }

        html = `<span>${html}</span>`;
        this.htmlPvInstallation = html;
    }

    private _stringifyPeakPower() {
        const peakPower = this.usefulService.round(this.solarPvSurplusService.totalPowerPeak, 1);
        const powerPeakStringified = this.usefulService.stringifyNumber(peakPower);
        return `${powerPeakStringified}\u00a0kWc`;
    }

    private _updatePvProduction() {
        // ##pv_production_by_year
        const splitByProductionTag =
            this.solarPvTotalityService.pvInfo.message.production.split('##pv_production_by_year');
        if (splitByProductionTag.length === 2) {
            const pvProduction = Math.round(this.solarPvSurplusService.totalProduction);
            const pvProductionStringified = this.usefulService.stringifyNumber(pvProduction);

            let html = splitByProductionTag.join(
                `<span class="text-st-active">${pvProductionStringified}\u00a0kWh/an</span>`,
            );

            // ##housing_consumption_by_year_ratio
            const splitByHousingTag = html.split('##housing_consumption_by_year_ratio');
            if (splitByHousingTag.length === 2) {
                // dirty fix : actually we don't want to display the second part of the message
                html = splitByHousingTag[0].split(',')[0] + '.';
            }

            const selfConsumptionRate = Math.round(
                this.solarPvSurplusService.selfConsumptionRate * 100,
            );
            const selfConsumptionRateStringified =
                this.usefulService.stringifyNumber(selfConsumptionRate);
            html += `<div>Le taux d'autoconsommation de l'électricité produite est de <span class="text-st-active">${selfConsumptionRateStringified}\u00a0%</span>.</div>`;

            html = `<div>${html}</div>`;
            this.htmlPvProduction = html;
        }
    }

    async getConnectionCost() {
        this.connectionCost.isLoading = true;
        this.connectionCost.isActive = true;

        try {
            const peakPower = this.solarPvSurplusService.currentResult.peakPower;
            const result = await this.solarService.getConnectionCost(
                'Surplus',
                this.solarPvSurplusService.userPvData.electricity.subscribedPower.value,
                peakPower,
            );

            if (result.state) {
                this.connectionCost.value = result.cost.total;
            } else {
                this.connectionCost.message = result.message;
            }

            this.solarPvSurplusService.addConnectionCost(this.connectionCost);
            this._updateAutoconsumptionWithConnectionCost();

            this.connectionCost.isActive = false;
        } catch (error) {
            console.error('Error getConnectionCost', error);
            this.notification.error(
                `L'API Enedis Raccordement ne répond pas. Veuillez réessayer ultérieurement.`,
            );
            this.connectionCost.isActive = true;
        } finally {
            this.connectionCost.isLoading = false;
        }
    }

    async downloadPdf() {
        try {
            this.isResultsDownloading = true;

            const labelComplement = 'autoconsommation avec revente du surplus';

            const currentResult = this.solarPvSurplusService.currentResult;
            const moduleAreaStringified = `${Math.round(currentResult.totalModuleArea)}\u00a0m²`;

            const results = {
                technology: 'pv',
                valorization: 'Surplus',
                label: `Photovoltaïque ${labelComplement} - ${this._stringifyPeakPower()}`,
                totalPowerPeak: currentResult.peakPower,
                // csvData: this._setCsvData(),
                htmlInstallation: this.htmlPvInstallation,
                htmlInvestment: this.htmlPvInvestment,
                htmlProduction: this.htmlPvProduction,
                htmlEconomy: this.htmlPvEconomy,
                moduleAreaStringified: moduleAreaStringified,
                peakPowerStringified: this._stringifyPeakPower(),
                investmentStringified: this._stringifyInvestment(),
                sellingGainStringified: this._stringifySellingGain(),
                roiStringified: this._stringifyRoi(currentResult),
                htmlProductionDetail: this.htmlPvProductionDetail,
                htmlConsumptionDetail: this.htmlPvConsumptionDetail,
                gaugeChartData: this.gaugeChartData,
                barChartData: this.barChartData,
            };

            const buffer = await this.solarService.getSolarPdfFile(results);
            const blob = new Blob([buffer], { type: 'application/pdf' });
            saveAs(blob, 'solaire-photovoltaique.pdf');
        } catch (error) {
            console.error('Error downloadPdf', error);
            this.notification.error(
                'Une erreur est survenue. Impossible de télécharger les résultats.',
            );
        } finally {
            this.isResultsDownloading = false;
        }
    }

    openContactModal() {
        const body =
            '<p> Vous voulez en savoir plus sur une installation solaire sur votre logement ? </p>';

        const modalRef = this.modalService.open(CadastreSolaireContactModalComponent);
        modalRef.componentInstance.body = body;
        modalRef.result.then(
            (result) => {},
            () => {},
        );
        modalRef.componentInstance.name = 'confirmationModal';
    }

    validate() {
        const labelComplement = 'autoconsommation avec revente du surplus';

        const currentResult = this.solarPvSurplusService.currentResult;
        const moduleAreaStringified = `${Math.round(currentResult.totalModuleArea)}\u00a0m²`;

        const result = {
            technology: 'pv',
            valorization: 'Surplus',
            label: `Photovoltaïque ${labelComplement} - ${this._stringifyPeakPower()}`,
            totalPowerPeak: currentResult.peakPower,
            // csvData: this._setCsvData(),
            htmlInstallation: this.htmlPvInstallation,
            htmlInvestment: this.htmlPvInvestment,
            htmlProduction: this.htmlPvProduction,
            htmlEconomy: this.htmlPvEconomy,
            moduleAreaStringified: moduleAreaStringified,
            peakPowerStringified: this._stringifyPeakPower(),
            investmentStringified: this._stringifyInvestment(),
            sellingGainStringified: this._stringifySellingGain(),
            roiStringified: this._stringifyRoi(currentResult),
            htmlProductionDetail: this.htmlPvProductionDetail,
            htmlConsumptionDetail: this.htmlPvConsumptionDetail,
            gaugeChartData: this.gaugeChartData,
            barChartData: this.barChartData,
        };
        this.solarService.addResult(result);
        this.solarService.updateMainStage('resultsStage');
    }

    private _stringifySellingGain() {
        const sellingGain = this.usefulService.round(this.solarPvSurplusService.sellingGain);
        const sellingGainStringified = this.usefulService.stringifyNumber(sellingGain);
        return `${sellingGainStringified}\u00a0€/an`;
    }

    private _stringifyRoi(data: PvSurplusResult | PvTotalityResult) {
        const pvRoi = this.usefulService.round(data.roi);
        const unit = pvRoi > 1 ? 'ans' : 'an';
        const pvRoiStringified = this.usefulService.stringifyNumber(pvRoi);
        return `${pvRoiStringified} ${unit}`;
    }

    updateModuleCountForAutoconsumption() {
        this.initConnectionCost();

        const pvTotalityResult = this.solarPvTotalityService.findResultByModuleCount(
            this.slider.moduleCount,
        );
        this.solarPvTotalityService.setCurrentResult(pvTotalityResult);

        const result = this.solarPvSurplusService.findResultByModuleCount(this.slider.moduleCount);
        this.solarPvSurplusService.setCurrentResult(result);

        this.slider.moduleArea = this.usefulService.round(result.totalModuleArea);
        this.slider.peakPower = result.peakPower;
        this.slider.stringifiedPeakPower = this.usefulService.stringifyNumber(
            this.usefulService.ceil(result.peakPower, 1),
        );

        this._updatePvIndicator();
    }

    private _updateAutoconsumptionWithConnectionCost() {
        this._updatePvInvestment();
        if (this.connectionCost.value) {
            this._updateAutoconsumptionGain();
            this._updateAutoConsumptionDetail();
        }
    }

    private _updateAutoconsumptionGain() {
        const roi = this.usefulService.round(this.solarPvSurplusService.roi);
        const roiUnit = roi > 1 ? 'ans' : 'an';

        const pvTotalityResult = this.solarPvTotalityService.currentResult;
        const roiWithTotalSell = this._stringifyRoi(pvTotalityResult);

        const totalGainByYear = this.usefulService.round(this.solarPvSurplusService.totalGain);
        const totalGainByYearStringified = this.usefulService.stringifyNumber(totalGainByYear);

        const economyByYear = this.usefulService.round(this.solarPvSurplusService.saving);
        const economyByYearStringified = this.usefulService.stringifyNumber(economyByYear);

        const sellingGainByYear = this.usefulService.round(this.solarPvSurplusService.sellingGain);
        const sellingGainByYearStringified = this.usefulService.stringifyNumber(sellingGainByYear);

        const htmlPvEconomy = `
            <div class="mb-2">
                Cet investissement vous permet un gain de <span class="text-st-active">${totalGainByYearStringified}\u00a0€/an</span>
                en autoconsommation avec revente du surplus au prix actuel de l'électricité.
                Soit un temps de retour sur investissement de <span class="text-st-active">${roi} ${roiUnit}</span>.
            </div>
            <small>
                Vous économisez ${economyByYearStringified}\u00a0€/an sur votre facture et ${sellingGainByYearStringified}\u00a0€/an de vente de l'énergie
                complémentaire.
            </small>
            <br>
            <small>
                En vente totale, le temps de retour sur investissement serait de ${roiWithTotalSell}.
            </small>
        `;
        this.htmlPvEconomy = htmlPvEconomy;
    }

    private _updateAutoConsumptionDetail() {
        this._setHtmlPvConsumptionDetail();
        this._setHtmlPvProductionDetail();
    }

    private _setHtmlPvConsumptionDetail() {
        const housingConsumption = Number(
            this.solarPvSurplusService.userPvData.electricity.energyConsumed.value,
        );
        const stringifiedHousingConsumption =
            this.usefulService.stringifyNumber(housingConsumption);

        const autoproductionRate = Math.round(this.solarPvSurplusService.selfProductionRate * 100);
        const stringifiedAutoproductionRate =
            this.usefulService.stringifyNumber(autoproductionRate);

        const electricEnergyAutoConsumed = Math.round(
            this.solarPvSurplusService.selfConsumptionEnergy,
        );
        const stringifiedElectricEnergyAutoConsumed = this.usefulService.stringifyNumber(
            electricEnergyAutoConsumed,
        );

        const saving = this.usefulService.round(this.solarPvSurplusService.saving);
        const stringifiedEconomy = this.usefulService.stringifyNumber(saving);

        this.htmlPvConsumptionDetail = `
        <div>
            Vous consommez ${stringifiedHousingConsumption}&nbsp;kWh par an d’électricité.<br/>
            Cette installation couvrirait <span class="text-st-active">${stringifiedAutoproductionRate} %</span>
            de votre consommation d’électricité et vous permettrait donc d’économiser
            <span class="text-st-active">${stringifiedElectricEnergyAutoConsumed}&nbsp;kWh</span> d’achat d’électricité chaque année.<br/>
            <span class="fw-bold">
                Cela représente <span class="text-st-active">une économie de ${stringifiedEconomy}&nbsp;€ par an</span> sur votre facture.
            </span>
        </div>`;
        this.gaugeChartData = {
            housingConsumption: housingConsumption,
            electricEnergyAutoConsumed: this.solarPvSurplusService.selfConsumptionEnergy,
            autoproductionRate: this.solarPvSurplusService.selfProductionRate,
        };
    }

    private _setHtmlPvProductionDetail() {
        const totalProduction = this.usefulService.round(
            this.solarPvSurplusService.totalProduction,
        );
        const stringifiedTotalProduction = this.usefulService.stringifyNumber(totalProduction);

        const sellingGain = this.usefulService.round(this.solarPvSurplusService.sellingGain);
        const stringifiedSellingGain = this.usefulService.stringifyNumber(sellingGain);

        this.htmlPvProductionDetail = `
        <div>
            Avec cette installation, vous produirez au total <span class="text-st-active">
            ${stringifiedTotalProduction}&nbsp;kWh par an</span>.<br/>
            <span class="fw-bold">
                En revendant le surplus d’électricité que vous n’autoconsommez pas immédiatement,
                vous êtes rémunérés <span class="text-st-active">${stringifiedSellingGain}&nbsp;€ par an</span>.
            </span>
        </div>`;

        this.barChartData = {
            electricEnergySurplus: this.solarPvSurplusService.surplusEnergy,
            electricEnergyAutoConsumed: this.solarPvSurplusService.selfConsumptionEnergy,
            totalProduction: this.solarPvSurplusService.totalProduction,
        };
    }

    openInfoModal(body: string, title: string) {
        if (title == 'Production') {
            body +=
                "Le taux d'autoconsommation représente la part de l'électricité produite par les panneaux solaires qui sera directement consommée au sein du logement.";
        }

        this.modalService.dismissAll();
        const modalRef = this.modalService.open(CadastreSolaireInfoModalComponent, { size: 'xs' });
        modalRef.componentInstance.body = body;
        modalRef.componentInstance.title = title;
    }

    private _displayWarningSubcribedPower(): boolean {
        const subscribedPowerValue =
            this.solarPvSurplusService.userPvData.electricity.subscribedPower.value;
        const peakPowerValue = this.solarPvSurplusService.currentResult.peakPower;
        return subscribedPowerValue < peakPowerValue;
    }
}
