import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as L from 'leaflet';

import { LocalStorageService } from '../local-storage.service';
import { MapService } from 'src/app/services/map.service';
import { ModuleService } from '../module.service';
import { SearchService } from 'src/app/services/search.service';
import { SolarService } from 'src/app/services/cadastre-solaire/cadastre-solaire.service';
import { TerService } from '../TerService';
import { UsefulService } from 'src/app/services/UsefulService';

import { round, stringifyNumber } from '../../utilities/formatNumber';
import { Indicator } from 'src/app/models/Indicator';

import { ConfirmationModalComponent } from '../../controllers/main/modals/confirmation/confirmation-modal.component';

@Injectable({
    providedIn: 'root',
})
export class PlotCadastreSolaireIndicatorService {
    private destroy$ = new Subject<void>();

    public idKey = 'id_ter';

    private conflictExistingElementsGroup = new L.FeatureGroup();
    public preSelectedExistingElementsGroup = new L.FeatureGroup();
    public selectedExistingElementsGroup = new L.FeatureGroup();

    public conflictNewElementsGroup = new L.FeatureGroup();
    public preSelectedNewElementsGroup = new L.FeatureGroup();
    public selectedNewElementsGroup = new L.FeatureGroup();

    public isClickOnMapEventHandled: boolean = false;

    private iconMarker: L.DivIcon = L.divIcon({
        popupAnchor: [2, -25],
        className: 'custom-div-icon',
        html: `
            <div class="marker-pin"></div>
            <i class="fa fa-plus awesome"></i>
            <i class="cs-new-element">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" fill="currentColor">
                    <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                        d="M96 0C80.7 0 67.6 10.8 64.6 25.7l-64 320c-1.9 9.4 .6 19.1 6.6 26.6S22.4 384 32 384H288v64H224c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H352V384H608c9.6 0 18.7-4.3 24.7-11.7s8.5-17.2 6.6-26.6l-64-320C572.4 10.8 559.3 0 544 0H96zm5.4 168L122.2 64h90.4L202.3 168H101.4zm-9.6 48H197.5L187.1 320H71L91.8 216zm153.9 0H394.3l10.4 104H235.3l10.4-104zm196.8 0H548.2L569 320h-116L442.5 216zm96-48H437.7L427.3 64h90.4l20.8 104zm-149.1 0h-139L260.9 64H379.1l10.4 104z"
                    />
                </svg>
            </i>
        `,
    });
    public newRoofMarkerLayer: L.Marker;
    public addressLabel: string;
    public openNewElementPopup: boolean = true;

    constructor(
        private modalService: NgbModal,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(MapService) private mapService: MapService,
        @Inject(ModuleService) private moduleService: ModuleService,
        @Inject(SearchService) private searchService: SearchService,
        @Inject(SolarService) private solarService: SolarService,
        @Inject(TerService) private terService: TerService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {
        this.mapService.isMarkerControlActiveObs$
            .pipe(takeUntil(this.destroy$))
            .subscribe((isMarkerControlActiveObs) => {
                if (this.solarService.indicatorPlot) {
                    if (isMarkerControlActiveObs) {
                        this.removeClickOnMapHandler();
                    } else {
                        this.setClickOnMapHandler();
                    }
                }
            });
    }

    centerMapOnLayer(latlng: L.LatLng) {
        const zoom = this.mapService.map.getZoom();
        this.mapService.centerOn(latlng.lat, latlng.lng, zoom, true);
    }

    initFeatureGroups() {
        this.clearFeatureGroups();
        this.clearFeatureGroupsEvents();
        this.addFeatureGroupsToMap();
        this.setFeatureGroupsEvents();
        this.setClickOnMapHandler();
    }

    clearFeatureGroups() {
        this.conflictExistingElementsGroup.clearLayers();
        this.preSelectedExistingElementsGroup.clearLayers();
        this.selectedExistingElementsGroup.clearLayers();

        this.conflictNewElementsGroup.clearLayers();
        this.preSelectedNewElementsGroup.clearLayers();
        this.selectedNewElementsGroup.clearLayers();
    }

    clearFeatureGroupsEvents() {
        this.conflictExistingElementsGroup.clearAllEventListeners();
        this.preSelectedExistingElementsGroup.clearAllEventListeners();
        this.selectedExistingElementsGroup.clearAllEventListeners();

        this.conflictNewElementsGroup.clearAllEventListeners();
        this.preSelectedNewElementsGroup.clearAllEventListeners();
        this.selectedNewElementsGroup.clearAllEventListeners();
    }

    setFeatureGroupsEvents() {
        this.conflictExistingElementsGroup.on('layeradd', (e) => {
            const layer = e.layer;
            this._openExistingReinitModal(layer as L.Polygon);
        });

        this.preSelectedExistingElementsGroup.on('layeradd', (e) => {
            const layer = e.layer as any;
            layer.setStyle({
                color: '#fff',
                weight: 6,
                opacity: 1,
                fillOpacity: 1,
            });
            this.solarService.indicatorPlot.setClickPopup(layer);
        });
        this.preSelectedExistingElementsGroup.on('layerremove', (e) => {
            const layer = e.layer as any;
            const feature = layer.feature;

            if (!this.selectedExistingElementsGroup.hasLayer(layer)) {
                const indicatorPlot = this.solarService.indicatorPlot;
                const style = indicatorPlot.getStyle(feature);
                layer.setStyle(style);
            }
        });

        this.selectedExistingElementsGroup.on('layeradd', async (e) => {
            this.preSelectedNewElementsGroup.clearLayers();
            this.selectedNewElementsGroup.clearLayers();

            const layer = e.layer as any;
            const feature = layer.feature;

            await this.solarService.addSelectedElement(feature);
            this.solarService.updateMainStage('initStage');
            layer.closePopup();
            feature.properties.isPopupClickOn = false;

            feature.properties.isSelected = true;
            layer.setStyle({
                color: '#fff',
                weight: 6,
                opacity: 1,
                fillOpacity: 1,
            });

            const center = layer.getCenter();
            this.centerMapOnLayer(center);
        });
        this.selectedExistingElementsGroup.on('layerremove', (e) => {
            const layer = e.layer as any;
            const feature = layer.feature;

            this.solarService.removeElement(feature.properties.id);
            layer.closePopup();

            feature.properties.isSelected = false;
            const indicatorPlot = this.solarService.indicatorPlot;
            const style = indicatorPlot.getStyle(feature);
            layer.setStyle(style);
        });

        // new elements
        this.conflictNewElementsGroup.on('layeradd', (e) => {
            this._openNewRoofReinitModal();
        });

        this.preSelectedNewElementsGroup.on('layeradd', async (e) => {
            await this.setNewRoofPopup();

            this.newRoofMarkerLayer.clearAllEventListeners();
            this.newRoofMarkerLayer.on('click', this.handleClickOnNewMarkerLayer);
        });

        this.selectedNewElementsGroup.on('layeradd', async (e) => {
            const layer = e.layer as L.Marker;
            const latlng = layer.getLatLng();

            const newElement = {
                center: {
                    latitude: latlng.lat,
                    longitude: latlng.lng,
                },
                installationType: null,
                usableRoofArea: null,
                height: null,
                orientation: null,
                azimuth: null,
                tilt: null,
                isNew: true,
                addressLabel: this.addressLabel,
                parcelLabel: null,
            };
            await this.solarService.addNewElement(newElement, latlng);

            this.moduleService.openModule('solar');
            this.solarService.updateMainStage('newRoofStage');
            this.newRoofMarkerLayer.closePopup();

            this.centerMapOnLayer(latlng);

            this.preSelectedExistingElementsGroup.clearLayers();
            this.selectedExistingElementsGroup.clearLayers();
        });
        this.selectedNewElementsGroup.on('layerremove', (e) => {
            this._removeNewRoofElement();
        });
    }

    handleClickOnNewMarkerLayer = async (e: any) => {
        const mainStage = this.solarService.mainStageObs.value;
        const isInitStage = [null, 'initStage', 'newRoofStage'].includes(mainStage);
        const isAnyResultDefined = this.solarService.results.length > 0;
        this.openNewElementPopup = isInitStage && !isAnyResultDefined;
        if (this.openNewElementPopup) {
            await this.setNewRoofPopup();
        } else {
            this.newRoofMarkerLayer.closePopup().unbindPopup();
            this._openNewRoofReinitModal();
        }
    };

    addFeatureGroupsToMap() {
        this.preSelectedNewElementsGroup.addTo(this.mapService.map);
        this.selectedNewElementsGroup.addTo(this.mapService.map);
    }

    setSelectionStyle(layer: any) {
        layer.setStyle({ color: '#fff', weight: 6, opacity: 1, fillOpacity: 1 });
    }

    setClickOnFeatureHandler(indicatorPlot: Indicator) {
        indicatorPlot.popupMaxHeight = this.usefulService.showMobileUi ? 'auto' : 200;
        indicatorPlot.popupMinWidth = this.usefulService.showMobileUi ? 'auto' : 400;
        indicatorPlot.popupMaxWidth = this.usefulService.showMobileUi ? 'auto' : 500;

        indicatorPlot.handleMouseOver = (e: any) => {
            const layer = e.target;
            const feature = layer.feature;
            const isPopupClickOn = feature.properties.isPopupClickOn;
            const isSelected = this.selectedExistingElementsGroup.hasLayer(layer);

            if (!isPopupClickOn) {
                if (!isSelected) {
                    indicatorPlot.highlightFeature(layer);
                }

                const tooltipContent = indicatorPlot.setTooltipContent(feature);
                layer.bindTooltip(tooltipContent, {
                    direction: 'top',
                });

                // here is the crazy part: leaflet does not initialized the tooltip correctly
                // (this first time it appears, the tooltip is placed at the wrong position)
                // so we have to initialize it manually
                const latLng = indicatorPlot.getOverlayPosition(layer);
                const tooltip = layer.getTooltip();
                tooltip._latlng = latLng;
                tooltip._map = this.mapService.map;
                tooltip._initLayout();

                layer.openTooltip(latLng);
            }
        };

        indicatorPlot.handleMouseOut = (e: any) => {
            const layer = e.target;
            const feature = layer.feature;
            const isPreSelected = this.preSelectedExistingElementsGroup.hasLayer(layer);
            const isSelected = this.selectedExistingElementsGroup.hasLayer(layer);

            layer.closeTooltip().unbindTooltip();

            if (!isPreSelected && !isSelected) {
                if (layer.setStyle) {
                    const style = indicatorPlot.getStyle(feature);
                    layer.setStyle(style);
                }
                indicatorPlot.unhighlightFeature(layer);
            }
        };

        indicatorPlot.handleClickOnFeature = (e: any) => {
            const layer = e.target;
            this.preSelectedExistingElementsGroup.clearLayers();
            this.preSelectedNewElementsGroup.clearLayers();

            const mainStage = this.solarService.mainStageObs.value;
            const isInitStage = [null, 'initStage', 'newRoofStage'].includes(mainStage);
            const isAnyResultDefined = this.solarService.results.length > 0;

            if (isInitStage) {
                if (isAnyResultDefined) {
                    this.conflictExistingElementsGroup.addLayer(layer);
                } else {
                    this.preSelectedExistingElementsGroup.addLayer(layer);
                }
            } else {
                this.conflictExistingElementsGroup.addLayer(layer);
            }
        };

        indicatorPlot.handleClosePopup = (layer: any) => {
            const popupElement: any = layer.getPopup();
            const closeButton = $(popupElement._closeButton);

            closeButton.attr('title', 'Fermer');

            closeButton.one('click', () => this.preSelectedExistingElementsGroup.clearLayers());
        };

        indicatorPlot.handlePopupCustomEvent = (layer: any) => {
            const feature = layer.feature;
            const centerLatLng = layer.getCenter();
            const center = {
                latitude: centerLatLng.lat,
                longitude: centerLatLng.lng,
            };
            feature.properties.id = feature.properties.id_ter;
            feature.properties.center = center;

            const popupElement = layer.getPopup();
            const wrapper = popupElement.getElement();

            const simulateButton = $(wrapper).find('.start-cs-simulation');
            if (simulateButton) {
                simulateButton.one('click', () => {
                    this.selectedExistingElementsGroup.clearLayers();
                    this.solarService.initSelectedElements();
                    this.selectedExistingElementsGroup.addLayer(layer);
                });
            }

            const addElementButton = $(wrapper).find('.add-element-to-cs-simulation');
            if (addElementButton) {
                addElementButton.one('click', () =>
                    this.selectedExistingElementsGroup.addLayer(layer),
                );
            }

            const removeElementButton = $(wrapper).find('.remove-element-from-cs-simulation');
            if (removeElementButton) {
                removeElementButton.one('click', () =>
                    this.selectedExistingElementsGroup.removeLayer(layer),
                );
            }
        };
    }

    setClickOnMapHandler() {
        if (!this.isClickOnMapEventHandled) {
            const map = this.mapService.map;
            map.on('click', this._handleClickOnMap);
            this.isClickOnMapEventHandled = true;
        }
    }

    removeClickOnMapHandler() {
        const map = this.mapService.map;
        map.off('click', this._handleClickOnMap);
        this.isClickOnMapEventHandled = false;
    }

    setTooltip(indicatorPlot: Indicator) {
        // we need the scope of the service, so arrow function is used
        indicatorPlot.setHeaderPopupContent = (feature: GeoJSON.Feature) => {
            const addressLabel = this.solarService.formatAddressLabel(feature);
            const parcelLabel = this.solarService.formalParcelLabel(feature);

            let occupation = feature.properties.occupation;
            const branch = feature.properties.branche;
            if (branch && !['Mutiple', 'Multiple'].includes(branch)) {
                occupation += ` / ${branch}`;
            }

            const header = `
                <div class="mb-2">
                    <div class="text-st-primary fs-16">${addressLabel}</div>
                    <div class="text-st-muted fs-65">
                        <div>${parcelLabel}</div>
                        <div>${occupation}</div>
                    </div>
                </div>
            `;

            return header;
        };

        // we need the scope of indicatorPlot, so arrow function is used
        indicatorPlot.setBodyPopupContent = (feature: GeoJSON.Feature) => {
            const availableArea = feature.properties.surface_disponible;
            let value: string;
            if (indicatorPlot.type === 'class') {
                const labelInfo = indicatorPlot.class_label.find(
                    (labelInfo) => labelInfo.id_class == feature.properties.value,
                );
                value = labelInfo.lib_class || 'Non disponible';
            } else {
                const roundedValue = round(feature.properties.value, indicatorPlot.decimalCount);
                const valueStringified = stringifyNumber(roundedValue, indicatorPlot.decimalCount);
                value = valueStringified;
                if (indicatorPlot.unit) {
                    value += ` ${indicatorPlot.unit}`;
                }
            }

            const isRoofSection = indicatorPlot.vector_base == 'solaire';
            const elementTypeLabel = isRoofSection ? 'pan de toiture' : 'toiture';

            const style = indicatorPlot.getStyle(feature);
            const css = `opacity: ${indicatorPlot.indicatorfillopacity}; background-color: ${style.fillColor}; border: ${indicatorPlot.indicatorcontourweight}px solid ${indicatorPlot.indicatorcontourcolor};`;

            let body = `
                <div class="fs-16">
                    <div>
                        Votre ${elementTypeLabel} peut recevoir jusqu'à
                        <span class="text-st-active">${availableArea}&nbsp;m²</span>
                        de panneaux.
                    </div>
                    <div>
                        <div class="d-inline me-2">
                            ${indicatorPlot.label} :
                        </div>
                        <div class="d-inline-block">
                            <span class="popover-legend d-inline-block me-2" style="${css}"></span>
                            <span>${value}</span>
                        </div>
                    </div>
                </div>
            `;
            return body;
        };

        // we need the scope of the service, so arrow function is used
        indicatorPlot.setBodyComplementPopupContent = (feature: GeoJSON.Feature) => {
            let bodyComplement = '';

            const elementId = feature.properties.id_ter;
            const isSelected = this.solarService.selectedElements.some(
                (element) => element.id == elementId,
            );

            const isAnyElementSelected = this.selectedExistingElementsGroup.getLayers().length;
            let addOrRemoveButton = '';
            if (isAnyElementSelected) {
                indicatorPlot.popupMinWidth = this.usefulService.showMobileUi ? 'auto' : 500;

                if (isSelected) {
                    addOrRemoveButton = `
                        <div class="col-md-6">
                            <button type="button" class="btn btn-st-default fs-16 w-100 d-flex justify-content-center align-items-center remove-element-from-cs-simulation" title="Retirer des toitures sélectionnées">
                                <span class="me-2">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17 7L7 17M7 7L17 17" stroke="#4A4A4C" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                                <span>
                                    Retirer de la sélection
                                </span>
                            </button>
                        </div>
                    `;
                } else {
                    addOrRemoveButton = `
                        <div class="col-md-6">
                            <button 
                                type="button" 
                                class="btn btn-st-default fs-16 w-100 d-flex justify-content-center align-items-center add-element-to-cs-simulation" 
                                title="Ajouter aux toitures sélectionnées">
                                <span class="me-2">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.75 2V16M1.75 9H15.75" stroke="#4A4A4C" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                                <span>
                                    Ajouter à la sélection
                                </span>
                            </button>
                        </div>
                    `;
                }
            } else {
                indicatorPlot.popupMinWidth = this.usefulService.showMobileUi ? 'auto' : 400;
            }

            bodyComplement = `
                <div class="container-fluid px-0 mt-2">
                    <div class="row g-2">
                        <div class="${isAnyElementSelected ? 'col-md-6' : 'col-md'}">
                            <button type="button" class="btn btn-st-primary fs-16 w-100 d-flex justify-content-center align-items-center start-cs-simulation">
                                <span class="me-2">
                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_6119_4331)">
                                            <path d="M13.7494 17.5004L12.2503 17.5023V16.25H8.25036V17.5082L6.751 17.5102C6.47538 17.5105 6.25194 17.7898 6.25163 18.1348L6.25006 19.3742C6.24975 19.7199 6.47413 20.0004 6.75069 20L13.7494 19.9898C14.025 19.9895 14.2485 19.7102 14.2488 19.3656L14.25 18.1262C14.2503 17.7805 14.026 17.5 13.7494 17.5004ZM18.5375 1.04453C18.4556 0.441797 18.0309 0 17.5331 0H2.96665C2.46884 0 2.04415 0.441797 1.96228 1.04453C0.146354 14.4203 0.251354 13.5969 0.251041 13.75C0.250104 14.4266 0.697601 15 1.26978 15H19.2303C19.8 15 20.2462 14.4312 20.2487 13.7582C20.2493 13.5988 20.3534 14.4203 18.5375 1.04453ZM8.36974 2.5H12.13L12.4353 6.25H8.06443L8.36974 2.5ZM6.02069 12.5H2.47165L3.06571 8.125H6.37725L6.02069 12.5ZM6.52975 6.25H3.32008L3.82914 2.5H6.83506L6.52975 6.25ZM7.55537 12.5L7.91162 8.125H12.5881L12.9444 12.5H7.55537ZM13.6647 2.5H16.6706L17.1797 6.25H13.97L13.6647 2.5ZM14.4791 12.5L14.1228 8.125H17.4344L18.0284 12.5H14.4791Z" fill="white"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_6119_4331">
                                                <rect width="20" height="20" fill="white" transform="translate(0.25)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>
                                <span>
                                    Simuler mon projet solaire
                                </span>
                            </button>
                        </div>
                        ${addOrRemoveButton}
                    </div>
                </div>
            `;
            return bodyComplement;
        };
    }

    // this function needs to be arrow
    private _handleClickOnMap = (e: any) => {
        const modularity = this.localStorageService.get('preferences').modularity.solar;
        const isNewElementAvailable = modularity.newElement;
        if (!isNewElementAvailable) {
            return;
        }

        const isNewRoofWithinTerritory = this._checkIsNewRoofWithinTerritory(e.latlng);
        if (!isNewRoofWithinTerritory) {
            return;
        }

        this.newRoofMarkerLayer = L.marker(e.latlng, { icon: this.iconMarker });
        const mainStage = this.solarService.mainStageObs.value;
        const isInitStage = [null, 'newRoofStage', 'initStage'].includes(mainStage);
        const isAnyResultDefined = this.solarService.results.length > 0;

        if (isInitStage) {
            if (isAnyResultDefined) {
                this.conflictNewElementsGroup.addLayer(this.newRoofMarkerLayer);
            } else {
                this.preSelectedExistingElementsGroup.clearLayers();

                this.preSelectedNewElementsGroup
                    .getLayers()
                    .filter((layer) => !this.selectedNewElementsGroup.hasLayer(layer))
                    .forEach((layer) => this.preSelectedNewElementsGroup.removeLayer(layer));
                this.preSelectedNewElementsGroup.addLayer(this.newRoofMarkerLayer);
            }
        } else {
            this.conflictNewElementsGroup.addLayer(this.newRoofMarkerLayer);
        }
    };

    private _checkIsNewRoofWithinTerritory(latlng: L.LatLng): boolean {
        const territoryScaleGroups = Object.values(this.terService.territoryScaleLayers);
        const territoryScaleGroup = territoryScaleGroups.find(
            (territoryScaleGroup: L.FeatureGroup) => territoryScaleGroup.getLayers().length,
        );

        if (!territoryScaleGroup) {
            return false;
        }

        const polygonLayer = (territoryScaleGroup as L.FeatureGroup).getLayers()[0];
        const geojson = (polygonLayer as L.GeoJSON).toGeoJSON();
        const geometryType = (geojson as any).features[0].geometry.type;

        const isMultiLineString = geometryType === 'MultiLineString';
        if (isMultiLineString) {
            const isPointWithinPolygon = this.mapService.checkIfPointIsWithinPolygon(
                latlng,
                polygonLayer,
            );
            return isPointWithinPolygon;
        }

        const isPointWithinPolygon = this.mapService.checkIfPointIsWithinMultiPolygon(
            latlng,
            polygonLayer,
        );

        return isPointWithinPolygon;
    }

    async setNewRoofPopup() {
        const latlng = this.newRoofMarkerLayer.getLatLng();
        const latitude = latlng.lat;
        const longitude = latlng.lng;

        this.addressLabel = await this.getAddress(latitude, longitude);

        const newRoofPopupContent = this._setNewRoofPopupContent();
        const popupMinWidth = this.usefulService.showMobileUi ? undefined : 400;
        this.newRoofMarkerLayer
            .bindPopup(newRoofPopupContent, {
                autoClose: true,
                closeOnClick: false,
                minWidth: popupMinWidth,
            })
            .openPopup();

        const popupElement: any = this.newRoofMarkerLayer.getPopup();
        const wrapper = popupElement.getElement();

        const simulateButton = $(wrapper).find('.start-new-cs-simulation');
        if (simulateButton) {
            simulateButton.one('click', () => {
                this.preSelectedNewElementsGroup.clearLayers();
                this.selectedNewElementsGroup.clearLayers();
                this.selectedNewElementsGroup.addLayer(this.newRoofMarkerLayer);
            });
        }

        const closeButton = $(popupElement._closeButton);
        closeButton.attr('title', 'Fermer');

        closeButton.one('click', () => {
            this.preSelectedNewElementsGroup.removeLayer(this.newRoofMarkerLayer);
        });
    }

    private _setNewRoofPopupContent(): string {
        const header = this._setHeaderNewPopupContent();
        const body = this._setBodyNewPopupContent();
        const bodyComplement = this._setBodyComplementnewPopupContent();

        const popupContent = `
            <div class="popup-content"
                <div>
                    ${header}
                    ${body}
                    ${bodyComplement}
                </div>
            </div>
        `;
        return popupContent;
    }

    private _setHeaderNewPopupContent() {
        const header = `
            <div class="fs-16 mb-2">
                <div class="text-st-primary">${this.addressLabel}</div>
            </div>
        `;
        return header;
    }

    private _setBodyNewPopupContent() {
        const body = `
            <div class="fs-16">
                <p class="m-0 mb-2">
                    Vous n'avez pas sélectionné de bâtiment.
                </p>
                <p class="m-0">
                    Vous pouvez
                    <span class="text-st-active">simuler un projet solaire</span>
                    à cet emplacement en renseignant ses caractéristiques.
                </p>
            </div>
        `;
        return body;
    }

    private _setBodyComplementnewPopupContent() {
        const bodyComplement = `
            <div class="d-flex mt-2">
                <button type="button" class="btn btn-st-primary fs-16 flex-grow-1 start-new-cs-simulation">
                    Simuler mon projet solaire
                </button>
            </div>
        `;
        return bodyComplement;
    }

    async getAddress(latitude: number, longitude: number) {
        const features = await this.searchService.reverseAddress(latitude, longitude);
        if (features.length === 0) {
            return '';
        }

        const feature = features[0];
        const name = feature.properties.name;
        const city = feature.properties.city;
        const postcode = feature.properties.postcode;
        const addressLabel = `${name}, ${postcode} ${city}`;
        return addressLabel;
    }

    private _openExistingReinitModal(layer: L.Polygon) {
        const confirmationMessage = [
            'Une simulation de projet solaire est en cours.',
            'Sélectionner une autre toiture mettra fin à cette simulation.',
            'Voulez-vous continuer ?',
        ];
        const modalRef = this.modalService.open(ConfirmationModalComponent);
        modalRef.componentInstance.header = 'Mettre fin à la simulation';
        modalRef.componentInstance.body = confirmationMessage.join('<br/>');
        modalRef.componentInstance.validateLabel = 'Confirmer';
        modalRef.result.then(
            async (result) => {
                this.preSelectedExistingElementsGroup.clearLayers();
                this.selectedNewElementsGroup.clearLayers();
                this.preSelectedExistingElementsGroup.clearLayers();
                this.selectedExistingElementsGroup.clearLayers();

                this.moduleService.closeModule('solar');
                this.preSelectedExistingElementsGroup.addLayer(layer);
                this.solarService.initResults();
                this.solarService.updateMainStage(null);

                const elementId = layer.feature.properties.id_ter;
                this.solarService.indicatorPlot.targetElement(elementId);

                const center = layer.getCenter();
                this.centerMapOnLayer(center);

                this.conflictExistingElementsGroup.removeLayer(layer);
            },
            (reason) => this.conflictExistingElementsGroup.removeLayer(layer),
        );
    }

    private _openNewRoofReinitModal() {
        const confirmationMessage = [
            'Une simulation de projet solaire est en cours.',
            'Sélectionner une autre toiture mettra fin à cette simulation.',
            'Voulez-vous continuer ?',
        ];
        const modalRef = this.modalService.open(ConfirmationModalComponent);
        modalRef.componentInstance.header = 'Mettre fin à la simulation';
        modalRef.componentInstance.body = confirmationMessage.join('<br/>');
        modalRef.componentInstance.validateLabel = 'Confirmer';
        modalRef.result.then(
            async (result) => {
                this.preSelectedExistingElementsGroup.clearLayers();
                this.selectedExistingElementsGroup.clearLayers();

                this.preSelectedNewElementsGroup.clearLayers();
                this.selectedNewElementsGroup.clearLayers();

                this.moduleService.closeModule('solar');
                this.preSelectedNewElementsGroup.addLayer(this.newRoofMarkerLayer);
                this.solarService.initResults();
                this.solarService.updateMainStage(null);

                const center = this.newRoofMarkerLayer.getLatLng();
                this.centerMapOnLayer(center);

                this.conflictNewElementsGroup.removeLayer(this.newRoofMarkerLayer);
            },
            (reason) => this.conflictNewElementsGroup.removeLayer(this.newRoofMarkerLayer),
        );
    }

    private _removeNewRoofElement() {
        this.solarService.selectedElements = this.solarService.selectedElements.filter(
            (element) => !element.isNew,
        );
    }
}
