('use strict');

import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as XLSX from 'xlsx';

import {
    AutoconsumptionConfig,
    PvSurplusResult,
    PvTotalityResult,
    PvResults,
} from 'src/app/models/Solar';

import { FilesService } from 'src/app/services/FilesService';
import { RestService } from '../RestService';
import { SolarService } from './cadastre-solaire.service';
import { TerService } from '../TerService';
import { UsefulService } from '../UsefulService';

@Injectable({
    providedIn: 'root',
})
export class SolarPvSurplusService {
    public pvSurplusStageObs = new BehaviorSubject<string>(null);
    public pvSurplusStageObs$: Observable<string> = this.pvSurplusStageObs.asObservable();

    public pvInfo: any;

    private equipments: any = null;
    public userPvData: {
        typology: { value: string; label: string };
        housing: {
            parameters: {
                active: boolean;
                usages: { value: string; label: string; active: boolean; message: string }[];
                usableFloorArea: { value: any };
                occupantCount: { value: any };
            };
        };
        electricity: {
            active: boolean;
            energyConsumed: { value: any };
            subscribedPower: { value: any };
        };
        moduleCount: any;
        moduleArea: any;
        peakPower: any;
    };

    public importedElectricLoad: {
        isLoading: boolean;
        start: Date;
        end: Date;
        fillRate: number;
        isValid: boolean;
        error: string;
        load: Array<{ date: Date; value: number }>;
        energy: number;
        subscribedPower: number;
    };

    private autoconsumptionCsvBodyTmp = [
        ['Projet Photovoltaique en autoconsommation avec revente du surplus', '', '', 'infoDetail'],
        ["Montant de l'investissement", 'investment', '€', 'invesmentDetail'],
        ['Nombre de modules installés', 'totalModuleCount', '-', ''],
        ['Puissance crète installée', 'totalPowerPeak', 'kWc', ''],
        ["Taux d'autoconsumption", 'autoconsumptionRate', '%', ''],
        ['Productible Photovoltaique total', 'totalProduction', 'kWh/an', 'productionDetail'],
        ['Temps de retour sur investissement espéré', 'roi', 'années', ''],
        ["Montant annuel de revente de l'énergie produite", 'sellingGain', '€', 'economyDetail'],
        ['Economie réalisée', 'economy', '€/an', ''],
        ["Prime à l'autoconsommation", 'bonus', '€', ''],
        ['', '', '', ''],
    ];

    public pvSurplusResults: PvSurplusResult[];
    public currentResult: PvSurplusResult;

    public bonus: number;
    public investment: number;
    public totalPowerPeak: number;
    public totalModuleCount: number;
    public totalModuleArea: number;
    public totalProduction: number;
    public selfConsumptionRate: number;
    public selfProductionRate: number;
    public selfConsumptionEnergy: number;
    public surplusEnergy: number;
    public saving: number;
    public sellingGain: number;
    public totalGain: number;
    public roi: number;
    public connectionCost: any;

    constructor(
        @Inject(FilesService) private filesService: FilesService,
        @Inject(RestService) private restService: RestService,
        @Inject(SolarService) private solarService: SolarService,
        @Inject(TerService) private terService: TerService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {}

    clear() {
        this._clearObservables();
    }

    private _clearObservables() {
        this.pvSurplusStageObs.next(null);
    }

    updateStage(stage: string) {
        this.pvSurplusStageObs.next(stage);
    }

    setPvInfo(pvInfo: any) {
        this.pvInfo = pvInfo;
        this.equipments = JSON.parse(JSON.stringify(pvInfo.equipments));
        this.userPvData.housing.parameters.usages = JSON.parse(JSON.stringify(pvInfo.equipments));
    }

    initImportedElectricLoad() {
        this.importedElectricLoad = {
            isLoading: false,
            start: null,
            end: null,
            fillRate: null,
            isValid: null,
            error: '',
            load: [],
            energy: null,
            subscribedPower: null,
        };
    }

    async simulatePvSurplus(): Promise<PvResults> {
        const elements = this.solarService.selectedElements.map((element) => ({
            latitude: element.center.latitude,
            longitude: element.center.longitude,
            height: element.height,
            tilt: element.tilt,
            azimuth: element.azimuth,
            usableRoofArea: element.usableRoofArea,
            exists: !element.isNew,
            installationType: element.installationType,
        }));

        const data: any = {
            elements: elements,
            year: this.terService.geoYear,
            scaleTypeId: this.terService.territoryScale.typeId,
            territoryIds: this.terService.territories.map((t) => t.id),
            typology: this.userPvData.typology.value,
            electricLoadDates: this.importedElectricLoad.load.map((load) => load.date),
            electricLoadValues: this.importedElectricLoad.load.map((load) => load.value),
        };
        if (this.userPvData.typology.value == 'housing') {
            const isCustomElectricLoadDefined = this.importedElectricLoad.load.length > 0;
            if (!isCustomElectricLoadDefined) {
                const usages = this.userPvData.housing.parameters.usages
                    .filter((usage) => usage.active)
                    .map((usage) => usage.value);

                data.usableFloorArea = this.userPvData.housing.parameters.usableFloorArea.value;
                data.occupantCount = this.userPvData.housing.parameters.occupantCount.value;
                data.usages = usages;
                data.electricConsumption = Number(this.userPvData.electricity.energyConsumed.value);
            }
        }
        const response = await this.restService.simulatePvSurplus(data);
        this.pvSurplusResults = response.selfConsumption;
        return response;
    }

    getAutoconsumption(data: any) {
        return this.restService.getAutoconsumption(data);
    }

    setCurrentResult(result: PvSurplusResult) {
        this.currentResult = result;

        this.bonus = result.bonus;
        this.investment = result.investment;
        this.totalPowerPeak = result.peakPower;
        this.totalModuleCount = result.moduleCount;
        this.totalModuleArea = result.totalModuleArea;
        this.totalProduction = result.annualProduction;
        this.selfConsumptionRate = result.selfConsumptionRate;
        this.selfProductionRate = result.selfProductionRate;
        this.selfConsumptionEnergy = result.selfConsumptionEnergy;
        this.surplusEnergy = result.surplusEnergy;
        this.saving = result.saving;
        this.sellingGain = result.sellingGain;
        this.totalGain = result.totalGain;
        this.roi = result.roi;
    }

    getMaxModuleResult(): PvSurplusResult {
        return this.pvSurplusResults[this.pvSurplusResults.length - 1];
    }

    findResultByModuleCount(moduleCount: number) {
        return this.pvSurplusResults.find((result) => result.moduleCount == moduleCount);
    }

    findOptimalResult(): PvSurplusResult {
        const optimalResult = this.pvSurplusResults
            .slice(1)
            .reduce(
                (optimal: PvSurplusResult, config: PvSurplusResult) =>
                    optimal.roi > config.roi ? config : optimal,
                this.pvSurplusResults[0],
            );
        return optimalResult;
    }

    setDefaultUsableFloorArea() {
        let buildingIds = this.solarService.selectedElements.map((element) => element.id_cons);
        buildingIds = [...new Set(buildingIds)];
        const usableFloorArea = buildingIds.reduce((usableFloorArea, buildingId) => {
            const element = this.solarService.selectedElements.find(
                (element) => element.id_cons == buildingId,
            );
            usableFloorArea += element.surface_habitable ? element.surface_habitable : 0;
            return usableFloorArea;
        }, 0);
        return Math.round(usableFloorArea);
    }

    setDefaultOccupantCount() {
        return 3;
    }

    getAutoconsumptionCsvSchema() {
        return [...this.autoconsumptionCsvBodyTmp];
    }

    async getHousingElectricityConsumption(userData: any) {
        const usages = userData.housing.parameters.usages
            .filter((usage: any) => usage.active)
            .map((usage: any) => usage.value);

        const parameters = {
            indicatorId: this.solarService.indicatorPlot.indicatorId,
            usages: JSON.stringify(usages),
            usableFloorArea: userData.housing.parameters.usableFloorArea.value,
            occupantCount: userData.housing.parameters.occupantCount.value,
            scaleTypeId: this.terService.territoryScale.typeId,
            territoryId: this.terService.territories[0].id,
            year: this.solarService.indicatorPlot.crrsdc_ter_year_geo,
        };

        return await this.restService.getHousingElectricityConsumption(parameters);
    }

    addConnectionCost(connectionCost: any) {
        const currentResult = this.currentResult;
        const totalGain = currentResult.totalGain;
        const initInvestment = currentResult.investment;
        currentResult.connectionCost = connectionCost;
        this.connectionCost = connectionCost;

        if (connectionCost.value) {
            currentResult.investment = initInvestment + connectionCost.value;
            currentResult.roi = currentResult.investment / totalGain;

            this.investment = currentResult.investment;
            this.roi = currentResult.roi;
        }
    }

    convertImportElectricLoadType1ToJson(ws: XLSX.WorkSheet): Array<{ date: Date; value: number }> {
        // CDC prévert-CSV - Réelle
        const jsonData = XLSX.utils
            .sheet_to_json(ws, {
                header: ['date', 'value'],
                range: 0,
                raw: false,
                defval: '',
            })
            .map((row: { date: string; value: string }) => ({
                date: new Date(row.date),
                value: row.value,
            }));

        const parsedDate = SolarPvSurplusService._parseImportElectricLoad(jsonData);
        return parsedDate;
    }

    convertImportElectricLoadType2ToJson(ws: XLSX.WorkSheet): Array<{ date: Date; value: number }> {
        // export_courbe_charges_sieds
        const jsonData = XLSX.utils
            .sheet_to_json(ws, {
                header: 1,
                range: 1,
                raw: false,
                defval: '',
            })
            .map((row) => ({
                date: SolarPvSurplusService._parseDate(`${row[0]} ${row[1]}`),
                value: row[4],
            }));

        const parsedDate = SolarPvSurplusService._parseImportElectricLoad(jsonData);
        return parsedDate;
    }

    convertImportElectricLoadType3ToJson(ws: XLSX.WorkSheet): Array<{ date: Date; value: number }> {
        //  csv residentiel
        // let range = XLSX.utils.decode_range(ws['!ref']);
        // range.s.r = 2; // Skip first two rows
        // ws['!ref'] = XLSX.utils.encode_range(range);
        const jsonData = XLSX.utils
            .sheet_to_json(ws, {
                header: 1,
                range: 3,
                raw: false,
                defval: '',
            })
            .map((row) => ({
                date: new Date(row[0]),
                value: row[1],
            }));

        const parsedDate = SolarPvSurplusService._parseImportElectricLoad(jsonData);
        return parsedDate;
    }

    private static _parseImportElectricLoad(
        jsonData: Array<{ date: Date; value: string }>,
    ): Array<{ date: Date; value: number }> {
        try {
            const parsedData = jsonData.map((row) => ({
                date: new Date(row.date),
                value:
                    row.value == ''
                        ? null
                        : parseFloat(row.value.replace(/\s/g, '').replace(',', '.')),
            }));
            const isAnyNaNValue = parsedData.some((row) => isNaN(row.value));
            if (isAnyNaNValue) {
                const nanValue = parsedData.find((row) => isNaN(row.value));
                throw new Error(`Some values are NaN. Check row ${nanValue.date}`);
            }
            const isAnyInvalidDate = parsedData.some((row) => isNaN(row.date.getTime()));
            if (isAnyInvalidDate) {
                throw new Error('Some dates are invalid.');
            }

            const mostRecentParsedData =
                SolarPvSurplusService._getOnLyOneYearMostRecentData(parsedData);

            return mostRecentParsedData;
        } catch (error) {
            throw new Error(error);
        }
    }

    private static _parseDate(dateString: string) {
        // Split the date and time parts
        const [datePart, timePart] = dateString.split(' ');

        // Split the date part into day, month, and year
        const [day, month, year] = datePart.split('/').map(Number);

        // Split the time part into hours, minutes, and seconds
        const [hours, minutes, seconds] = timePart.split(':').map(Number);

        // Create and return the Date object
        return new Date(year, month - 1, day, hours, minutes, seconds);
    }

    private static _getOnLyOneYearMostRecentData(parsedData: Array<{ date: Date; value: number }>) {
        const mostRecentDate = new Date(parsedData[parsedData.length - 1].date);
        // Calculate the cutoff date (one year before the first object's date)
        const cutoffDate = new Date(mostRecentDate);
        cutoffDate.setFullYear(mostRecentDate.getFullYear() - 1);

        // Filter the array to include only objects with dates more recent than the cutoff date
        const mostRecentParsedData = parsedData.filter((obj) => new Date(obj.date) >= cutoffDate);
        return mostRecentParsedData;
    }

    async analyseElectricLoad(electricLoad: Array<{ date: Date; value: number }>): Promise<any> {
        const data = {
            dates: electricLoad.map((load) => load.date),
            values: electricLoad.map((load) => load.value),
            year: this.terService.geoYear,
            scaleTypeId: this.terService.territoryScale.typeId,
            territoryIds: this.terService.territories.map((t) => t.id),
        };
        return await this.restService.analyseElectricLoad(data);
    }

    async readImportedElectricLoad(file: any) {
        try {
            const rows = await this.filesService.readCsv(file);
            const importedElectricLoad = [];

            if (!rows.length) {
                throw 'File is empty.';
            }

            if (rows.length != 8763) {
                throw `File size in incorrect (reading ${rows.length}).`;
            }

            for (let i = 3; i < rows.length; i++) {
                const row = rows[i];
                const value = Number(row[1]);

                if (isNaN(value)) {
                    throw `${value} is not a number.`;
                }
                importedElectricLoad.push(value);
            }
            return importedElectricLoad;
        } catch (error) {
            console.error('Error readImportedElectricLoad', error);
            throw error;
        }
    }

    calcImportedElectricEnergyConsumed(load: number[]) {
        const energy = load.reduce((sum, value) => sum + value / 1e3, 0);
        return this.usefulService.round(energy);
    }

    calcImportedSubscribedPower(load: number[]) {
        const subscribedPower = Math.max(...load) / 1e3;
        return this.usefulService.round(subscribedPower);
    }

    initUserPvData() {
        this.userPvData = {
            typology: {
                value: 'housing',
                label: 'Résidentiel',
            },
            housing: {
                parameters: {
                    active: true,
                    usages: this.equipments ? this.equipments : [],
                    usableFloorArea: {
                        value: null,
                    },
                    occupantCount: {
                        value: null,
                    },
                },
            },
            electricity: {
                active: false,
                energyConsumed: {
                    value: null,
                },
                subscribedPower: {
                    value: null,
                },
            },
            moduleCount: null,
            moduleArea: null,
            peakPower: null,
        };
    }

    initEquipments() {
        this.userPvData.housing.parameters.usages = this.equipments;
    }

    // getCsvData() {
    //     const data: any = {};
    //     for (const key in this.currentConfig) {
    //         const value = this.currentConfig[key];
    //         data[key] = Math.round(value);
    //     }
    //     data.totalPowerPeak = this.usefulService.ceil(this.currentConfig.totalPowerPeak, 1);

    //     const autoconsumptionRate = this.currentConfig.autoconsumptionRate;
    //     data.autoconsumptionRate = Math.round(autoconsumptionRate * 100);

    //     this.solarService.setTotalGrossRoofArea();
    //     data.totalGrossRoofArea = this.solarService.totalGrossRoofArea;

    //     return data;
    // }
}
