'use strict';

import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReversePipe } from 'ngx-pipes';
import { NgbAccordion, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
    PrElement,
    PrFeature,
    PrLine,
    PrParameter,
    PrHypothesisValue,
} from 'src/app/models/PrTypes';
import { Indicator } from 'src/app/models/Indicator';
import { Position } from 'geojson';

import { AuthService } from 'src/app/services/AuthService';
import { DataService } from 'src/app/services/DataService';
import { EventService } from 'src/app/services/event.service';
import { LoaderService } from 'src/app/services/LoaderService';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MapService } from 'src/app/services/map.service';
import { ModuleService } from 'src/app/services/module.service';
import { PlotIndicatorService } from 'src/app/services/plotIndicator/plot-indicator.service';
import { ProsperReseauxService } from 'src/app/services/prosper-reseaux/prosper-reseaux.service';
import { ProsperReseauxClearService } from 'src/app/services/prosper-reseaux/prosper-reseaux-clear.service';
import { ProsperReseauxElementService } from 'src/app/services/prosper-reseaux/prosper-reseaux-element.service';
import { ProsperReseauxApplyChangesService } from 'src/app/services/prosper-reseaux/prosper-reseaux-apply-changes.service';
import { UsefulService } from 'src/app/services//UsefulService';
import { ProsperReseauxNewElementModal } from './modals/prosper-reseaux-new-element.component';
import { ConfirmationPRModalComponent } from './modals/confirmation-modal.component';

const BUILDING = '01';
const BT_LINE = '05';
const BT_START = '08';
const HTA_BT_TRANSFORMER = '09';
const HTA_BT_POST = '10';
const HTA_LINE = '15';
const HTA_START = '18';
const HTA_TRANSFORMER = '19';
const DEFAULT_HTA_LENGTH = 500;
const DEFAULT_BT_LENGTH = 30;

@UntilDestroy()
@Component({
    selector: 'prosperReseaux',
    templateUrl: './prosper-reseaux.template.html',
    providers: [ReversePipe],
})
export class ProsperReseauxComponent implements OnInit {
    @ViewChild('acc') accordionComponent: NgbAccordion;
    @ViewChild('accElement') accordionElementComponent: NgbAccordion;
    @Input() unit: string;
    @Input() indicator: Indicator;

    prElements: any;
    elementsToModify: any;

    userSelection = {
        applyChangesOptions: [
            {
                value: 'allFromStart',
                label: 'À tout le départ',
            },
            {
                value: 'upstream',
                label: 'En amont',
            },
            {
                value: 'downstream',
                label: 'En aval',
            },
        ],
    };
    isAnyHypothesisAvailable = false;
    isDrawingEnable = false;
    isSimulationRunning = false;

    show = {
        main: true,
        searchElement: false,
        prosperActionsScenario: false,
    };

    isProsperActionsScenariosAvailable: boolean;

    applyChangesInfo = {
        stream: {
            header: '',
            body: '',
        },
        element: null,
        hypothesisInfo: [],
    };

    modal: any;
    elementsInfo = { elements: [], label: '', action: '' };
    modalResult: {
        isConfirmed: boolean;
        data: any;
    };

    constructor(
        private modalService: NgbModal,
        private notification: ToastrService,
        @Inject(AuthService) private authService: AuthService,
        @Inject(DataService) private dataService: DataService,
        @Inject(EventService) private eventService: EventService,
        @Inject(LoaderService) private loaderService: LoaderService,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(MapService) private mapService: MapService,
        @Inject(ModuleService) public moduleService: ModuleService,
        @Inject(PlotIndicatorService) private plotIndicatorService: PlotIndicatorService,
        @Inject(ProsperReseauxService) public prService: ProsperReseauxService,
        @Inject(ProsperReseauxClearService) private prClearService: ProsperReseauxClearService,
        @Inject(ProsperReseauxApplyChangesService)
        private prApplyChangesService: ProsperReseauxApplyChangesService,
        @Inject(ProsperReseauxElementService)
        private prElementService: ProsperReseauxElementService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {
        this.prClearService.clear();
    }

    ngOnInit(): void {
        this.isProsperActionsScenariosAvailable = this.authService.user.isProsper;

        this.eventService.selectionTerritoryUpdated
            .pipe(untilDestroyed(this))
            .subscribe(() =>
                this.prService.prElements.forEach((prElement) =>
                    this.prService.updateElementType(prElement.id),
                ),
            );

        this.eventService.indicatorDataAdded
            .pipe(untilDestroyed(this))
            .subscribe(async (indicatorData: any) => {
                const isPrIndicator: boolean = indicatorData.plugin == 'prosper_reseaux';
                if (isPrIndicator) {
                    const indicatorId = indicatorData.id_indicateur;
                    const vectorBase = indicatorData.vector_base;
                    const typeId = vectorBase.substring(7, 9);

                    await Promise.all([
                        this.getPrElementLabels(typeId),
                        this.prService.getPrParameters(indicatorId),
                    ]);
                    this.prService.updateHypothesisWithParameters();

                    this.prService.setCollapsePrTypeIds();
                    this.prService.setCollapseElementIds();
                    this.setIsAnyHypothesisAvailable();
                }

                // console.log('elementsToModifyByType', this.prService.elementsToModifyByType);
                // console.log('prElements', this.prService.prElements);
            });

        this.eventService.indicatorDataDeleted.pipe(untilDestroyed(this)).subscribe(() => {
            const isAnyPrIndicator = this.dataService.activeIndicatorsList.some(
                (indicatorData) => indicatorData.plugin == 'prosper_reseaux',
            );
            if (!isAnyPrIndicator) {
                this.prService.miscInfo.isInitialized = false;
                this.prService.miscInfo.selectedElement = null;
                this.setIsAnyHypothesisAvailable();
                this.prClearService.clear();
            }
        });

        this.eventService.clickOnProsperReseauxElement
            .pipe(untilDestroyed(this))
            .subscribe((elementData: any) => {
                const typeId = elementData.typeId;
                elementData.typeLabel = this.prService.findPrElementByTypeId(typeId).label;
                this.prService.miscInfo.selectedElement = elementData;
                this.moduleService.openModule('prosperReseaux');

                this.selectElementOnMap();
            });

        this.eventService.indicatorPloted
            .pipe(untilDestroyed(this))
            .subscribe((indicatorId: any) => {
                this.setIsDrawingAvailable(indicatorId);
            });

        this.eventService.indicatorUnploted
            .pipe(untilDestroyed(this))
            .subscribe((indicatorId: any) => {
                const indicatorPlots: Indicator[] = Object.values(
                    this.plotIndicatorService.plotedIndicators,
                );
                this.prService.deleteIndicatorIdFromHypothesis(indicatorId, indicatorPlots);
                this.prService.setCollapsePrTypeIds();
                this.prService.setCollapseElementIds();
                this.setIsAnyHypothesisAvailable();
            });

        this.eventService.prosperReseauxHypothesisUpdated
            .pipe(untilDestroyed(this))
            .subscribe(() => this.update());
    }

    expandAccordion(id: string, level = 'type'): void {
        if (level == 'type') {
            this.prService.openPrTypeCollapse(id);
            this.accordionComponent.expand(id);
        } else {
            this.prService.openElementCollapse(id);
            this.accordionElementComponent.expand(id);
        }
    }

    collapseAccordion(id: string, level = 'type'): void {
        if (level == 'type') {
            this.prService.closePrTypeCollapse(id);
            this.accordionComponent.collapse(id);
        } else {
            this.prService.closeElementCollapse(id);
            this.accordionElementComponent.collapse(id);
        }
    }

    isElementSelected(element: PrElement) {
        return this.prService.miscInfo.selectedElement
            ? element.id == this.prService.miscInfo.selectedElement.id
            : false;
    }

    switchTo(stage: string) {
        this.show.main = false;
        this.show[stage] = true;
    }

    prTypeAccordionOnChange(event: any) {
        const panelId = event.panelId;
        const state = event.nextState;
        if (state) {
            this.prService.openPrTypeCollapse(panelId);
        } else {
            this.prService.closePrTypeCollapse(panelId);
        }
    }

    elementAccordionOnChange(event: any) {
        const panelId = event.panelId;
        const state = event.nextState;
        if (state) {
            this.prService.openElementCollapse(panelId);
        } else {
            this.prService.closeElementCollapse(panelId);
        }
    }

    handleSelectCategoricalHypothesis(option: any, hypothesis: PrHypothesisValue) {
        hypothesis.value = option.value;
    }

    handleBlurCategoricalHypothesis(option: any, hypothesis: PrHypothesisValue) {
        hypothesis.value = '';
        if (option) {
            hypothesis.value = option.value;
        }
    }

    async getPrElementLabels(typeId: string) {
        try {
            await this.prService.getPrElementLabels(typeId);
        } catch (error) {
            console.error('Error getPrElementLabels', error);
            this.notification.error(
                "Une erreur s'est produite. Impossible de récupérer les noms des éléments du réseaux.",
            );
        }
    }

    async selectElementOnMap() {
        const elementData = this.prService.miscInfo.selectedElement;
        const id = elementData.id;
        const typeId = elementData.typeId;
        const isNew = elementData.isNew;

        if (!isNew) {
            const element = JSON.parse(JSON.stringify(this.prService.findElementById(id)));
            const indicatorPlots: Indicator[] = Object.values(
                this.plotIndicatorService.plotedIndicators,
            );
            await this.prService.addElement(element, indicatorPlots);
        } else {
            this.prService.elementsToModifyByType.forEach(
                (elementsToModify) => (elementsToModify.isActive = false),
            );

            const prElementToModify = this.prService.findElementsToModifyByTypeId(typeId);
            prElementToModify.isActive = true;
        }

        const collapseTypeId = `panel-${typeId}`;
        const collapseElementId = `panel-${id}`;

        this.expandAccordion(collapseTypeId, 'type');
        setTimeout(() => this.expandAccordion(collapseElementId, 'element'), 200);

        this.setIsAnyHypothesisAvailable();
        this.prService.miscInfo.isInitialized = true;

        setTimeout(
            () =>
                document.getElementById(`panel-${id}-header`).scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                }),
            400,
        );

        // console.log('elementsToModifyByType', this.prService.elementsToModifyByType);
        // console.log('prElements', this.prService.prElements);
    }

    setIsAnyHypothesisAvailable() {
        this.isAnyHypothesisAvailable = this.prService.elementsToModifyByType.some(
            (elementsToModify) =>
                elementsToModify.elements.some((element) => element.isHypothesisAvailable),
        );
    }

    async removeHypothesis(typeId: string, element: PrElement, toUpdate = true) {
        const prElementToModify = this.prService.findElementsToModifyByTypeId(typeId);
        const elementsToModify = prElementToModify.elements;

        const index = elementsToModify.indexOf(element);
        elementsToModify.splice(index, 1);

        if (element.isNew) {
            this._removeHypothesisForNewElement(typeId, element);

            if (toUpdate) {
                this.setIsAnyHypothesisAvailable();
                await this.update();
            }
        }

        const collapseElementId = `panel-${element.id}`;
        this.prService.closeElementCollapse(collapseElementId);
        if (!elementsToModify.length) {
            const collapseElementId = `panel-${element.id}`;
            this.prService.closeElementCollapse(collapseElementId);
        }

        // console.log('elementsToModifyByType', this.prService.elementsToModifyByType);
        // console.log('prElements', this.prService.prElements);
    }

    private _removeHypothesisForNewElement(typeId: string, element: PrElement) {
        const elementsToDelete = this.prService.prElements.reduce(
            (elementsToDelete, prElement) => (elementsToDelete[prElement.id] = []),
            {},
        );

        this.prService.deleteFromFeaturesToAdd(typeId, element);
        this.prService.deletePathFromElementsToModify(typeId, element, elementsToDelete);

        for (const typeId in elementsToDelete) {
            elementsToDelete[typeId].forEach((elementToRemove: any) =>
                this.removeHypothesis(typeId, elementToRemove, false),
            );
        }
    }

    targetElementOnMap(element: PrElement) {
        const energyId = '01';
        const typeId = element.typeId;
        const id = element.id;
        const label = element.label;

        const indicators = Object.values(this.plotIndicatorService.plotedIndicators);
        const targetVectorBase = `res_${energyId}_${typeId}`;
        const indicator: any = indicators.find(
            (indicator: any) => indicator.vector_base === targetVectorBase,
        );

        if (indicator) {
            indicator.targetElement(id);

            const typeLabel = this.prService.findPrElementByTypeId(typeId).label;
            this.prService.miscInfo.selectedElement = {
                id: id,
                label: label,
                energyId: energyId,
                typeId: typeId,
                typeLabel: typeLabel,
            };
        }
    }

    async editElement(element: PrElement) {
        const id = element.id;
        const typeId = element.typeId;
        const indicatorPlots: Indicator[] = Object.values(
            this.plotIndicatorService.plotedIndicators,
        );

        const elementsToModify = this.prService.findElementsToModifyByTypeId(typeId);
        const isElementAlreadyDefined = elementsToModify.elements.some(
            (element) => element.id == id,
        );
        if (!isElementAlreadyDefined) {
            const tmpElement = JSON.parse(JSON.stringify(this.prService.findElementById(id)));
            await this.prService.addElement(tmpElement, indicatorPlots);
        }

        this.setIsAnyHypothesisAvailable();

        const collapseTypeId = `panel-${typeId}`;
        const collapseElementId = `panel-${element.id}`;

        this.expandAccordion(collapseTypeId, 'type');
        setTimeout(() => this.expandAccordion(collapseElementId, 'element'), 200);

        setTimeout(
            () =>
                document.getElementById(`panel-${element.id}-header`).scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                }),
            400,
        );
    }

    async applyChanges(value: string, element: PrElement) {
        element.applyChanges = this.userSelection.applyChangesOptions.find(
            (option) => option.value == value,
        );

        let headerStream: string;
        let bodyStream: string;
        if (value == 'upstream') {
            headerStream = 'en amont';
            bodyStream = 'en amont';
        } else if (value == 'downstream') {
            headerStream = 'en aval';
            bodyStream = 'en aval';
        } else {
            headerStream = 'du départ';
            bodyStream = 'situés sur le départ';
        }
        this.applyChangesInfo.stream.header = `Modifier tous les éléments ${headerStream} ?`;
        this.applyChangesInfo.stream.body = bodyStream;
        this.applyChangesInfo.element = element;

        this.applyChangesInfo.hypothesisInfo = element.hypothesis
            .filter((hypothesis) => hypothesis.value != '')
            .reduce((hypothesisInfo, hypothesis) => {
                const value = !!hypothesis.options
                    ? hypothesis.options.find((option: any) => option.value == hypothesis.value)
                          .label
                    : this.usefulService.stringifyNumber(hypothesis.value);
                const data = {
                    label: hypothesis.label,
                    value: value,
                    unit: hypothesis.unit,
                };
                hypothesisInfo.push(data);
                return hypothesisInfo;
            }, []);

        const modalRef = this.modalService.open(ConfirmationPRModalComponent);
        modalRef.componentInstance.name = 'confirmationModal';
        modalRef.componentInstance.applyChangesInfo = this.applyChangesInfo;
        modalRef.result.then(
            (result) => this.confirmApplyChanges(),
            () => {},
        );
    }

    async confirmApplyChanges() {
        const element = this.applyChangesInfo.element;
        const stream = element.applyChanges.value;
        const typeId = element.typeId;

        const indicatorPlots = Object.values(this.plotIndicatorService.plotedIndicators);
        const indicatorPlot: Indicator = indicatorPlots.find(
            (indicatorPlot: Indicator) => indicatorPlot.vector_base == `res_01_${typeId}`,
        );

        const indicatorId = indicatorPlot ? indicatorPlot.indicatorId : null;
        try {
            element.isLineElementsLoading = true;

            this.prApplyChangesService.init(element);
            await this.prApplyChangesService.getElements(stream, indicatorId);
            await this.prApplyChangesService.addNewElements(indicatorPlots);
            this.prApplyChangesService.applyChangesToElements();

            this.setIsAnyHypothesisAvailable();
        } catch (error) {
            console.error('Error confirmApplyChanges', error);
            const label = element.applyChanges.label.toLowerCase();
            this.notification.error(
                `Une erreur s'est produite. Impossible d'appliquer les modifications ${label}.`,
            );
        } finally {
            element.isLineElementsLoading = false;
            // console.log('elementsToModifyByType', this.prService.elementsToModifyByType);
            // console.log('prElements', this.prService.prElements);
        }
    }

    async reset() {
        this.prClearService.clearElementsToModify();
        await this.update();
    }

    async update() {
        this.loaderService.start('updateProsperReseaux');
        this.isSimulationRunning = true;

        const refreshIntervals = false;
        const broadcastEvent = false;
        const promises = this.dataService.activeIndicatorsList
            .filter((indicatorData) => indicatorData.plugin == 'prosper_reseaux')
            .map((indicatorData) =>
                this.plotIndicatorService.updateIndicator(
                    indicatorData.id_indicateur,
                    refreshIntervals,
                    broadcastEvent,
                ),
            );

        try {
            await Promise.all(promises);
            this.setIsDrawingAvailable();

            const preferences = this.localStorageService.get('preferences');
            const isFilterModuleAvailable = preferences.modules.filters;
            if (isFilterModuleAvailable) {
                this.dataService.activeIndicatorsList
                    .filter((indicatorData) => indicatorData.plugin == 'prosper_reseaux')
                    .filter((indicatorData) => indicatorData.static_dynamic == 'dyn')
                    .forEach((indicatorData) =>
                        this.eventService.emit('modelingDataUpdated', indicatorData.id_indicateur),
                    );
            }
        } catch (error) {
            console.error('Error update', error);
            this.notification.error(
                "Une erreur s'est produite. Impossible de mettre à jour les hypothèses.",
            );
        } finally {
            this.loaderService.stop('updateProsperReseaux');
            this.isSimulationRunning = false;
            this.setIsAnyHypothesisAvailable();

            // console.log('elementsToModifyByType', this.prService.elementsToModifyByType);
            // console.log('prElements', this.prService.prElements);
        }
    }

    setIsDrawingAvailable(indicatorId?: number) {
        const indicatorPlots = indicatorId
            ? [this.plotIndicatorService.plotedIndicators]
            : Object.values(this.plotIndicatorService.plotedIndicators);

        this.prService.elementsToModifyByType.forEach((elementsToModify) =>
            elementsToModify.elements.forEach((element) =>
                this.prService.setIsDrawingAvailable(element, indicatorPlots),
            ),
        );
    }

    //########################################New network - depart ###########################//
    private _setElementInfo(typeId: string) {
        const element = this.prService.findPrElementByTypeId(typeId);
        const parameters: PrParameter[] = JSON.parse(JSON.stringify(element.parameters));
        parameters.forEach((parameter) => (parameter.reference = parameter.defaultValue));
        parameters.sort((a: any, b: any) => a.id - b.id);

        this.elementsInfo.elements.push({
            label: element.label,
            id: typeId,
            parameters: parameters,
            maxLineLength: this._setDefaultMaxLineLength(typeId),
        });
    }

    private _setDefaultMaxLineLength(typeId: string) {
        if (typeId === HTA_LINE) {
            return DEFAULT_HTA_LENGTH;
        }
        if (typeId === BT_LINE) {
            return DEFAULT_BT_LENGTH;
        }
        return;
    }

    async addStart(id: string, typeId: string, feature?: PrFeature) {
        const hypothesisForStart = await this.setHypothesisForStart(id, typeId, feature);
        const startHypothesis = hypothesisForStart.startHypothesis;
        const lineHypothesis = hypothesisForStart.lineHypothesis;
        const clientHypothesis = hypothesisForStart.clientHypothesis;
        const maxLineLength = hypothesisForStart.maxLineLength;
        feature = hypothesisForStart.feature;

        this.enableDrawExtension(
            feature,
            startHypothesis,
            lineHypothesis,
            clientHypothesis,
            maxLineLength,
        );
    }

    async setHypothesisForStart(
        id: string,
        raccTypeId: string,
        feature?: PrFeature,
        energyId: string = '01',
    ) {
        const isHtaBtTransformer = raccTypeId === HTA_BT_TRANSFORMER;
        const startTypeId = isHtaBtTransformer ? BT_START : HTA_START;

        this.elementsInfo.elements = [];
        this._setElementInfo(startTypeId);

        const lineTypeId = isHtaBtTransformer ? BT_LINE : HTA_LINE;
        this._setElementInfo(lineTypeId);

        const clientTypeId = isHtaBtTransformer ? BUILDING : HTA_BT_POST;
        this._setElementInfo(clientTypeId);

        const startHypothesis = [];
        const lineHypothesis = [];
        const clientHypothesis = [];
        let maxLineLength = this._setDefaultMaxLineLength(lineTypeId);

        const areParametersDefined = this.elementsInfo.elements.some(
            (element: any) => element.parameters.length,
        );
        if (areParametersDefined) {
            this.elementsInfo.action = 'Dessiner';
            this.elementsInfo.label =
                startTypeId == BT_START
                    ? 'Paramétrer un nouveau départ BT'
                    : 'Paramétrer un nouveau départ HTA';
            const results = await this.openNewElementModal();
            const startInfo: any = results.find((hypothesis: any) => hypothesis.id == startTypeId);
            startHypothesis.push(...startInfo.hypothesis);

            const lineInfo: any = results.find((hypothesis: any) => hypothesis.id == lineTypeId);
            lineHypothesis.push(...lineInfo.hypothesis);
            maxLineLength = lineInfo.maxLineLength;

            const clientInfo: any = results.find(
                (hypothesis: any) => hypothesis.id == clientTypeId,
            );
            clientHypothesis.push(...clientInfo.hypothesis);
        }

        // récupération du Geojson de l'élément sur lequel on se connecte
        if (!feature) {
            feature = this.prElementService.getGeoJsonElement(id, raccTypeId);
        }
        feature.properties.energyId = energyId;
        feature.properties.typeId = raccTypeId;

        return {
            startHypothesis: startHypothesis,
            lineHypothesis: lineHypothesis,
            clientHypothesis: clientHypothesis,
            maxLineLength: maxLineLength,
            feature: feature,
        };
    }

    enableDrawExtension(
        feature: PrFeature,
        startHypothesis: PrHypothesisValue[],
        lineHypothesis: PrHypothesisValue[],
        clientHypothesis: PrHypothesisValue[],
        maxLineLength: number,
    ) {
        this.mapService.enableDrawPolyLine((polyLine: GeoJSON.Feature) => {
            this.addStartCallBack(
                feature,
                startHypothesis,
                lineHypothesis,
                clientHypothesis,
                polyLine,
                maxLineLength,
            );
            this.isDrawingEnable = false;
            // this.setIsAnyHypothesisAvailable();
        });
        this.isDrawingEnable = true;
    }

    addStartCallBack = (
        feature: PrFeature,
        startHypothesis: PrHypothesisValue[],
        lineHypothesis: PrHypothesisValue[],
        clientHypothesis: PrHypothesisValue[],
        polyLine: any,
        maxLineLength: number,
    ) => {
        const typeId = feature.properties.typeId;
        const isHtaBtTransformer = typeId === HTA_BT_TRANSFORMER;
        const startTypeId = isHtaBtTransformer ? BT_START : HTA_START;
        const lineTypeId = isHtaBtTransformer ? BT_LINE : HTA_LINE;
        const clientTypeId = isHtaBtTransformer ? BUILDING : HTA_BT_POST;

        let firstPointCoord: Position;
        let lastPointCoord: Position;

        // Déplacement de firstPointCoord : On cherche à le décaler d'un metre par rapport au poste, par visibilité:
        const lastCoordIndex = polyLine.geometry.coordinates.length - 1;
        const distanceFromFirstPoint = this.mapService.distance(
            feature.geometry.coordinates,
            polyLine.geometry.coordinates[0],
        );
        const distanceFromLastPoint = this.mapService.distance(
            feature.geometry.coordinates,
            polyLine.geometry.coordinates[lastCoordIndex],
        );

        const distanceFromStart = 3;
        if (distanceFromFirstPoint < distanceFromLastPoint) {
            const line = [feature.geometry.coordinates, polyLine.geometry.coordinates[0]];
            firstPointCoord = this.mapService.lineSliceAlong(line, distanceFromStart);
            polyLine.geometry.coordinates[0] = firstPointCoord;
            lastPointCoord = polyLine.geometry.coordinates[lastCoordIndex];
        } else {
            const line = [
                feature.geometry.coordinates,
                polyLine.geometry.coordinates[lastCoordIndex],
            ];
            firstPointCoord = this.mapService.lineSliceAlong(line, distanceFromStart);
            polyLine.geometry.coordinates[lastCoordIndex] = firstPointCoord;
            lastPointCoord = polyLine.geometry.coordinates[0];
        }

        const newStartFeature = this.prElementService.createStart(
            feature.properties.id,
            firstPointCoord,
            startTypeId,
            startHypothesis,
        );

        // Create lines elements
        const newNetwork = this.prElementService.createLines(
            newStartFeature.properties.id,
            polyLine,
            lineTypeId,
            lineHypothesis,
            maxLineLength,
        );

        // Create New Client
        const newClientFeature = this.prElementService.createClient(
            newNetwork[newNetwork.length - 1].properties.id,
            lastPointCoord,
            clientTypeId,
            clientHypothesis,
        );

        // Création du chemin de l'electron;
        const electronPath = [];
        let aggCost = 0;
        let sens = 1;

        if (lineTypeId === HTA_LINE) {
            sens = -1;
            // En HTA le aggCost est dans l'autre sens (du poste HTABT vers le poste source....)
            aggCost = newNetwork.reduce(
                (agg: number, network: PrLine) => agg + network.properties.longueur_e,
                0,
            );
        }

        newNetwork.forEach((lineFeature: PrLine) => {
            electronPath.push({
                [typeId]: feature.properties.id,
                [lineTypeId]: lineFeature.properties.id,
                [startTypeId]: newStartFeature.properties.id,
                [clientTypeId]: newClientFeature.properties.id,
                aggCost: aggCost,
            });
            //aggCost start with 0 (if BT) or maxValue (if HTA) and increase/decrease over sens value
            aggCost = aggCost + sens * lineFeature.properties.longueur_e;
        });

        const elementsToModify = this.prService.findElementsToModifyByTypeId(typeId);
        const newPaths = elementsToModify.newPaths;

        newPaths.push({
            id: feature.properties.id,
            path: electronPath,
            aggCost: 0,
        });

        this.prService.updateHypothesisWithParameters();
        this.setIsAnyHypothesisAvailable();
        this.update();
    };

    //######################################## New network - transfo ##########################//
    addHtaBtTransformer = async (id: string, raccTypeId: string) => {
        const typeId = raccTypeId === HTA_BT_POST ? HTA_BT_TRANSFORMER : HTA_TRANSFORMER;

        this.elementsInfo.elements = [];
        this._setElementInfo(typeId);

        const areParametersDefined = this.elementsInfo.elements.some(
            (element: any) => element.parameters.length,
        );
        const transformerHypothesis = [];
        if (areParametersDefined) {
            this.elementsInfo.action = 'Valider';
            this.elementsInfo.label = 'Paramétrer un nouveau transformateur';
            const results = await this.openNewElementModal();
            const transformerInfo: any = results.find((hypothesis: any) => hypothesis.id == typeId);
            transformerHypothesis.push(...transformerInfo.hypothesis);
        }

        const feature = this.prElementService.getGeoJsonElement(id, raccTypeId);
        const coordinates = feature.geometry.coordinates;

        const newTransformerFeature = this.prElementService.createTransformer(
            id,
            coordinates,
            typeId,
            transformerHypothesis,
        );
        const newTransformerId = newTransformerFeature.properties.id;

        const elementToModify = this.prService.findElementsToModifyByTypeId(raccTypeId);
        const newPaths = elementToModify.newPaths;
        newPaths.push({
            id: id,
            path: [
                {
                    [raccTypeId]: id,
                    [typeId]: newTransformerId,
                },
            ],
        });

        await this.addStart(newTransformerId, typeId, newTransformerFeature);
    };

    //########################################Add Extension ###########################//
    async addExtension(id: string, lineTypeId: string) {
        this.elementsInfo.elements = [];
        this._setElementInfo(lineTypeId);

        const clientTypeId = lineTypeId === BT_LINE ? BUILDING : HTA_BT_POST;
        this._setElementInfo(clientTypeId);

        const areParametersDefined = this.elementsInfo.elements.some(
            (element: any) => element.parameters.length,
        );
        const lineHypothesis = [];
        const clientHypothesis = [];
        let maxLineLength = this._setDefaultMaxLineLength(lineTypeId);

        if (areParametersDefined) {
            this.elementsInfo.action = 'Dessiner';
            this.elementsInfo.label = 'Paramétrer une nouvelle extension';
            const results = await this.openNewElementModal();
            const lineInfo: any = results.find((hypothesis: any) => hypothesis.id == lineTypeId);
            lineHypothesis.push(...lineInfo.hypothesis);
            maxLineLength = lineInfo.maxLineLength;

            const clientInfo: any = results.find(
                (hypothesis: any) => hypothesis.id == clientTypeId,
            );
            clientHypothesis.push(...clientInfo.hypothesis);
        }

        // récupération du Geojson de l'élément sur lequel on se connecte
        const feature = this.prElementService.getGeoJsonElement(id, lineTypeId);
        this.mapService.enableDrawPolyLine((polyLine: any) => {
            this._addExtensionCallBack(
                feature,
                lineHypothesis,
                clientHypothesis,
                polyLine,
                maxLineLength,
            );
            this.isDrawingEnable = false;
        });
        this.isDrawingEnable = true;
    }

    private _addExtensionCallBack(
        lineFeature: PrFeature,
        lineHypothesis: any,
        clientHypothesis: any,
        geojson: any,
        maxLineLength: number,
    ) {
        const lineTypeId = lineFeature.properties.typeId;
        const isBtLine = lineFeature.properties.typeId === BT_LINE;
        const clientType = isBtLine ? BUILDING : HTA_BT_POST;

        // When creating a client, the geojson is a point, so we need to convert it to a line
        const geometryType = geojson.geometry.type;
        const newLine =
            geometryType === 'Point'
                ? {
                      geometry: {
                          type: 'MultiLineString',
                          coordinates: [geojson.geometry.coordinates, geojson.geometry.coordinates],
                      },
                  }
                : geojson;

        const lineCoordinates = lineFeature.geometry.coordinates;
        const distanceFromLineEnd = this.mapService.distance(
            lineCoordinates[lineCoordinates.length - 1],
            newLine.geometry.coordinates[0],
        );
        const distanceFromLineStart = this.mapService.distance(
            lineCoordinates[0],
            newLine.geometry.coordinates[0],
        );

        // Le vrai point de départ correspond l'extremité du troncon existant le plus proche
        newLine.geometry.coordinates[0] = lineCoordinates[0];
        if (distanceFromLineEnd < distanceFromLineStart) {
            newLine.geometry.coordinates[0] = lineCoordinates[lineCoordinates.length - 1];
        }

        const newNetworkFeature = this.prElementService.createLines(
            lineFeature.properties.id,
            newLine,
            lineTypeId,
            lineHypothesis,
            maxLineLength,
        );

        const lastPoint = newLine.geometry.coordinates[newLine.geometry.coordinates.length - 1];
        const newClient = this.prElementService.createClient(
            newNetworkFeature[newNetworkFeature.length - 1].properties.id,
            lastPoint,
            clientType,
            clientHypothesis,
        );

        // Création du chemin de l'election;
        const electronPath = [];
        let aggCost = 0;
        let sens = 1;

        if (lineTypeId === HTA_LINE) {
            sens = -1;
            //En HTA le aggCost est dans l'autre sens (du poste HTABT vers le poste source....)
            aggCost = newNetworkFeature.reduce(
                (agg, network) => agg + network.properties.longueur_e,
                0,
            );
        }

        newNetworkFeature.forEach((feature) => {
            electronPath.push({
                [lineTypeId]: feature.properties.id,
                [clientType]: newClient.properties.id,
                aggCost: aggCost,
            });
            aggCost += sens * feature.properties.longueur_e;
        });

        const elementsToModify = this.prService.findElementsToModifyByTypeId(lineTypeId);
        const newPaths = elementsToModify.newPaths;

        newPaths.push({
            id: lineFeature.properties.id,
            path: electronPath,
        });

        this.prService.updateHypothesisWithParameters();
        this.setIsAnyHypothesisAvailable();
        this.update();
    }

    async addClient(id: string, lineTypeId: string, energyId: string = '01') {
        this.elementsInfo.elements = [];
        this._setElementInfo(lineTypeId);

        const clientTypeId = lineTypeId === BT_LINE ? BUILDING : HTA_BT_POST;
        this._setElementInfo(clientTypeId);

        this.elementsInfo.elements = [];
        this._setElementInfo(clientTypeId);

        const areParametersDefined = this.elementsInfo.elements.some(
            (element: any) => element.parameters.length,
        );
        const clientHypothesis = [];
        const lineHypothesis = [];
        let maxLineLength = this._setDefaultMaxLineLength(lineTypeId);

        if (areParametersDefined) {
            this.elementsInfo.action = 'Dessiner';
            this.elementsInfo.label = 'Paramétrer un nouveau client';
            const results = await this.openNewElementModal();

            const lineInfo: any = results.find((hypothesis: any) => hypothesis.id == lineTypeId);
            lineHypothesis.push(...lineInfo.hypothesis);
            maxLineLength = lineInfo.maxLineLength;

            const clientInfo: any = results.find(
                (hypothesis: any) => hypothesis.id == clientTypeId,
            );
            clientHypothesis.push(...clientInfo.hypothesis);
        }

        const feature = this.prElementService.getGeoJsonElement(id, lineTypeId, energyId);
        this.mapService.enableDrawPoint((geojson: GeoJSON.FeatureCollection) => {
            this._addExtensionCallBack(
                feature,
                lineHypothesis,
                clientHypothesis,
                geojson,
                maxLineLength,
            );
            this.isDrawingEnable = false;
        });
        this.isDrawingEnable = true;
    }

    // ========================== Modal de saisie des hypothèses réseau avant de déssiner celui ci ================ //
    openNewElementModal(): Promise<[]> {
        return new Promise((resolve) => {
            const modalRef = this.modalService.open(ProsperReseauxNewElementModal, { size: 'lg' });
            modalRef.componentInstance.elementsInfo = this.elementsInfo;

            modalRef.componentInstance.valueSubmitted.subscribe((receivedEntry) => {
                const isConfirmed = receivedEntry.isConfirmed;

                if (isConfirmed) {
                    resolve(receivedEntry.data);
                }
            });
        });
    }

    stopDraw() {
        this.isDrawingEnable = false;
        this.mapService.disableDrawPoint();
        this.mapService.disableDrawPolyLine();
    }

    updateModalValue(value) {
        this.modalResult = value;
    }
}
