('use strict');

import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ActiveFilter } from '../models/ActiveFilter';
import { FilterCategory, Aggregation } from '../models/FilterCategory';

import { CatalogService } from './catalog.service';
import { DataService } from './DataService';
import { ImportedDataService } from './ImportedDataService';
import { ProsperReseauxService } from './prosper-reseaux/prosper-reseaux.service';
import { RestService } from './RestService';
import { TerService } from './TerService';
import { UsefulService } from './UsefulService';
import { Indicator } from '../models/Indicator';

@Injectable({
    providedIn: 'root',
})
export class FilterDataService {
    public filtersByIndicator: { [id: number]: Array<FilterCategory> } = {};
    private _filtersByIndicator = new BehaviorSubject<any>(null);
    filtersByIndicator$ = this._filtersByIndicator.asObservable();

    private _filteredIndicatorId = new BehaviorSubject<number>(null);
    filteredIndicatorId$: Observable<any> = this._filteredIndicatorId.asObservable();

    constructor(
        @Inject(CatalogService) private catalogService: CatalogService,
        @Inject(DataService) private dataService: DataService,
        @Inject(ImportedDataService) private importedDataService: ImportedDataService,
        @Inject(ProsperReseauxService) private prosperReseauxService: ProsperReseauxService,
        @Inject(RestService) private restService: RestService,
        @Inject(TerService) private terService: TerService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {}

    clear() {
        this.clearBase();
        this._clearObservables();
    }

    clearBase(indicatorId?: number) {
        if (indicatorId) {
            delete this.filtersByIndicator[indicatorId];
        } else {
            this.filtersByIndicator = {};
            this._clearObservables();
        }
    }

    private _clearObservables() {
        this._filtersByIndicator.next(null);
        this._filteredIndicatorId.next(null);
    }

    async init(indicatorPlot: any, savedFilters?: ActiveFilter[]) {
        try {
            const indicatorId = indicatorPlot.indicatorId;
            const isAlreadyDefined = !!this.filtersByIndicator[indicatorId];
            if (!isAlreadyDefined) {
                await this.initCriteria(indicatorId);
            }

            if (isAlreadyDefined) {
                await Promise.all([
                    this.updateCriteriaInitValue(indicatorId),
                    this.updateCriteriaValue(indicatorId),
                ]);
            } else {
                await this.updateCriteriaInitValue(indicatorId);
                this.filtersByIndicator[indicatorId].forEach((category) =>
                    category.criteria.forEach((criteria) => {
                        criteria.absoluteValue = criteria.initAbsoluteValue;
                        criteria.relativeValue = criteria.initRelativeValue;
                    }),
                );
            }

            // user can uncheck indicator checkbox during the request
            // so we must check if the indicator is still checked before continuing
            if (!this.dataService.activeIndicators[indicatorId]) return;

            if (savedFilters) {
                this.loadSavedFilters(indicatorId, savedFilters);
            }
            this._filtersByIndicator.next(this.filtersByIndicator);
        } catch (error: any) {
            console.error('Error init', error);
            this._filtersByIndicator.next(this.filtersByIndicator);
            throw error;
        }
    }

    async setAggregationValue(indicatorPlot: Indicator) {
        const indicatorId = indicatorPlot.indicatorId;
        const isImported = this.dataService.activeIndicators[indicatorId].isImported;

        let aggregationInfo: any;
        if (isImported) {
            aggregationInfo = this.importedDataService.getAggregation(indicatorId);
        } else {
            const indicatorData = this.dataService.activeIndicators[indicatorId];

            const data: any = {
                scale_int: this.terService.territoryScale.typeId, // that one is for back auth checkout
                scale_filter: this.terService.territories.map((t) => `'${t.id}'`).join(', '), // that one is for back auth checkout
                scale: this.terService.territoryScale.tbl_data,
                scaleTypeId: this.terService.territoryScale.typeId,
                year: indicatorData.crrsdc_ter_year_geo,
                territoryIds: this.terService.territories.map((t) => t.id),
                filters: this.createFiltersArray(indicatorId),
            };
            if (!indicatorData.isCustomTerritory) {
                data.granularityTypeId = indicatorPlot.granularity.typeId;
            }
            if (indicatorData.plugin == 'prosper_reseaux') {
                const typeId = indicatorData.vector_base.split('_').slice(-1)[0];
                data.modelingData = {
                    hypothesisInputs: this.prosperReseauxService.getHypothesisInputs(indicatorId),
                    valuesToAggregate: this.prosperReseauxService.getValuesToAggregate(typeId),
                };
            }
            aggregationInfo = await this.restService.getIndicatorAggregation(indicatorId, data);

            // user can uncheck indicator checkbox during the request
            // so we must check if the indicator is still checked before continuing
            if (!this.dataService.activeIndicators[indicatorId]) return;
        }

        const relatedIndicatorAggregation = this.formatAggregation(indicatorId, aggregationInfo);
        return relatedIndicatorAggregation;
    }

    private formatAggregation(indicatorId: number, aggregationInfo: any) {
        const label = aggregationInfo.label;
        const value = aggregationInfo.value;
        const decimalCount = aggregationInfo.decimalCount || 0;
        const unit = aggregationInfo.unit || '';
        const count = aggregationInfo.count;
        // const count = indicatorPlot.geojson.features.length;
        const method = aggregationInfo.method;
        const type = aggregationInfo.type;

        const labelMethod = method == 'mean' ? 'moyenne' : 'somme';

        const stringifiedValue =
            type == 'numerical'
                ? this.usefulService.stringifyNumber(this.usefulService.round(value, decimalCount))
                : 'N/A';

        let aggregationLabel = `${label} (${labelMethod}) : ${stringifiedValue}`;
        if (unit) {
            aggregationLabel += ` ${unit}`;
        }

        const stringifiedCount = this.usefulService.stringifyNumber(count);
        const elementCountLabel = `Nombre d'éléments affichés : ${stringifiedCount}`;

        const aggregation: Aggregation = {
            indicatorId: indicatorId,
            type: type,
            label: label,
            method: labelMethod,
            value: stringifiedValue,
            unit: unit,
            countLabel: elementCountLabel,
            aggregationLabel: aggregationLabel,
        };
        return aggregation;
    }

    async initCriteria(indicatorId: number) {
        try {
            const indicatorData = this.dataService.activeIndicators[indicatorId];
            if (!indicatorData) return;

            const isImported = indicatorData.isImported;
            if (isImported) {
                this.filtersByIndicator[indicatorId] =
                    this.importedDataService.setFilters(indicatorId);
            } else {
                const data: any = {};
                const isPrIndicator = indicatorData.plugin == 'prosper_reseaux';
                if (isPrIndicator) {
                    const typeId = indicatorData.vector_base.split('_').slice(-1)[0];
                    const modelingData = {
                        hypothesisInputs:
                            this.prosperReseauxService.getHypothesisInputs(indicatorId),
                        valuesToAggregate: this.prosperReseauxService.getValuesToAggregate(typeId),
                    };
                    data.modelingData = modelingData;
                }
                const filters = await this.restService.getIndicatorFilters(indicatorId, data);

                // user can uncheck indicator checkbox during the request
                // so we must check if the indicator is still checked before continuing
                if (!this.dataService.activeIndicators[indicatorId]) return;

                this.filtersByIndicator[indicatorId] = filters;
            }

            this.filtersByIndicator[indicatorId].forEach((category) => {
                category.criteria.forEach((criteria) => {
                    criteria.active = true;

                    criteria.absoluteValue = 0;
                    criteria.relativeValue = 0;

                    criteria.initAbsoluteValue = 0;
                    criteria.initRelativeValue = 0;
                });
            });
        } catch (error: any) {
            console.error('Error initCriteria', error);
            return Promise.reject(error);
        }
    }

    async updateCriteria(indicatorId: number) {
        try {
            const indicatorData = this.dataService.activeIndicators[indicatorId];

            const data: any = {};
            const isPrIndicator = indicatorData.plugin == 'prosper_reseaux';
            if (isPrIndicator) {
                const typeId = indicatorData.vector_base.split('_').slice(-1)[0];
                data.modelingData = {
                    hypothesisInputs: this.prosperReseauxService.getHypothesisInputs(indicatorId),
                    valuesToAggregate: this.prosperReseauxService.getValuesToAggregate(typeId),
                };
            }
            const newFilter = await this.restService.getIndicatorFilters(indicatorId, data);

            // user can uncheck indicator checkbox during the request
            // so we must check if the indicator is still checked before continuing
            if (!this.dataService.activeIndicators[indicatorId]) return;

            const currentFilter = this.filtersByIndicator[indicatorId];
            newFilter.forEach((newCategory: FilterCategory) => {
                const currentCategory = currentFilter.find(
                    (currentCategory) => currentCategory.id == newCategory.id,
                );
                newCategory.criteria.forEach((newCriteria) => {
                    const isAlreadyDefined = currentCategory.criteria.some(
                        (currentCriteria) => currentCriteria.id == newCriteria.id,
                    );
                    if (!isAlreadyDefined) {
                        newCriteria.active = true;
                        newCriteria.absoluteValue = 0;
                        newCriteria.relativeValue = 0;
                        newCriteria.initAbsoluteValue = 0;
                        newCriteria.initRelativeValue = 0;
                        currentCategory.criteria.push(newCriteria);
                    }
                });
            });
        } catch (error: any) {
            console.error('Error updateCriteria', error);
            return Promise.reject(error);
        }
    }

    async updateCriteriaInitValue(indicatorId: number) {
        try {
            const indicatorData = this.dataService.activeIndicators[indicatorId];
            if (!indicatorData) return;

            if (indicatorData.isImported) {
                this.filtersByIndicator[indicatorId] =
                    this.importedDataService.getCriteriaValue(indicatorId);
                return;
            }

            const data: any = {
                scale_int: this.terService.territoryScale.typeId, // that one is for back auth checkout
                scale_filter: this.terService.territories.map((t) => `'${t.id}'`).join(', '), // that one is for back auth checkout
                year: indicatorData.crrsdc_ter_year_geo,
                scaleTypeId: this.terService.territoryScale.typeId,
                territoryIds: this.terService.territories.map((t) => t.id),
            };

            if (indicatorData.plugin == 'prosper_reseaux') {
                const typeId = indicatorData.vector_base.split('_').slice(-1)[0];
                const modelingData = {
                    hypothesisInputs: this.prosperReseauxService.getHypothesisInputs(indicatorId),
                    valuesToAggregate: this.prosperReseauxService.getValuesToAggregate(typeId),
                };
                data.modelingData = modelingData;
            }
            const filtersValue = await this.restService.getIndicatorFiltersValue(indicatorId, data);

            // user can uncheck indicator checkbox during the request
            // so we must check if the indicator is still checked before continuing
            if (!this.dataService.activeIndicators[indicatorId]) return;

            this.setCriteriaValue(indicatorId, filtersValue);
        } catch (error) {
            console.error('Error updateCriteriaInitValue', error);
            return Promise.reject(error);
        }
    }

    async updateCriteriaValue(indicatorId: number) {
        try {
            const indicatorData = this.dataService.activeIndicators[indicatorId];
            if (!indicatorData) return;

            this.filtersByIndicator[indicatorId].forEach((category) =>
                category.criteria.forEach((criteria) => {
                    criteria.absoluteValue = 0;
                    criteria.relativeValue = 0;
                }),
            );

            if (indicatorData.isImported) {
                this.filtersByIndicator[indicatorId] =
                    this.importedDataService.getCriteriaValue(indicatorId);
                return;
            }

            const filters = this.createFiltersArray(indicatorId);
            const filtersValue = await this.getCriteriaValue(indicatorId, filters);

            // user can uncheck indicator checkbox during the request
            // so we must verify if the indicator is still checked before continuing
            if (!this.dataService.activeIndicators[indicatorId]) return;

            // user can change filters during the request
            // so we must verify if the filters are the same as before the request before continuing
            const afterRequestFilters = this.createFiltersArray(indicatorId);
            if (JSON.stringify(filters) != JSON.stringify(afterRequestFilters)) return;

            this.filtersByIndicator[indicatorId].forEach((category) => {
                const filterValue = filtersValue.find(
                    (categoryValue: FilterCategory) => categoryValue.id === category.id,
                );

                category.criteria.forEach((criteria) => {
                    const criteriaValue = filterValue.criteria.find(
                        (criteriaValue: any) => criteriaValue.id == criteria.id,
                    );
                    if (criteriaValue) {
                        criteria.absoluteValue = criteriaValue.absoluteValue || 0;
                        criteria.relativeValue = criteriaValue.relativeValue || 0;
                    }
                });
            });
        } catch (error) {
            console.error('Error updateCriteriaValue', error);
            return Promise.reject(error);
        }
    }

    async getCriteriaValue(indicatorId: number, filters: Array<string> = []) {
        try {
            const indicatorData = this.dataService.activeIndicators[indicatorId];
            if (!indicatorData) return;

            const data: any = {
                scale_int: this.terService.territoryScale.typeId, // that one is for back auth checkout
                scale_filter: this.terService.territories.map((t) => `'${t.id}'`).join(', '), // that one is for back auth checkout
                year: indicatorData.crrsdc_ter_year_geo,
                scaleTypeId: this.terService.territoryScale.typeId,
                territoryIds: this.terService.territories.map((t) => t.id),
                filters: filters,
            };

            if (indicatorData.plugin == 'prosper_reseaux') {
                const typeId = indicatorData.vector_base.split('_').slice(-1)[0];
                const modelingData = {
                    hypothesisInputs: this.prosperReseauxService.getHypothesisInputs(indicatorId),
                    valuesToAggregate: this.prosperReseauxService.getValuesToAggregate(typeId),
                };
                data.modelingData = modelingData;
            }

            const filtersValue = await this.restService.getIndicatorFiltersValue(indicatorId, data);
            return filtersValue;
        } catch (error) {
            console.error('Error getCriteriaValue', error);
            return Promise.reject(error);
        }
    }

    setCriteriaValue(indicatorId: number, filtersValue: any[]) {
        this.filtersByIndicator[indicatorId].forEach((category) => {
            const filterValue = filtersValue.find(
                (categoryValue: FilterCategory) => categoryValue.id === category.id,
            );

            category.criteria.forEach((criteria) => {
                const criteriaValue = filterValue.criteria.find(
                    (criteriaValue: any) => criteriaValue.id == criteria.id,
                );
                if (criteriaValue) {
                    criteria.initAbsoluteValue = criteriaValue.absoluteValue || 0;
                    criteria.initRelativeValue = criteriaValue.relativeValue || 0;
                }
            });
        });
    }

    reinitCriteriaValue(indicatorId: number) {
        this.filtersByIndicator[indicatorId].forEach((category: FilterCategory) => {
            category.criteria.forEach((criteria: any) => {
                criteria.active = true;
                criteria.absoluteValue = criteria.initAbsoluteValue;
                criteria.relativeValue = criteria.initRelativeValue;
            });
        });
    }

    createFiltersArray(indicatorId: number) {
        const filters = [];

        if (!this.filtersByIndicator[indicatorId]) {
            return filters;
        }

        this.filtersByIndicator[indicatorId].forEach((category) => {
            const column = category.champ_associe;

            category.criteria
                .filter((criteria) => !criteria.active && criteria.id == null)
                .forEach(() => filters.push(`${column} is not null`));

            category.criteria
                .filter((criteria) => !criteria.active && criteria.id != null)
                .forEach((criteria) => filters.push(`${column} is distinct from '${criteria.id}'`));
        });

        return filters;
    }

    createFilterForSave(indicatorId: number) {
        const activeFilters = this.filtersByIndicator[indicatorId].reduce(
            (activeFilters, category) => {
                const filters = category.criteria
                    .filter((criteria) => !criteria.active && !!criteria.id)
                    .map((criteria) => ({
                        indicatorId: indicatorId,
                        id_critere: category.id, // for now, use old name
                        id_dter: criteria.id, // for now, use old name
                    }));

                return activeFilters.concat(filters);
            },
            [],
        );

        return activeFilters;
    }

    loadSavedFilters(indicatorId: number, savedFilters: ActiveFilter[]) {
        this.filtersByIndicator[indicatorId].forEach((category) =>
            category.criteria.forEach(
                (criteria) =>
                    (criteria.active = !savedFilters.some(
                        (savedFilter) =>
                            savedFilter.id_critere === category.id && // for now, use old name
                            savedFilter.id_dter === criteria.id, // for now, use old name
                    )),
            ),
        );
    }

    async geTerritoryIndicatorFiltersValue(indicatorId: number, data: any) {
        try {
            const filtersValue = await this.restService.getIndicatorFiltersValue(indicatorId, data);
            return filtersValue;
        } catch (error) {
            console.error('Error geTerritoryIndicatorFiltersValue', error);
            throw error;
        }
    }

    async geCustomTerritoryIndicatorFiltersValue(indicatorId: number, data: any) {
        try {
            const filtersValue = await this.restService.getCustomTerritoryIndicatorFiltersValue(
                indicatorId,
                data,
            );
            return filtersValue;
        } catch (error) {
            console.error('Error geCustomTerritoryIndicatorFiltersValue', error);
            throw error;
        }
    }

    async getTerritoryIndicatorsValues(parameters: {
        id: number;
        scale: string;
        scale_filter_init: string;
        scale_init: string;
        year: number;
        filters?: string;
    }) {
        try {
            const indicatorId = parameters.id;
            const indicator = this.dataService.activeIndicators[indicatorId];

            if (indicator.isImported) {
                if (
                    parameters.scale_filter_init ==
                    this.terService.territories.map((t) => `'${t.id}'`).join(',')
                ) {
                    parameters.scale_filter_init = null;
                }
                return this.importedDataService.getDataSingleTer(parameters);
            }

            const filters = this.createFiltersArray(indicatorId);
            parameters.filters = filters.join(' and ');
            return this.restService.getIndicatorsValues(indicatorId, parameters);
        } catch (error) {
            console.error('Error getTerritoryIndicatorsValues', error);
            throw error;
        }
    }

    async getCustomTerritoryIndicatorsValues(parameters: {
        id: number;
        scale: string;
        scale_filter_init: string;
        scale_init: string;
        year: number;
        filters?: string;
    }) {
        try {
            const indicatorId = parameters.id;
            const indicator = this.dataService.activeIndicators[indicatorId];

            if (indicator.isImported) {
                if (
                    parameters.scale_filter_init ==
                    this.terService.territories.map((t) => `'${t.id}'`).join(',')
                ) {
                    parameters.scale_filter_init = null;
                }
                return this.importedDataService.getDataSingleTer(parameters);
            }

            const filters = this.createFiltersArray(indicatorId);
            parameters.filters = filters.join(' and ');
            return this.restService.getCustomTerritoryIndicatorsValues(indicatorId, parameters);
        } catch (error) {
            console.error('Error getCustomTerritoryIndicatorsValues', error);
            throw error;
        }
    }

    async getUnit(indicatorData: Indicator) {
        let unit = '';
        if (!indicatorData.isImported) {
            const filterIndicatorId = indicatorData.id_indic_for_filter_defaut;
            let filterIndicator = this.catalogService.findIndicator(filterIndicatorId);
            if (!filterIndicator) {
                filterIndicator = await this.dataService.getIndicator(filterIndicatorId, false);
            }
            unit = filterIndicator.unit;
        }
        return unit;
    }

    setCurrentFilteredIndicatorId(indicatorId: number) {
        this._filteredIndicatorId.next(indicatorId);
    }
}
