'use strict';

import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';

import { CadastreSolaireContactModalComponent } from '../../../../modals/cadastre-solaire-contact-modal.component';
import { CadastreSolaireInfoModalComponent } from '../../../../modals/cadastre-solaire-info-modal.component';

import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SolarPvTotalityService } from 'src/app/services/cadastre-solaire/cadastre-solaire-pv-totality.service';
import { SolarService } from 'src/app/services/cadastre-solaire/cadastre-solaire.service';
import { UsefulService } from 'src/app/services/UsefulService';
import { PvTotalityResult } from 'src/app/models/Solar';

@Component({
    selector: 'cadastreSolairePvTotality',
    templateUrl: './cadastre-solaire-pv-totality.template.html',
})
export class CadastreSolairePvTotalityComponent implements OnInit, AfterViewInit {
    public showMobileUi: boolean = false;
    public showLoader: boolean = true;

    public modularity: any;

    public slider: {
        maxModuleCount: number;
        maxPeakPower: number;
        moduleCount: number;
        moduleArea: number;
        peakPower: number;
        stringifiedPeakPower: string;
    };

    public tooltipPvInfoDetail: string;
    public tooltipPvInvestmentDetail: string;
    public tooltipPvProductionDetail: string;
    public tooltipPvSellingDetail: string;
    public tooltip: {
        investment: string;
        installation: string;
        production: string;
        economy: string;
    };

    public htmlPvInstallation: any;
    public htmlPvInvestment: string;
    public htmlPvProduction: string;
    public htmlPvProductionDetail: string;
    public htmlPvConsumptionDetail: string;
    public htmlPvEconomy: string;
    public htmlPvWarning: string;
    public htmlPvWarningSubcribedPower: string;
    public activeCollapseWarningPower: boolean = false;

    public adjustInstallationSizeLabel: string;
    public contactButtonLabel: string;
    public isResultsDownloading: boolean;

    public connectionCost: {
        isAvailable: boolean;
        isActive: boolean;
        isLoading: boolean;
        value: number;
        message: string;
    };

    public titleInvestment: string = 'Investissement';
    public titleProduction: string = 'Production';
    public titleEconomy: string = 'Economie';

    constructor(
        private modalService: NgbModal,
        private notification: ToastrService,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(SolarPvTotalityService) private solarPvTotalityService: SolarPvTotalityService,
        @Inject(SolarService) public solarService: SolarService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {}

    ngOnInit(): void {
        this.showMobileUi = this.usefulService.showMobileUi;
        this.modularity = this.localStorageService.get('preferences').modularity.solar;

        this.tooltipPvInfoDetail = this.solarPvTotalityService.pvInfo.message.info_detail;
        this.tooltipPvInvestmentDetail =
            this.solarPvTotalityService.pvInfo.message.investissement_detail;
        this.tooltipPvProductionDetail =
            this.solarPvTotalityService.pvInfo.message.production_detail;
        this.tooltipPvSellingDetail = this.solarPvTotalityService.pvInfo.message.economie_detail;
        this.htmlPvWarning = this.solarPvTotalityService.pvInfo.message.warning;
        this.htmlPvWarningSubcribedPower =
            this.solarPvTotalityService.pvInfo.message.warning_subcribed_power;

        this.tooltip = {
            investment: "L'investissement",
            installation: "L'installation",
            production: 'La production',
            economy: 'Les économies',
        };

        const customAdjustInstallationSizeLabel =
            this.solarService.moduleInfo.ajuster_taille_installation;
        this.adjustInstallationSizeLabel = customAdjustInstallationSizeLabel
            ? customAdjustInstallationSizeLabel
            : "J'ajuste la taille de mon installation";

        const customContactButtonLabel = this.solarService.moduleInfo.contacts_bouton;
        this.contactButtonLabel = customContactButtonLabel
            ? customContactButtonLabel
            : 'Aller plus loin';
    }

    async ngAfterViewInit(): Promise<void> {
        await this.initPvTotalitySimulation();

        const maxResult = this.solarPvTotalityService.getMaxModuleResult();
        this.solarPvTotalityService.setCurrentResult(maxResult);

        this.initConnectionCost();

        this.slider = {
            maxModuleCount: maxResult.moduleCount,
            maxPeakPower: maxResult.peakPower,
            moduleCount: maxResult.moduleCount,
            moduleArea: this.usefulService.round(maxResult.totalModuleArea),
            peakPower: maxResult.peakPower,
            stringifiedPeakPower: this.usefulService.stringifyNumber(
                this.usefulService.ceil(maxResult.peakPower, 1),
            ),
        };

        this._updatePvIndicator();

        const isNew = this.solarService.checkIfSelectedElementsIsNew();
        if (isNew) {
            this.solarService.setTotalModuleAreaToSelectionInfo(maxResult.totalModuleArea);
        }
    }

    private initConnectionCost() {
        this.connectionCost = {
            isAvailable: true,
            isLoading: false,
            isActive: true,
            value: null,
            message: '',
        };
    }

    async initPvTotalitySimulation() {
        try {
            this.showLoader = true;
            await this.solarPvTotalityService.simulatePvTotality();
        } catch (error) {
            console.error('Error initPvTotalitySimulation', error);
            this.notification.error(
                "Une erreur est survenue. Impossible d'initialiser la simulation photovoltaïque en vente totale.",
            );
            this.solarService.updatePvStage('pvValorizationStage');
        } finally {
            this.showLoader = false;
        }
    }

    cancel() {
        if (this.modularity.pvAutoconsumption) {
            this.solarService.updatePvStage('pvValorizationStage');
        } else {
            this.solarService.updateMainStage('technologyStage');
        }
    }

    private _updatePvIndicator() {
        if (this.solarPvTotalityService.currentResult.connectionCost) {
            this.connectionCost = this.solarPvTotalityService.currentResult.connectionCost;
        }

        this._updatePvInvestment();
        this._updatePvInstallation();
        this._updatePvProduction();
        this._updatePvSellingGain();

        this.solarPvTotalityService.setSubscribedPower();
        this.activeCollapseWarningPower = this._displayWarningSubcribedPower();
    }

    private _updatePvInvestment() {
        const splitByInvestmentTag =
            this.solarPvTotalityService.pvInfo.message.investissement.split('##pv_investment');
        if (splitByInvestmentTag.length === 2) {
            let html = splitByInvestmentTag.join(
                `<span class="text-st-active">${this._stringifyInvestment()}</span>`,
            );

            if (this.solarService.connectionCostInfo.isEnedisAvailable) {
                if (!this.connectionCost.isAvailable) {
                    this.htmlPvInvestment = `
                        <div>
                            ${html}
                            <div>
                                <small>
                                    Le coût du raccordement ne peut être estimé par Enedis, car la puissance souscrite est supérieure à 36\u00a0kVA.
                                </small>
                            </div>
                        </div>
                    `;
                    return;
                }

                if (this.connectionCost.value) {
                    const connectionCostStringified = this.usefulService.stringifyNumber(
                        this.connectionCost.value,
                    );
                    const initHtml = html.slice(0, -1);
                    this.htmlPvInvestment = `
                        <div class="text-start">
                           ${initHtml}, dont ${connectionCostStringified}\u00a0€ de raccordement.
                            <div>
                                <small>
                                    Coût de raccordement estimé grâce à Enedis.
                                </small>
                            </div>
                        </div>
                    `;
                    return;
                }

                if (this.connectionCost.message) {
                    this.htmlPvInvestment = `
                        <div>
                            ${html}
                            <div>Le coût du raccordement n'a pas pu être estimé. ${this.connectionCost.message}</div>
                        </div>
                    `;
                    return;
                }

                this.htmlPvInvestment = `
                    <div>
                        ${html}
                    </div>
                `;
            } else {
                let message: string;
                const connectionCostInfo = this.solarService.connectionCostInfo;
                if (connectionCostInfo.contactRegie) {
                    message = connectionCostInfo.contactRegie;
                } else if (connectionCostInfo.aecEstimation) {
                    message = connectionCostInfo.aecEstimation;
                } else {
                    message = '';
                }
                const htmlPvInvestment = `
                    <div>
                        ${html}
                        <div>${message}</div>
                    </div>
                `;

                this.htmlPvInvestment = htmlPvInvestment;
            }
        }
    }

    private _stringifyInvestment() {
        const investment = Math.round(this.solarPvTotalityService.investment);
        const investmentStringified = this.usefulService.stringifyNumber(investment);
        return `${investmentStringified}\u00a0€`;
    }

    private _updatePvInstallation() {
        let html = this.solarPvTotalityService.pvInfo.message.installation;

        // ##pv_module_count
        const isPvModuleCountDefined = html.includes('##pv_module_count');
        if (isPvModuleCountDefined) {
            let unit = this.solarPvTotalityService.totalModuleCount > 1 ? 'modules' : 'module';
            const pvModuleCountStringified = this.usefulService.stringifyNumber(
                this.slider.moduleCount,
            );
            const placeholder = `<span class="text-st-active">${pvModuleCountStringified}&nbsp;${unit}</span>`;
            html = html.replace('##pv_module_count', placeholder);
        }

        // ##pv_total_module_area
        const isPvTotalModuleAreaDefind = html.includes('##pv_total_module_area');
        if (isPvTotalModuleAreaDefind) {
            const roundedValue = this.usefulService.round(
                this.solarPvTotalityService.totalModuleArea,
            );
            const pvTotalModuleAreaStringified = this.usefulService.stringifyNumber(roundedValue);
            const placeholder = `<span class="text-st-active">${pvTotalModuleAreaStringified}&nbsp;m²</span>`;
            html = html.replace('##pv_total_module_area', placeholder);
        }

        // ##pv_power_peak
        const isPvPowerPeakDefined = html.includes('##pv_power_peak');
        if (isPvPowerPeakDefined) {
            const placeholder = `<span class="text-st-active">${this._stringifyPeakPower()}</span>`;
            html = html.replace('##pv_power_peak', placeholder);
        }

        html = `<span>${html}</span>`;
        this.htmlPvInstallation = html;
    }

    private _stringifyPeakPower() {
        const peakPower = this.usefulService.round(this.solarPvTotalityService.totalPowerPeak, 1);
        const powerPeakStringified = this.usefulService.stringifyNumber(peakPower);
        return `${powerPeakStringified}\u00a0kWc`;
    }

    private _updatePvProduction() {
        // ##pv_production_by_year
        const splitByProductionTag =
            this.solarPvTotalityService.pvInfo.message.production.split('##pv_production_by_year');
        if (splitByProductionTag.length === 2) {
            const pvProduction = Math.round(this.solarPvTotalityService.totalProduction);
            const pvProductionStringified = this.usefulService.stringifyNumber(pvProduction);

            let html = splitByProductionTag.join(
                `<span class="text-st-active">${pvProductionStringified}\u00a0kWh/an</span>`,
            );

            // ##housing_consumption_by_year_ratio
            const splitByHousingTag = html.split('##housing_consumption_by_year_ratio');
            if (splitByHousingTag.length === 2) {
                // dirty fix : actually we don't want to display the second part of the message
                html = splitByHousingTag[0].split(',')[0] + '.';
            }

            html = `<div>${html}</div>`;
            this.htmlPvProduction = html;
        }
    }

    async getConnectionCost() {
        this.connectionCost.isLoading = true;
        this.connectionCost.isActive = true;

        try {
            const totalPowerPeak = this.solarPvTotalityService.totalPowerPeak;
            const subscribedPower = this.solarPvTotalityService.setSubscribedPower();
            const result = await this.solarService.getConnectionCost(
                'Totality',
                subscribedPower,
                totalPowerPeak,
            );

            if (result.state) {
                this.connectionCost.value = result.cost.total;
            } else {
                this.connectionCost.message = result.message;
            }

            this.solarPvTotalityService.addConnectionCost(this.connectionCost);
            this._updateSellWithConnectionCost();

            this.connectionCost.isActive = false;
        } catch (error) {
            console.error('Error getConnectionCost', error);
            this.notification.error(
                `L'API Enedis Raccordement ne répond pas. Veuillez réessayer ultérieurement.`,
            );
            this.connectionCost.isActive = true;
        } finally {
            this.connectionCost.isLoading = false;
        }
    }

    async downloadPdf() {
        try {
            this.isResultsDownloading = true;

            const labelComplement = 'vente totale';

            const moduleAreaStringified = `${Math.round(
                this.solarPvTotalityService.totalModuleArea,
            )}\u00a0m²`;
            const results = {
                technology: 'pv',
                valorization: 'Totality',
                label: `Photovoltaïque ${labelComplement} - ${this._stringifyPeakPower()}`,
                totalPowerPeak: this.solarPvTotalityService.totalPowerPeak,
                htmlInstallation: this.htmlPvInstallation,
                htmlInvestment: this.htmlPvInvestment,
                htmlProduction: this.htmlPvProduction,
                htmlEconomy: this.htmlPvEconomy,
                moduleAreaStringified: moduleAreaStringified,
                peakPowerStringified: this._stringifyPeakPower(),
                investmentStringified: this._stringifyInvestment(),
                sellingGainStringified: this._stringifySellingGain(),
                roiStringified: this._stringifyRoi(),
                htmlProductionDetail: this.htmlPvProductionDetail,
                htmlConsumptionDetail: this.htmlPvConsumptionDetail,
            };

            const buffer = await this.solarService.getSolarPdfFile(results);
            const blob = new Blob([buffer], { type: 'application/pdf' });
            saveAs(blob, 'solaire-photovoltaique.pdf');
        } catch (error) {
            console.error('Error downloadPdf', error);
            this.notification.error(
                'Une erreur est survenue. Impossible de télécharger les résultats.',
            );
        } finally {
            this.isResultsDownloading = false;
        }
    }

    openContactModal() {
        const body =
            '<p> Vous voulez en savoir plus sur une installation solaire sur votre logement ? </p>';

        const modalRef = this.modalService.open(CadastreSolaireContactModalComponent);
        modalRef.componentInstance.body = body;
        modalRef.result.then(
            (result) => {},
            () => {},
        );
        modalRef.componentInstance.name = 'confirmationModal';
    }

    validate() {
        const labelComplement = 'vente totale';

        const moduleAreaStringified = `${Math.round(
            this.solarPvTotalityService.totalModuleArea,
        )}\u00a0m²`;

        const result = {
            technology: 'pv',
            valorization: 'Totality',
            label: `Photovoltaïque ${labelComplement} - ${this._stringifyPeakPower()}`,
            totalPowerPeak: this.solarPvTotalityService.currentResult.peakPower,
            // csvData: this._setCsvData(),
            htmlInstallation: this.htmlPvInstallation,
            htmlInvestment: this.htmlPvInvestment,
            htmlProduction: this.htmlPvProduction,
            htmlEconomy: this.htmlPvEconomy,
            moduleAreaStringified: moduleAreaStringified,
            peakPowerStringified: this._stringifyPeakPower(),
            investmentStringified: this._stringifyInvestment(),
            sellingGainStringified: this._stringifySellingGain(),
            roiStringified: this._stringifyRoi(),
            htmlProductionDetail: this.htmlPvProductionDetail,
            htmlConsumptionDetail: this.htmlPvConsumptionDetail,
        };
        this.solarService.addResult(result);
        this.solarService.updateMainStage('resultsStage');
    }

    updateModuleCountForSell() {
        this.initConnectionCost();

        const result = this.solarPvTotalityService.findResultByModuleCount(this.slider.moduleCount);
        this.solarPvTotalityService.setCurrentResult(result);

        this.slider.moduleCount = result.moduleCount;
        this.slider.moduleArea = this.usefulService.round(result.totalModuleArea);
        this.slider.peakPower = result.peakPower;
        this.slider.stringifiedPeakPower = this.usefulService.stringifyNumber(
            this.usefulService.ceil(result.peakPower, 1),
        );

        this._updatePvIndicator();
    }

    clickOnPossibleInstallation(possibleInstallation: number) {
        if (possibleInstallation <= this.slider.maxPeakPower) {
            if (possibleInstallation != this.slider.peakPower) {
                this.initConnectionCost();
                this.slider.peakPower = possibleInstallation;
                this._updatePeakPower();
            }
        }
    }

    private _updatePeakPower() {
        const results = this.solarPvTotalityService.pvTotalityResults;
        const resultsBelow = results.filter((result) => result.peakPower <= this.slider.peakPower);
        const result = resultsBelow[resultsBelow.length - 1];
        this.solarPvTotalityService.setCurrentResult(result);

        this.slider.moduleCount = result.moduleCount;
        this.slider.moduleArea = this.usefulService.round(result.totalModuleArea);
        this.slider.peakPower = result.peakPower;
        this.slider.stringifiedPeakPower = this.usefulService.stringifyNumber(
            this.usefulService.ceil(result.peakPower, 1),
        );

        this._updatePvIndicator();
    }

    private _updateSellWithConnectionCost() {
        this._updatePvInvestment();
        if (this.connectionCost.value) {
            this._updatePvSellingGain();
        }
    }

    private _updatePvSellingGain() {
        const splitBySellingTag =
            this.solarPvTotalityService.pvInfo.message.economie.split('##pv_selling_gain');
        if (splitBySellingTag.length === 2) {
            let html = splitBySellingTag.join(
                `<span class="text-st-active">${this._stringifySellingGain()}</span>`,
            );

            const splitByRoiTag = html.split('##pv_roi');
            if (splitByRoiTag.length === 2) {
                html = splitByRoiTag.join(
                    `<span class="text-st-active">${this._stringifyRoi()}</span>`,
                );
            }
            html = `<span>${html}<span>`;
            this.htmlPvEconomy = html;
        }
    }

    private _stringifySellingGain() {
        const sellingGain = Math.round(this.solarPvTotalityService.sellingGain);
        const sellingGainStringified = this.usefulService.stringifyNumber(sellingGain);
        return `${sellingGainStringified}\u00a0€/an`;
    }

    private _stringifyRoi() {
        const pvRoi = Math.round(this.solarPvTotalityService.roi);
        const unit = pvRoi > 1 ? 'ans' : 'an';
        const pvRoiStringified = this.usefulService.stringifyNumber(pvRoi);
        return `${pvRoiStringified} ${unit}`;
    }

    openInfoModal(body: string, title: string) {
        this.modalService.dismissAll();
        const modalRef = this.modalService.open(CadastreSolaireInfoModalComponent, { size: 'xs' });
        modalRef.componentInstance.body = body;
        modalRef.componentInstance.title = title;
    }

    private _displayWarningSubcribedPower(): boolean {
        const subscribedPowerValue = this.solarPvTotalityService.subscribedPower;
        const peakPowerValue = this.solarPvTotalityService.currentResult.peakPower;
        return subscribedPowerValue < peakPowerValue;
    }
}
