import { Inject, Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

import { AuthService } from 'src/app/services/AuthService';
import { CatalogService } from 'src/app/services/catalog.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { DataService } from 'src/app/services/DataService';
import { FilterDataService } from 'src/app/services/FilterDataService';
import { LegendService } from './legend.service';
import { LoaderService } from 'src/app/services/LoaderService';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ModuleService } from 'src/app/services/module.service';
import { PlotIndicatorService } from 'src/app/services/plotIndicator/plot-indicator.service';
import { ProsperReseauxClearService } from 'src/app/services/prosper-reseaux/prosper-reseaux-clear.service';
import { ScenariosService } from 'src/app/services/ScenariosService';
import { SolarService } from './cadastre-solaire/cadastre-solaire.service';
import { SolarPvSurplusService } from './cadastre-solaire/cadastre-solaire-pv-surplus.service';
import { TerService } from 'src/app/services/TerService';
import { ThemeService } from 'src/app/services/ThemeService';

('use strict');

@Injectable({
    providedIn: 'root',
})
export class ClearService {
    constructor(
        @Inject(AuthService) private authService: AuthService,
        @Inject(CatalogService) private catalogService: CatalogService,
        @Inject(DashboardService) private dashboardService: DashboardService,
        @Inject(DataService) private dataService: DataService,
        @Inject(FilterDataService) private filterDataService: FilterDataService,
        @Inject(LegendService) private legendService: LegendService,
        @Inject(LoaderService) private loaderService: LoaderService,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(ModuleService) private moduleService: ModuleService,
        @Inject(PlotIndicatorService) private plotIndicatorService: PlotIndicatorService,
        @Inject(ProsperReseauxClearService) private prClearService: ProsperReseauxClearService,
        @Inject(ScenariosService) private scenariosService: ScenariosService,
        @Inject(SolarService) public solarService: SolarService,
        @Inject(SolarPvSurplusService) public solarPvSurplusService: SolarPvSurplusService,
        @Inject(TerService) private terService: TerService,
        @Inject(ThemeService) private themeService: ThemeService,
    ) {}

    clearApp() {
        this.localStorageService.clear();
        this.dataService.clear();
        this.filterDataService.clear();
        this.terService.clear();
        this.loaderService.clear();
        this.authService.clear();
        this.plotIndicatorService.clear();
        this.prClearService.clear();
        this.moduleService.clear();
        this.catalogService.clear();
        this.dashboardService.clear();
        this.themeService.clear();
        this.legendService.clear();
    }

    clearAfterLogOut() {
        this.localStorageService.clear();
        this.authService.clear();
        this.dataService.clear();
        this.filterDataService.clear();
        this.terService.clear();
        this.loaderService.clear();
        this.scenariosService.clear();
        this.plotIndicatorService.clear();
        this.prClearService.clear();
        this.moduleService.clear();
        this.catalogService.clear();
        this.dashboardService.clear();
        this.themeService.clear();
        this.solarService.clear();
        this.solarPvSurplusService.clear();

        // to reinit header when logout from stopexclusion
        this.localStorageService.set('profile', environment.name);
    }
}
