('use strict');

import {
    Component,
    Input,
    TemplateRef,
    ContentChild,
    OnInit,
    OnChanges,
    SimpleChanges,
} from '@angular/core';

@Component({
    selector: 'stAccordion',
    templateUrl: './st-accordion.template.html',
    styleUrls: ['./st-accordion.component.scss'],
})
export class StAccordionComponent implements OnInit, OnChanges {
    @ContentChild('contentTemplate') contentTemplate: TemplateRef<any>;
    @Input() items: any[];
    @Input() cardClass: string;
    @Input() innerId: string = 'st-accordion';
    @Input() activeIds: string[] | 'all' | 'first' = 'all';

    public actualActiveIds: string[];

    ngOnInit() {
        if (this.activeIds === 'all') {
            this.actualActiveIds = this.items.map((_, index) => `${this.innerId}-${index}`);
        }
        if (this.activeIds === 'first') {
            this.actualActiveIds = [`${this.innerId}-0`];
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.items && !changes.items.firstChange) {
            this.items = changes.items.currentValue;
            if (this.activeIds === 'all') {
                this.actualActiveIds = this.items.map((_, index) => `${this.innerId}-${index}`);
            }
            if (this.activeIds === 'first') {
                this.actualActiveIds = [`${this.innerId}-0`];
            }
        }
    }

    updateActiveIds() {
        this.actualActiveIds = this.items.map((_, index) => `${this.innerId}-${index}`);
    }
}
