import { Inject, Injectable } from '@angular/core';

import { GeojsonInfoTemplate } from 'src/app/models/Indicator';

import { EventService } from '../event.service';
import { FilterDataService } from 'src/app/services/FilterDataService';
import { ImportedDataService } from 'src/app/services/ImportedDataService';
import { RestService } from 'src/app/services/RestService';
import { TerService } from 'src/app/services/TerService';
import { Indicator } from 'src/app/models/Indicator';

@Injectable({
    providedIn: 'root',
})
export class PlotTerritoryIndicatorService {
    public idKey = 'id_ter';

    private geojsonInfoTemplate: GeojsonInfoTemplate = {
        id: null,
        geojson: null,
        territoryScale: null,
        territoryIds: [],
        granularity: null,
    };

    private plotedIndicators: any;

    constructor(
        @Inject(EventService) private eventService: EventService,
        @Inject(FilterDataService) private filterDataService: FilterDataService,
        @Inject(ImportedDataService) private importedDataService: ImportedDataService,
        @Inject(RestService) private restService: RestService,
        @Inject(TerService) private terService: TerService,
    ) {}

    eventSelectionWrapper(plotedIndicators: any) {
        this.plotedIndicators = plotedIndicators;
        return this.selectTerritory.bind(this);
    }

    selectTerritory(e: any) {
        const layer = e.target;
        const feature = layer.feature;
        const indicatorId = feature.properties.id_indicateur;
        const indicatorPlot = this.plotedIndicators[indicatorId];
        const indicatorcontourweight = indicatorPlot.indicatorcontourweight;
        const isImported = indicatorPlot.isImported;

        if (layer && typeof layer.setStyle === 'function') {
            layer.setStyle({
                weight: indicatorcontourweight * 1.5,
                color: '#feff00',
            });
        }

        const id = feature.properties.id_ter;
        const label = feature.properties.lib_ter ? feature.properties.lib_ter : id;
        const labelId = label == id ? `${label}` : `${label} (${id})`;

        const data = {
            indicatorId: indicatorId,
            id_ter: id,
            lib_ter: label,
            label: labelId,
        };

        if (!isImported) {
            this.eventService.emit('clickOnTerritory', data);
        }
        // this.mapService.panMapBy(layer);
    }

    setGeojsonInfoId(indicatorPlot: Indicator) {
        if (!indicatorPlot.geojsonInfoId) {
            const vectorBase = indicatorPlot.vector_base;
            const year = indicatorPlot.crrsdc_ter_year_geo;
            const form = indicatorPlot.form || 'poly';

            const idParts = [vectorBase, year, form];
            const geojsonInfoId = idParts.join('_');

            indicatorPlot.geojsonInfoId = geojsonInfoId;
        }

        return indicatorPlot.geojsonInfoId;
    }

    completeGeojson(indicatorPlot: Indicator, geojson: GeoJSON.FeatureCollection) {
        const geojsonDeepCopy = JSON.parse(JSON.stringify(geojson));
        // indicatorPlot.geojson = geojsonDeepCopy;
        return geojsonDeepCopy;
    }

    async getGeojsonByTerritoryIds(
        indicatorPlot: Indicator,
        granularityScaleTypeId: number,
        granularityTerritoryIds: string[],
        geojsonsInfo: Array<any>,
        saveInGeojsonInfo: boolean = true,
    ) {
        try {
            const year = indicatorPlot.crrsdc_ter_year_geo;
            const shape = indicatorPlot.form || 'poly';

            const data = {
                perimeterScaleTypeId: this.terService.territoryScale.typeId,
                perimeterTerritoryIds: this.terService.territories.map((t) => t.id),
                granularityScaleTypeId: granularityScaleTypeId,
                granularityTerritoryIds: granularityTerritoryIds,
                year: year,
                shape: shape,
            };

            const geojson = await this.restService.getGeojsonByTerritoryIds(data);
            if (saveInGeojsonInfo && !indicatorPlot.isImported) {
                this.setGeojsonInfo(indicatorPlot, geojson, geojsonsInfo);
            }
            return geojson;
        } catch (error) {
            console.error('Erorr getGeojsonByIndicatorIds:', error);
            throw error;
        }
    }

    async getGeojson(
        indicatorPlot: Indicator,
        geojsonsInfo: Array<any>,
        saveInGeojsonInfo: boolean = true,
    ) {
        try {
            const geojson = await this.requestOrImportGeojson(indicatorPlot);
            if (saveInGeojsonInfo && !indicatorPlot.isImported) {
                this.setGeojsonInfo(indicatorPlot, geojson, geojsonsInfo);
            }
            return geojson;
        } catch (error) {
            console.error('Erorr getGeojson:', error);
            throw error;
        }
    }

    requestOrImportGeojson(indicatorPlot: Indicator) {
        const year = indicatorPlot.crrsdc_ter_year_geo;
        const granularity = indicatorPlot.granularity;
        const shape = indicatorPlot.form || 'poly';

        if (indicatorPlot.isImported) {
            const indicatorId = indicatorPlot.indicatorId;
            return this.importedDataService.getGeoJson(indicatorId, granularity, shape, year);
        }

        return this.requestGeojson(granularity, shape, year);
    }

    private async requestGeojson(granularity: any, shape: string, year: number) {
        const parameters = {
            type: granularity.type,
            year: year,
            shape: shape,
            isLabeled: false,
            orFilter: '',
        };

        if (this.terService.territoryScale) {
            const type = `${this.terService.territoryScale.type}_${year}`;
            const orFilter = this.terService.territories.map((territory) => [type, territory.id]);
            parameters.orFilter = JSON.stringify(orFilter);
        }

        try {
            const geojson = await this.restService.getGeojson(parameters);
            return geojson;
        } catch (error) {
            console.error('Error getGeojson', error);
            throw error;
        }
    }

    setGeojsonInfo(indicatorPlot: any, geojson: GeoJSON.FeatureCollection, geojsonsInfo: any) {
        const isImported = indicatorPlot.isImported;
        const granularity = indicatorPlot.granularity
            ? JSON.parse(JSON.stringify(indicatorPlot.granularity))
            : null;
        const territoryScale = isImported
            ? JSON.parse(JSON.stringify(granularity))
            : JSON.parse(JSON.stringify(this.terService.territoryScale));
        const territoryIds = indicatorPlot.isImported
            ? geojson.features.map((f: GeoJSON.Feature) => f.properties.id_ter)
            : this.terService.territories.map((t) => t.id);

        const geojsonInfo = { ...this.geojsonInfoTemplate };
        geojsonInfo.id = this.setGeojsonInfoId(indicatorPlot);
        geojsonInfo.geojson = JSON.parse(JSON.stringify(geojson));
        geojsonInfo.territoryScale = territoryScale;
        geojsonInfo.territoryIds = territoryIds;
        geojsonInfo.granularity = granularity;

        geojsonsInfo.push(geojsonInfo);
    }

    async getValues(indicatorPlot: any) {
        if (indicatorPlot.isImported) {
            return this.getValuesFromImportedData(indicatorPlot);
        }

        try {
            const indicatorId = indicatorPlot.indicatorId;
            const isFilterable = indicatorPlot.static_dynamic == 'dyn';
            const granularity = indicatorPlot.granularity;

            const data: any = {
                granularity_int: granularity.typeId, // that one is for back auth check
                scale_int: this.terService.territoryScale.typeId, // that one is for back auth check
                scale_filter: this.terService.territories.map((t) => `'${t.id}'`).join(', '), // that one is for back auth check
                year: indicatorPlot.crrsdc_ter_year_geo,
                scaleTypeId: this.terService.territoryScale.typeId,
                granularityTypeId: granularity.typeId,
                territoryIds: this.terService.territories.map((t) => t.id),
            };
            if (isFilterable) {
                data.filters = this.filterDataService.createFiltersArray(indicatorId);
            }

            indicatorPlot.dataToPlot = await this.restService.getIndicatorValues(indicatorId, data);
            return;
        } catch (error) {
            console.error('Error getValues', error);
            throw error;
        }
    }

    getValuesFromImportedData(indicatorPlot: any) {
        const indicatorId = indicatorPlot.indicatorId;
        if (!this.importedDataService.importedIndicator[indicatorId]) {
            throw 'No data in importedDataService';
        }
        indicatorPlot.dataToPlot = this.importedDataService.getData(indicatorId);
        return;
    }
}
