<div class="d-flex flex-column h-100">
    <div class="flex-grow-1 overflow-auto">
        <div class="w-100 p-3">
            <div class="header mb-3">
                <div class="container-fluid">
                    <div class="row d-flex align-items-center solar-card">
                        <div class="col-auto px-0 text-center">
                            <span class="fa-stack">
                                <i class="fa fa-circle-thin fa-stack-2x"></i>
                                <i class="fa fa-info fa-stack-1x"></i>
                            </span>
                        </div>
                        <div class="col">
                            <ng-container *ngIf="!!customElectricityConsumptionInfo">
                                <div [innerHtml]="customElectricityConsumptionInfo"></div>
                            </ng-container>
                            <ng-container *ngIf="!customElectricityConsumptionInfo">
                                <p>
                                    Votre consommation d'électricité a été estimée à partir de la
                                    description de votre bâtiment et de ses équipements ou bien
                                    représente votre consommation réelle issue des données de votre
                                    compteur.
                                </p>
                                <p>
                                    À titre d'information, la consommation moyenne d'électricité
                                    d'un foyer français se situe autour de {{ 5000 | stringifyNumber
                                    }}&nbsp;kWh par an.
                                </p>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <div class="body">
                <h5 class="text-st-primary">Ma consommation d'électricité</h5>

                <div id="housing">
                    <div
                        id="i-know-my-consumption"
                        class="container-fluid solar-card mb-3"
                        *ngIf="!solarPvSurplusService.importedElectricLoad.load.length"
                    >
                        <div class="d-flex align-items-center">
                            <stCheckbox
                                innerId="cs-electric"
                                labelClass="d-flex align-items-center"
                                InputClass="mt-0"
                                [(ngModel)]="solarPvSurplusService.userPvData.electricity.active"
                                [trueValue]="true"
                                [falseValue]="false"
                            >
                                <span>
                                    Je connais ma consommation annuelle d'électricité et je souhaite
                                    modifier la valeur estimée
                                </span>
                            </stCheckbox>
                        </div>
                    </div>

                    <div id="set-consumption" class="container-fluid solar-card">
                        <div class="row d-flex align-items-center mb-2">
                            <label
                                for="annual-consumption"
                                class="col-xl control-label text-start fw-normal py-0"
                            >
                                Ma consommation annuelle d'électricité est de
                            </label>
                            <div class="col-xl-5">
                                <div class="row d-flex align-items-center">
                                    <div
                                        class="col-8"
                                        [ngClass]="{'text-end': isImportedElectricLoadDefined}"
                                    >
                                        <stInput
                                            *ngIf="!isImportedElectricLoadDefined"
                                            class="primary"
                                            type="number"
                                            innerId="annual-consumption"
                                            [(ngModel)]="solarPvSurplusService.userPvData.electricity.energyConsumed.value"
                                            [isDisabled]="!solarPvSurplusService.userPvData.electricity.active || (solarPvSurplusService.importedElectricLoad.load.length > 0)"
                                        >
                                        </stInput>
                                        <span
                                            class="text-st-active"
                                            *ngIf="isImportedElectricLoadDefined"
                                        >
                                            {{ solarPvSurplusService.importedElectricLoad.energy |
                                            stringifyNumber }}
                                        </span>
                                    </div>
                                    <div
                                        class="col-4"
                                        [ngClass]="{'text-st-active': isImportedElectricLoadDefined}"
                                    >
                                        kWh/an
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex align-items-center">
                            <label
                                for="subscribed-power"
                                class="col-xl control-label text-start fw-normal py-0"
                            >
                                Ma puissance souscrite est de
                            </label>
                            <div class="col-xl-5">
                                <div class="row d-flex align-items-center">
                                    <div class="col-8">
                                        <stInput
                                            class="primary"
                                            type="number"
                                            innerId="subscribed-power"
                                            [(ngModel)]="solarPvSurplusService.userPvData.electricity.subscribedPower.value"
                                            [isDisabled]="isSubscribedPowerDisable()"
                                        >
                                        </stInput>
                                    </div>
                                    <div class="col-4">kVA</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer border-top">
        <div class="container-fluid py-3">
            <div class="row">
                <div class="col-5">
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-default w-100 h-100"
                        btnLabel="Retour"
                        (onClick)="cancel()"
                    >
                    </stButton>
                </div>
                <div class="col-7">
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-primary w-100"
                        btnLabel="Valider ma consommation"
                        (onClick)="goToStage('simulationStage')"
                        [isDisabled]="!isHousingElectricParametersDefined()"
                    >
                    </stButton>
                </div>
            </div>
        </div>
    </div>
</div>
