'use strict';

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MapService } from 'src/app/services/map.service';
import { ModuleService } from 'src/app/services/module.service';
import { PlotCadastreSolaireIndicatorService } from 'src/app/services/plotIndicator/plot-cadastre-solaire.service';
import { SolarService } from 'src/app/services/cadastre-solaire/cadastre-solaire.service';
import { SolarPvSurplusService } from 'src/app/services/cadastre-solaire/cadastre-solaire-pv-surplus.service';
import { SolarPvTotalityService } from 'src/app/services/cadastre-solaire/cadastre-solaire-pv-totality.service';
import { SolarThermalService } from 'src/app/services/cadastre-solaire/cadastre-solaire-thermal.service';
import { UsefulService } from 'src/app/services/UsefulService';

import { ConfirmationModalComponent } from '../../../../modals/confirmation/confirmation-modal.component';

@UntilDestroy()
@Component({
    selector: 'cadastreSolaireInit',
    templateUrl: './cadastre-solaire-init.template.html',
    styleUrls: ['./cadastre-solaire-init.component.scss'],
})
export class CadastreSolaireInitComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();

    public isZoneConstraint: boolean = false;
    private showMobileUi: boolean = false;

    public showLoader: boolean = true;

    public selectedRoofTitle: string;

    private modularity: any;

    private customInfo: any;
    private pvModuleArea: any;

    public selectedElements: any[];

    constructor(
        private notification: ToastrService,
        private modalService: NgbModal,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(MapService) private mapService: MapService,
        @Inject(ModuleService) public moduleService: ModuleService,
        @Inject(PlotCadastreSolaireIndicatorService)
        private plotCadastreSolaireIndicatorService: PlotCadastreSolaireIndicatorService,
        @Inject(SolarPvTotalityService) private solarPvTotalityService: SolarPvTotalityService,
        @Inject(SolarPvSurplusService) private solarPvSurplusService: SolarPvSurplusService,
        @Inject(SolarService) public solarService: SolarService,
        @Inject(SolarThermalService) public solarThermalService: SolarThermalService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.showMobileUi = this.usefulService.showMobileUi;
        this.modularity = this.localStorageService.get('preferences').modularity.solar;

        this.isZoneConstraint = this.solarService.isZoneConstraint;
        this.selectedElements = JSON.parse(JSON.stringify(this.solarService.selectedElements));
        this.showLoader = this.selectedElements.length > 0;

        if (!this.selectedElements.length) {
            this.moduleService.closeModule('solar');
            this.solarService.updateMainStage(null);
            return;
        }

        if (this.selectedElements.length == 1) {
            await this._getCustomTerritoryInfo();
        }

        if (this.solarService.results.length == 0) {
            this._initSelectionData();
        }

        this._updateInfo();
        this.showLoader = false;

        this._listenToEvents();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private _listenToEvents() {
        this.solarService.selectedElementsObs$
            .pipe(skip(1), takeUntil(this.destroy$))
            .subscribe(async (selectedElements) => {
                this.showLoader = selectedElements.length > 0;
                this._initSelectionData();

                if (!selectedElements.length) {
                    this.moduleService.closeModule('solar');
                    this.solarService.updateMainStage(null);
                    return;
                }

                if (selectedElements.length == 1) {
                    await this._getCustomTerritoryInfo();
                }

                this._updateInfo();

                this.isZoneConstraint = this.solarService.isZoneConstraint;
                this.selectedElements = JSON.parse(JSON.stringify(selectedElements));
                this.showLoader = false;
            });
    }

    private async _getCustomTerritoryInfo() {
        const isDifferentTerritory = this.solarService.isDifferentTerritory();
        if (!isDifferentTerritory) {
            return;
        }

        try {
            const customInfo = await this.solarService.getSolarCustomTerritoryInfo();
            this._setCustomInfo(customInfo);
        } catch (error) {
            console.error('Error _getCustomTerritoryInfo', error);
            this.solarService.selectionInfo.showLoader = false;
            this.showLoader = false;
            this.plotCadastreSolaireIndicatorService.clearFeatureGroups();
            this.solarService.updateMainStage(null);
            this.notification.error(
                "Une erreur est survenue. Impossible d'initialiser les paramètres.",
            );
            throw error;
        }
    }

    private _setCustomInfo(customInfo: any) {
        this.customInfo = customInfo;
        this.solarService.customInfo = customInfo;

        this.pvModuleArea = this.customInfo.pv.parameter.surface_module;

        this.solarService.moduleInfo = this.customInfo.module;
        this.solarPvTotalityService.setPvInfo(this.customInfo.pv);
        this.solarPvSurplusService.setPvInfo(this.customInfo.pv);
        this.solarThermalService.setThInfo(this.customInfo.th);
    }

    removeElement(elementId: number) {
        const isAnyResultDefined = this.solarService.results.length > 0;
        const layer = this.solarService.indicatorPlot.findLayerBySiterreId(elementId);

        if (isAnyResultDefined) {
            this._openReinitSimulation(layer);
        } else {
            this._removeExistingLayer(layer);
        }
    }

    private _openReinitSimulation(layer: L.Layer) {
        const confirmationMessage = [
            'Une simulation de projet solaire est en cours.',
            'Sélectionner une autre toiture mettra fin à cette simulation.',
            'Voulez-vous continuer ?',
        ];

        const modalRef = this.modalService.open(ConfirmationModalComponent);
        modalRef.componentInstance.header = "Réinitialiser l'étude";
        modalRef.componentInstance.body = confirmationMessage.join('<br/>');
        modalRef.componentInstance.validateLabel = 'Confirmer';
        modalRef.result.then(
            (result) => this._removeExistingLayer(layer),
            (reason) => {},
        );
    }

    private _removeExistingLayer(layer: L.Layer) {
        this.plotCadastreSolaireIndicatorService.selectedExistingElementsGroup.removeLayer(layer);
    }

    private _updateInfo() {
        if (this.solarService.selectedElements.length) {
            this._instanciateSolarPv();
            this._setSelectionInfo();
            this._setSelectedRoofTitle();
        }
    }

    private _instanciateSolarPv() {
        this.solarPvTotalityService.init(this.solarService.selectedElements, this.pvModuleArea);
        this.solarPvTotalityService.calcByElement();
        this.solarPvTotalityService.calcTotal();
    }

    private _setSelectionInfo() {
        const isRoofSection = this.solarService.indicatorPlot.vector_base == 'solaire';

        const totalModuleAreaRounded = this.usefulService.round(
            this.solarPvTotalityService.totalModuleArea,
        );
        const totalModuleArea = this.usefulService.stringifyNumber(totalModuleAreaRounded);

        const totalGrossRoofAreaRounded = this.usefulService.round(
            this.solarPvTotalityService.totalGrossRoofArea,
        );
        const totalGrossRoofArea = this.usefulService.stringifyNumber(totalGrossRoofAreaRounded);

        let myRoofLabel = '';
        let directionLabel = '';
        let tiltLabel = '';

        if (this.solarService.selectedElements.length === 1) {
            const element = this.solarService.selectedElements[0];
            const elementTypeLabel = isRoofSection ? 'pan de toiture' : 'toiture';

            myRoofLabel = `Votre ${elementTypeLabel} peut recevoir jusqu'à`;

            const direction = element.directionDetail;
            const tilt = this.usefulService.round(element.tilt);
            const isFlat = element.toit_plat;
            if (isFlat) {
                directionLabel = 'de surface';
                tiltLabel = 'en toit plat.';
            } else {
                const tiltStringified = this.usefulService.stringifyNumber(tilt);
                directionLabel = `de surface principalement orientée ${direction},`;
                tiltLabel = `inclinée à ${tiltStringified}°.`;
            }
        } else {
            const elementTypeLabel = isRoofSection ? 'pans de toiture' : 'toitures';
            myRoofLabel = `Vos ${elementTypeLabel} peuvent recevoir jusqu'à`;
            directionLabel = 'de surface.';
        }

        this.solarService.initSelectionInfo();
        this.solarService.selectionInfo.myRoof = myRoofLabel;
        this.solarService.selectionInfo.totalModuleArea = totalModuleArea;
        this.solarService.selectionInfo.totalGrossRoofArea = totalGrossRoofArea;
        this.solarService.selectionInfo.direction = directionLabel;
        this.solarService.selectionInfo.tilt = tiltLabel;
        this.solarService.selectionInfo.showLoader = false;
    }

    findElementOnMap(elementId: string) {
        const layer = this.solarService.indicatorPlot.findLayerBySiterreId(elementId);
        const latLng = layer.getCenter();

        if (this.showMobileUi) {
            this.moduleService.closeModule('solar');
        }
        this._targetElement(elementId);
        this.mapService.invalidateSize();
        this.plotCadastreSolaireIndicatorService.centerMapOnLayer(latLng);
    }

    private _targetElement(elementId: string) {
        this.mapService.closeAllPopup();
        const isAnyResultDefined = this.solarService.results.length > 0;

        this.solarService.indicatorPlot.openPopup = true;
        this.solarService.indicatorPlot.targetElement(elementId, false);
        this.solarService.indicatorPlot.openPopup = !isAnyResultDefined;
    }

    startSimulation() {
        this.solarService.updateMainStage('technologyStage');
    }

    goToStage(stage: string) {
        this.solarService.updateMainStage(stage);
    }

    private _setSelectedRoofTitle(): void {
        const isRoofSection = this.solarService.indicatorPlot.vector_base == 'solaire';
        this.selectedRoofTitle = isRoofSection
            ? 'Pans de toitures sélectionnés :'
            : 'Toitures sélectionnées :';
    }

    private _initSelectionData() {
        this.solarService.initResults();
        this.solarPvSurplusService.initUserPvData();
        this.solarPvSurplusService.initImportedElectricLoad();
        this.solarPvSurplusService.initEquipments();
    }
}
