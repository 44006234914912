'use strict';

import { Component, Inject } from '@angular/core';

import { EventService } from 'src/app/services/event.service';
import { SolarService } from 'src/app/services/cadastre-solaire/cadastre-solaire.service';

@Component({
    selector: 'cadastreSolaireTechnology',
    templateUrl: './cadastre-solaire-technology.template.html',
})
export class CadastreSolaireTechnologyComponent {
    public activeTabId: number = 1;

    constructor(
        @Inject(EventService) private eventService: EventService,
        @Inject(SolarService) public solarService: SolarService,
    ) {
        this.activeTabId = this.solarService.technology == 'pv' ? 1 : 2;
    }

    setTechnology(technology: string) {
        this.solarService.technology = technology;
        this.activeTabId = technology == 'pv' ? 1 : 2;
    }

    validate() {
        if (this.solarService.technology == 'thermal') {
            this.solarService.updateMainStage('thermalStage');
        } else {
            this.solarService.updateMainStage('pvStage');
        }
    }

    cancel() {
        const isNew = this.solarService.checkIfSelectedElementsIsNew();
        if (isNew) {
            if (this.solarService.results.length) {
                this.solarService.updateMainStage('resultsStage');
                return;
            } else {
                this.solarService.updateMainStage('newRoofStage');
                return;
            }
        }
        this.solarService.updateMainStage('initStage');
    }
}
