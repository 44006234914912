<div class="container-fluid p-3">
    <ng-container *ngFor="let plot of plots; let index = index">
        <div class="card st-card">
            <div class="card-body container-fluid">
                <div>
                    <h5 class="card-title d-flex align-items-center">
                        <span>
                            {{ plot.indicator.libelle_indic_complet }}
                        </span>
                        <i class="fa fa-spinner fa-pulse fa-fw ms-auto text-st-active" *ngIf="plot.isLoading"></i>
                    </h5>
                </div>

                <div class="row d-flex align-items-center gx-2" [ngClass]="{'mb-3': !!plot.chart}">
                    <label for="filters-dropdown" class="col-auto control-label py-0">
                        Afficher par :
                    </label>
                    <div ngbDropdown class="col">
                        <button ngbDropdownToggle type="button" class="btn btn-st-default w-100 text-wrap"
                            [ngClass]="{'disabled': plot.isLoading}" [disabled]="plot.isLoading">
                            <ng-container *ngIf="!plot.chart">
                                Sélectionner
                            </ng-container>
                            <ng-container *ngIf="!!plot.chart">
                                {{ plot.chart.categoryLabel }}
                            </ng-container>
                        </button>
                        <div ngbDropdownMenu class="w-100 text-center">
                            <ng-container *ngIf="!!filtersByIndicatorId[plot.indicatorId]">
                                <ng-container
                                    *ngFor="let filter of filtersByIndicatorId[plot.indicatorId] | sortBy : 'id'">
                                    <button ngbDropdownItem class="text-wrap"
                                        (click)="setChart(plot.indicatorId, filter.id)">
                                        <small>{{ filter.label }}</small>
                                    </button>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                    <stButton class="col-auto" btnClass="btn-st-default" iconFaRight="fa fa-download"
                        [isDisabled]="!plot.chart" (onClick)="downloadChartCsv(plot)" ngbTooltip="Export des données"
                        placement="auto" container="body"></stButton>
                </div>

                <div class="position-relative" *ngIf="!!plot.chart">
                    <stBarChart [yAxisLabel]="plot.chart.yAxisLabel" [labels]="plot.chart.xAxislabels"
                        [series]="plot.chart.series" [data]="plot.chart.datasets">
                    </stBarChart>
                </div>
            </div>
        </div>
    </ng-container>
</div>