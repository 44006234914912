('use strict');

import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Control, ControlPosition, DomEvent, DomUtil, Map } from 'leaflet';

import { EventService } from 'src/app/services/event.service';
import { MapService } from 'src/app/services/map.service';

@Injectable({
    providedIn: 'root',
})
export class LegendService {
    public legendObs = new BehaviorSubject<any>(null);
    legend$: Observable<any> = this.legendObs.asObservable();

    public legendControl: Control;
    private position: ControlPosition = 'bottomleft';

    constructor(
        @Inject(EventService) private eventService: EventService,
        @Inject(MapService) private mapService: MapService,
    ) {}

    clear() {
        this._clearObservables();
    }

    private _clearObservables() {
        this.legendObs.next(null);
    }

    initControl() {
        const LegendControl = Control.extend({
            onAdd(map: Map) {
                const element = DomUtil.get('legend-control');
                DomEvent.disableClickPropagation(element);
                return element;
            },
            onRemove(map: Map) {},
        });
        this.legendControl = new LegendControl({
            position: this.position,
        });
    }

    addControlToMap() {
        // we need the legend to be at the bottom of the screen under every other control that might be above it
        // (ie : lomia button for mobile)
        // leaflet adds control in the reverse order so we wait that all other control to be instanciated
        setTimeout(() => this.legendControl.addTo(this.mapService.map), 500);
    }

    removeControlFromMap() {
        this.mapService.map.removeControl(this.legendControl);
    }
}
