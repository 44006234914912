'use strict';

import { Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { HousingDefaultConsumption } from 'src/app/models/Solar';

import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SolarPvSurplusService } from 'src/app/services/cadastre-solaire/cadastre-solaire-pv-surplus.service';
import { SolarService } from 'src/app/services/cadastre-solaire/cadastre-solaire.service';
import { UsefulService } from 'src/app/services/UsefulService';

@Component({
    selector: 'cadastreSolairePvSurplusConsumption',
    templateUrl: './cadastre-solaire-pv-surplus-consumption.template.html',
    styleUrls: ['./cadastre-solaire-pv-surplus-consumption.component.scss'],
})
export class CadastreSolairePvSurplusConsumptionComponent implements OnInit {
    public showMobileUi: boolean = false;

    private modularity: any;

    public customElectricityConsumptionInfo: string;
    public isImportedElectricLoadDefined: boolean = false;

    constructor(
        private notification: ToastrService,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(SolarPvSurplusService)
        public solarPvSurplusService: SolarPvSurplusService,
        @Inject(SolarService) public solarService: SolarService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.showMobileUi = this.usefulService.showMobileUi;
        this.modularity = this.localStorageService.get('preferences').modularity.solar;

        if (!this.solarPvSurplusService.userPvData.electricity.active) {
            this.isImportedElectricLoadDefined =
                this.solarPvSurplusService.importedElectricLoad.load.length > 0;

            if (this.isImportedElectricLoadDefined) {
                this.solarPvSurplusService.userPvData.electricity.energyConsumed.value =
                    this.solarPvSurplusService.importedElectricLoad.energy;
                this.solarPvSurplusService.userPvData.electricity.subscribedPower.value =
                    this.solarPvSurplusService.importedElectricLoad.subscribedPower;
            } else {
                const energyConsumedValue =
                    this.solarPvSurplusService.userPvData.electricity.energyConsumed.value;
                const subscribedPowerValue =
                    this.solarPvSurplusService.userPvData.electricity.subscribedPower.value;

                if (!energyConsumedValue || !subscribedPowerValue) {
                    await this._getHousingElectricityParameters();
                }
            }
        }
    }

    cancel() {
        if (this.isImportedElectricLoadDefined) {
            this.solarPvSurplusService.updateStage('importStage');
        } else {
            this.solarPvSurplusService.updateStage('equipmentsStage');
        }
    }

    public goToStage(stage: string) {
        this.solarPvSurplusService.updateStage(stage);
    }

    private async _getHousingElectricityParameters() {
        try {
            const data: HousingDefaultConsumption =
                await this.solarPvSurplusService.getHousingElectricityConsumption(
                    this.solarPvSurplusService.userPvData,
                );

            const electricEnergyConsumed = this.usefulService.round(data.electricEnergyConsumed);
            const subscribedPower = this.usefulService.ceil(data.subscribedPower);

            this.solarPvSurplusService.userPvData.housing.parameters.usages.forEach(
                (usage: any) => (usage.active = data.usages.includes(usage.value)),
            );
            this.solarPvSurplusService.userPvData.electricity.energyConsumed.value =
                electricEnergyConsumed;
            this.solarPvSurplusService.userPvData.electricity.subscribedPower.value =
                subscribedPower;
        } catch (error) {
            console.error('Error _getHousingElectricityParameters', error);
            this.notification.error(
                "Une erreur est survenue. Impossible d'initialiser les paramètres.",
            );
        }
    }

    isHousingElectricParametersDefined(): boolean {
        const electricEnergyConsumed =
            this.solarPvSurplusService.userPvData.electricity.energyConsumed.value;
        const subscribedPower =
            this.solarPvSurplusService.userPvData.electricity.subscribedPower.value;

        const isPostive = parseFloat(electricEnergyConsumed) > 0 && parseFloat(subscribedPower) > 0;
        return isPostive;
    }

    isSubscribedPowerDisable(): boolean {
        if (this.isImportedElectricLoadDefined) {
            return false;
        }
        return !this.solarPvSurplusService.userPvData.electricity.active;
    }
}
