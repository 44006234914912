'use strict';

import { Inject, Injectable } from '@angular/core';
import * as L from 'leaflet';

import {
    TerritoryScale,
    Territory,
    TerritoryLabel,
    TerritoryLabelByYear,
} from '../models/TerritoryTypes';

import { EventService } from './event.service';
import { LoaderService } from 'src/app/services/LoaderService';
import { MapService } from './map.service';
import { RestService } from './RestService';
import { LocalStorageService } from './local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class TerService {
    private territoryLabelByYear: TerritoryLabelByYear = {};

    public territoryLayers: L.FeatureGroup;
    public territoryLabeledLayers: L.FeatureGroup;
    public territoryScaleLayers: any = null;
    public territoryScaleLabeledLayers: any = null;

    public territoryScales: TerritoryScale[];
    public geoYears: Array<number> = [2020];

    public geoYear: number = 2020;
    public territoryScale: TerritoryScale;
    public territories: Territory[];
    public granularity: TerritoryScale;

    constructor(
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(EventService) private eventService: EventService,
        @Inject(LoaderService) private loaderService: LoaderService,
        @Inject(MapService) private mapService: MapService,
        @Inject(RestService) private restService: RestService,
    ) {
        this.clear();
    }

    clear() {
        this.territoryLabelByYear = {};
        this.geoYear = Math.max(...this.geoYears);
        this.territoryScale = null;
        this.territories = [];
        this.granularity = null;
    }

    async initTerritory(isAddressDefined: boolean = false, drawTerritory: boolean = true) {
        try {
            const preferences = this.localStorageService.get('preferences');
            const year = preferences.territory.year;

            await this.loadGeoYears();
            this.geoYear = year ? year : Math.max(...this.geoYears);

            await this.loadTerritoryScales();
            if (this.mapService.map) {
                this.initTerritoryLayers();
            }

            await this.updateFromUserPreference();
            if (isAddressDefined) {
                await this.updateFromAddress();
            }

            if (drawTerritory) {
                const preferences = this.localStorageService.get('preferences');
                const territoryPreference = preferences.territory;

                if (territoryPreference.drawTerritory) {
                    const territoryScale = this.territoryScales.find(
                        (t: TerritoryScale) => t.typeId == territoryPreference.scaleTypeId,
                    );
                    // no need to wait until geojson is loaded to continue
                    this.addTerritoryLayer(territoryScale);
                }
            }
            return;
        } catch (error) {
            console.error('Error initialisation', error);
            throw error;
        }
    }

    async loadGeoYears() {
        try {
            this.geoYears = await this.restService.getGeoYears();
            return;
        } catch (error) {
            console.error('Error loadGeoYears', error);
            throw error;
        }
    }

    async loadTerritoryScales(year: number = undefined, save: boolean = true) {
        const parameters = {
            year: year || this.geoYear,
        };
        try {
            const territoryScales = await this.restService.getGeoTerritoryScales(parameters);
            this.setFrontAttributes(territoryScales);
            territoryScales.sort((a: TerritoryScale, b: TerritoryScale) => b.order - a.order);

            if (save) {
                this.territoryScales = territoryScales;
            }
            return territoryScales;
        } catch (error) {
            console.error('Error loadTerritoryScales', error);
            throw error;
        }
    }

    setFrontAttributes(territoryScales: TerritoryScale[]) {
        territoryScales.forEach((territoryScale: TerritoryScale) => {
            territoryScale.type = territoryScale.id_terr;
            territoryScale.typeId = territoryScale.id_terr_int;
            territoryScale.labelLong = territoryScale.libelle_long;
            territoryScale.labelShort = territoryScale.libelle_short;
            territoryScale.order = territoryScale.ordre;
            territoryScale.isLabeled = false;
            territoryScale.isLoading = false;
            territoryScale.isLabelLoading = false;
            territoryScale.isLabelPloted = false;
            territoryScale.isPloted = false;
        });
    }

    saveTerritoryScales(territoryScales: TerritoryScale[]) {
        this.territoryScales = territoryScales;
    }

    updateGeoYear(geoYear: number) {
        this.geoYear = geoYear;
    }

    updateTerritoryScale(territoryScale: TerritoryScale) {
        this.territoryScales.forEach((t: TerritoryScale) => (t.isPloted = false));
        this.territoryScale = this.territoryScales.find((t) => t.id == territoryScale.id);
        this.territoryScale.isPloted = true;
    }

    updateTerritories(territories: Territory[]) {
        this.territories = territories;
    }

    updateGranularity(granularity: TerritoryScale) {
        this.granularity = granularity;
    }

    async updateFromUserPreference() {
        const preferences = this.localStorageService.get('preferences');
        const territoryPreference = preferences.territory;

        const geoYear = territoryPreference.year || this.geoYear;
        this.updateGeoYear(geoYear);

        const territoryScale = this.territoryScales.find(
            (t: TerritoryScale) => t.typeId == territoryPreference.scaleTypeId,
        );
        this.updateTerritoryScale(territoryScale);

        let granularity = this.territoryScales.find(
            (t: TerritoryScale) => t.typeId == territoryPreference.granularityTypeId,
        );
        if (!granularity) {
            granularity = this.territoryScales.find(
                (t: TerritoryScale) => t.ordre == territoryScale.ordre - 1,
            );
        }
        this.updateGranularity(granularity);

        const territoryIds = territoryPreference.territoryIds;
        const territories = territoryIds.map((id: string) => {
            return { id: id, label: '' };
        });
        this.updateTerritories(territories);
        await this.updateTerritoryLabels();
    }

    async updateFromAddress() {
        const feature = this.localStorageService.get('address');
        const cityCode = feature.properties.citycode;
        const longitude = (feature.geometry as GeoJSON.Point).coordinates[0];
        const latitude = (feature.geometry as GeoJSON.Point).coordinates[1];

        const newTerritory = await this.getTerritory(cityCode, longitude, latitude);
        const isAlreadyDefined = this.territories.some(
            (territory) => territory.id == newTerritory.id,
        );
        if (!isAlreadyDefined) {
            await this.replaceTerritories([newTerritory]);
        }
    }

    async replaceTerritories(newTerritories: Territory[]) {
        this.updateTerritories(newTerritories);
        await this.updateTerritoryLayer();
        this.eventService.emit('searchAddressSelected');
    }

    async getTerritory(cityCode: string, longitude: number, latitude: number) {
        try {
            const parameters = {
                cityCode: cityCode,
                scaleTypeId: this.territoryScale.typeId,
                year: this.geoYear,
                longitude: longitude,
                latitude: latitude,
            };

            const response: any = await this.restService.searchTerritory(parameters);
            return response;
        } catch (error) {
            console.error('Error getTerritory', error);
            throw error;
        }
    }

    async updateTerritoryLabels() {
        const parameters = {
            year: this.territoryScale.year,
            territoryScaleType: this.territoryScale.type,
            territoryIds: JSON.stringify(this.territories.map((t) => t.id)),
        };
        try {
            const labels = await this.restService.getGeoTerritoryLabels(parameters);
            this.territories.forEach(
                (t, i) => (t.label = labels[i] ? labels[i] : 'Territoire personnalisé'),
            );
            return;
        } catch (error) {
            console.error('Error updateTerritoryLabels', error);
            throw error;
        }
    }

    async getTerritoryLabelByYear(
        year: number,
        territoryType: string,
        scaleType?: string,
        territoryIds: Array<string> = [],
    ) {
        let territoryTypeYear: string = `${territoryType}_${year}`;

        if (territoryType === 'ter_01' && !scaleType) {
            scaleType = this.territoryScale.type;
            territoryIds = this.territories.map((t) => t.id);
        }
        if (scaleType && territoryIds.length) {
            territoryTypeYear += `_${scaleType}_${territoryIds.join(',')}`;
        }

        if (
            this.territoryLabelByYear[territoryTypeYear] &&
            territoryTypeYear.substring(0, 5) != 'ter_0'
        ) {
            return this.territoryLabelByYear[territoryTypeYear];
        }

        const addLabelId = (terArray: TerritoryLabel[]) => {
            return terArray.map((ter: TerritoryLabel) => {
                ter.id = ter.id_ter;
                ter.label = ter.lib_ter;
                ter.labelId = `${ter.label} (${ter.id})`;
                return ter;
            });
        };

        const parameters = {
            year: year,
            type: territoryType,
            typeFilter: '',
            territoryIds: '',
        };
        if (scaleType && territoryIds.length) {
            parameters.typeFilter = scaleType;
            parameters.territoryIds = JSON.stringify(territoryIds);
        }
        try {
            const labels = await this.restService.getGeoLabels(parameters);
            const territoryLabel = addLabelId(labels);
            this.territoryLabelByYear[territoryTypeYear] = territoryLabel;
            return territoryLabel;
        } catch (error) {
            console.error('Error getTerritoryLabelByYear', error);
            throw error;
        }
    }

    initTerritoryLayers() {
        this.territoryScaleLayers = {};
        this.territoryScales.forEach((t: TerritoryScale) => {
            const layers = L.featureGroup().addTo(this.mapService.map);
            this.territoryScaleLayers[t.typeId] = layers;
        });

        this.territoryScaleLabeledLayers = {};
        this.territoryScales.forEach((t: TerritoryScale) => {
            const layers = L.featureGroup().addTo(this.mapService.map);
            this.territoryScaleLabeledLayers[t.typeId] = layers;
        });
    }

    async toggleTerritoryLayer(territoryScale: TerritoryScale) {
        let isAlreadyPloted = false;
        let layer: L.Layer;
        this.territoryLayers.eachLayer((lay: any) => {
            if (lay.territoryScale.id == territoryScale.id) {
                isAlreadyPloted = true;
                layer = lay;
            }
        });
        if (isAlreadyPloted) {
            this.removeTerritoryScaleLayers(territoryScale);
            return;
        }
        await this.addTerritoryLayer(territoryScale);
    }

    getLayersByTerritoryScaleType(territoryScale: TerritoryScale, labeled: boolean = false) {
        const territoryScaleTypeId = territoryScale.typeId;

        if (labeled) {
            return this.territoryScaleLabeledLayers[territoryScaleTypeId];
        }
        return this.territoryScaleLayers[territoryScaleTypeId];
    }

    removeTerritoryScaleLayers = (territoryScale: TerritoryScale) => {
        const featureGroup = this.getLayersByTerritoryScaleType(territoryScale);

        territoryScale.isPloted = false;
        featureGroup.clearLayers();
    };

    async addTerritoryLayer(territoryScale: TerritoryScale) {
        this.loaderService.start('drawTerritory');

        const parameters = {
            shape: 'line',
            type: territoryScale.type,
            year: this.geoYear,
            orFilter: '',
            isSimplified: JSON.stringify(territoryScale.type == 'ter_99'),
        };

        if (this.territoryScale) {
            const type = this.territoryScale.tbl_data;
            const orFilter = this.territories.map((t) => [type, t.id]);
            parameters.orFilter = JSON.stringify(orFilter);
        }

        try {
            const geojson = await this.restService.getGeojson(parameters);
            this.setLayer(territoryScale, geojson);
            // reorganizeLayersG();
        } catch (error) {
            console.error('Error addTerritoryLayer', error);
            return error;
        } finally {
            this.loaderService.stop('drawTerritory');
        }
    }

    async updateTerritoryLayer() {
        this.clearTerritoryLayers();
        const territoryScale = this.territoryScales.find(
            (territoryScale: TerritoryScale) =>
                territoryScale.type == this.territoryScale.type && !!territoryScale.geom_line,
        );
        await this.addTerritoryLayer(territoryScale);
    }

    setLayer(territoryScale: any, geojson: any) {
        const style = territoryScale.style;
        style.weight = 2;

        const layer = L.geoJson(geojson, {
            style: style,
        });

        territoryScale.isPloted = true;
        this.territoryScaleLayers[territoryScale.typeId].addLayer(layer);
    }

    removeTerritoryLabeledLayer = (territoryScale: TerritoryScale) => {
        const featureGroup = this.getLayersByTerritoryScaleType(territoryScale, true);

        territoryScale.isLabelPloted = false;
        featureGroup.clearLayers();
    };

    async addTerritoryLabeledLayer(territoryScale: any) {
        const parameters = {
            shape: 'point',
            type: territoryScale.type,
            year: this.geoYear,
            orFilter: '',
        };

        if (this.territoryScale) {
            const type = this.territoryScale.tbl_data;
            const orFilter = this.territories.map((territory) => [type, territory.id]);
            parameters.orFilter = JSON.stringify(orFilter);
        }

        try {
            const geojson = await this.restService.getGeojson(parameters);
            this.setLabeledLayer(territoryScale, geojson);
            // reorganizeLayersG();
            return;
        } catch (error) {
            console.error('Error addTerritoryLabeledLayer', error);
            return error;
        }
    }

    setLabeledLayer(territoryScale: TerritoryScale, geojson: any) {
        const style = territoryScale.style;
        style.weight = 2;

        const layer = L.geoJson(geojson, {
            style: style,
            pointToLayer: (feature: any, latlng: L.LatLng) => {
                return L.marker(latlng, {
                    icon: L.divIcon({
                        className: 'label',
                        html: `
                            <div style="color: ${style.color}; opacity: ${style.opacity};">
                                ${feature.properties.lib_ter}
                            </div>
                            `,
                        iconSize: [100, 40],
                    }),
                });
            },
        });

        territoryScale.isLabelPloted = true;
        this.territoryScaleLabeledLayers[territoryScale.typeId].addLayer(layer);
    }

    updateLayerStyle(territoryScale: TerritoryScale) {
        const style = territoryScale.style;
        const featureGroup = this.getLayersByTerritoryScaleType(territoryScale);
        featureGroup.setStyle(style);
    }

    clearTerritoryLayers() {
        for (let typeId in this.territoryScaleLayers) {
            this.territoryScaleLayers[typeId].clearLayers();
        }
        for (let typeId in this.territoryScaleLabeledLayers) {
            this.territoryScaleLabeledLayers[typeId].clearLayers();
        }
    }

    flyToLayerBounds() {
        const typeId = this.territoryScale.typeId;
        this.mapService.flyToBounds(this.territoryScaleLayers[typeId].getBounds(), true);
    }

    flyToLabeledLayerBounds() {
        const typeId = this.territoryScale.typeId;
        this.mapService.flyToBounds(this.territoryScaleLabeledLayers[typeId].getBounds(), true);
    }

    getUserMinScale() {
        const userMinScale = this.territoryScales.reduce(
            (minGranularity, scale) =>
                !minGranularity || scale.order < minGranularity.order ? scale : minGranularity,
            null,
        );
        return userMinScale;
    }

    getUserMaxScale() {
        const userMaxScale = this.territoryScales.reduce(
            (maxGranularity, scale) =>
                !maxGranularity || scale.order > maxGranularity.order ? scale : maxGranularity,
            null,
        );
        return userMaxScale;
    }
}
