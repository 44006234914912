('use strict');

import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { of, from, lastValueFrom, map, catchError } from 'rxjs';

import { Indicator } from 'src/app/models/Indicator';
import { TerritoryLabel } from 'src/app/models/TerritoryTypes';
import { TerritoryScaleId } from 'src/app/shared/enums/territory-scale.enum';

import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MapService } from 'src/app/services/map.service';
import { PlotIndicatorService } from 'src/app/services/plotIndicator/plot-indicator.service';
import { RestService } from 'src/app/services/RestService';
import { TerService } from 'src/app/services/TerService';

@Injectable({
    providedIn: 'root',
})
export class SearchService {
    public addressFeature: GeoJSON.Feature;

    public isSearchAddressAvailable: boolean;
    public isSearchGeolocationAvailable: boolean;
    public isSearchParcelAvailable: boolean;
    public isSearchTerritoryAvailable: boolean;

    constructor(
        private http: HttpClient,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(MapService) private mapService: MapService,
        @Inject(RestService) private restService: RestService,
        @Inject(TerService) private terService: TerService,
    ) {}

    setIsSearchAvailable() {
        const preferences = this.localStorageService.get('preferences');
        if (preferences) {
            const modulesAccess = preferences.modules;

            this.isSearchAddressAvailable = modulesAccess.searchAddress;
            this.isSearchGeolocationAvailable = modulesAccess.searchGeolocation;
            this.isSearchParcelAvailable = modulesAccess.searchParcel;
            this.isSearchTerritoryAvailable = modulesAccess.searchTerritory;
        }
    }

    toNoCase(item: string) {
        if (!item) {
            return ' ';
        }

        let temp = String(item).toLowerCase();

        const nonAsciis = {
            a: '[àáâãäå]',
            ae: 'æ',
            c: 'ç',
            e: '[èéêë]',
            i: '[ìíîï]',
            n: 'ñ',
            o: '[òóôõö]',
            oe: 'œ',
            u: '[ùúûűü]',
            y: '[ýÿ]',
        };

        for (let i in nonAsciis) {
            temp = temp.replace(new RegExp(nonAsciis[i], 'g'), i);
        }
        // temp = temp.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, '\\$&');
        temp = temp.replace(/[a\341\301\340\300\342\302\344\304]/gi, 'a');
        temp = temp.replace(/[e\351\311\350\310\352\312\353\313]/gi, 'e');
        temp = temp.replace(/[i\355\315\354\314\356\316\357\317]/gi, 'i');
        temp = temp.replace(/[o\363\323\362\322\364\324\366\326]/gi, 'o');
        temp = temp.replace(/[u\372\332\371\331\373\333\374\334]/gi, 'u');
        temp = temp.replace(/[c\347\307]/gi, 'c');
        temp = temp.replace(/['+\-]/gi, ' ');
        temp = temp.replace(/[(\)\.\,]/gi, '');
        return temp;
    }

    searchAddress(address: string) {
        if (address.replaceAll(' ', '').length < 3) {
            return of([]);
        }
        return from(this.getAddress(address)).pipe(
            map((response) => response),
            catchError(() => of([])),
        );
    }

    searchParcel(parcelId: string) {
        if (parcelId.replaceAll(' ', '').length < 3) {
            return of([]);
        }

        return from(this.getParcel(parcelId)).pipe(
            map((response) => response.features),
            catchError(() => of([])),
        );
    }

    setAddress(feature: GeoJSON.Feature) {
        this.addressFeature = feature;
        this.localStorageService.set('address', feature);
    }

    addMarker(geometry: GeoJSON.Point) {
        const lat = geometry.coordinates[1];
        const lng = geometry.coordinates[0];
        this.mapService.addMarkerAddress(lat, lng);
        this.mapService.centerOn(lat, lng, 18, false);
    }

    async getAddress(address: string) {
        try {
            /*
            const url = 'https://api-adresse.data.gouv.fr/search/';
            const params = new HttpParams().append('q', address.trim());

            const response: any = await lastValueFrom(this.http.get(url, { params }));*/
            //we look for the closest addresses as a priority
            let center = { latitude: null, longitude: null };
            try {
                center = this.mapService.getCenterOfMap();
            } catch (e) {
                console.warn('Map is not initialized');
            }

            let cityCode = null;
            if (
                this.terService.territoryScale &&
                [TerritoryScaleId.CITY, TerritoryScaleId.DISTRICT].includes(
                    this.terService.territoryScale.typeId,
                )
            ) {
                cityCode = this.terService.territories[0].id;
            }

            const parameters = {
                address: address.trim(),
                latitude: center.latitude,
                longitude: center.longitude,
                cityCode: cityCode,
                year: this.terService.geoYear,
            };
            const response: any = await this.restService.searchAddress(parameters);
            return response;
        } catch (error) {
            console.error('Error getAddress', error);
            throw error;
        }
    }

    async reverseAddress(latitude: number, longitude: number) {
        try {
            // const url = `https://api-adresse.data.gouv.fr/reverse/`;
            // const params = new HttpParams().append('lon', longitude).append('lat', latitude);

            // const response: any = await lastValueFrom(this.http.get(url, { params }));

            const parameters = {
                latitude: latitude,
                longitude: longitude,
            };
            const response: any = await this.restService.searchReverseAddress(parameters);
            return response;
        } catch (error) {
            console.error('Error reverseAddress', error);
            throw error;
        }
    }

    async getParcel(parcelId: string) {
        try {
            const parameters = {
                parcelId: parcelId.trim(),
                scaleTypeId: this.terService.territoryScale.typeId,
                territories: JSON.stringify(this.terService.territories.map((t) => t.id)),
            };

            const response: any = await this.restService.searchParcel(parameters);
            return response;
        } catch (error) {
            console.error('Error getParcel', error);
            throw error;
        }
    }

    async getTerritory(cityCode: string) {
        try {
            const parameters = {
                cityCode: cityCode,
                scaleTypeId: this.terService.territoryScale.typeId,
                year: this.terService.geoYear,
            };

            const response: any = await this.restService.searchTerritory(parameters);
            return response;
        } catch (error) {
            console.error('Error getTerritory', error);
            throw error;
        }
    }

    selectTerritory(territory: TerritoryLabel, indicatorPlots: Indicator[]) {
        const territoryIndicatorPlots: Indicator[] = indicatorPlots.filter(
            (indicator: Indicator) => indicator.vector_base == 'territories',
        );
        if (!territoryIndicatorPlots.length) {
            return;
        }

        const indicatorPlot: Indicator = territoryIndicatorPlots.find((indicator: Indicator) =>
            indicator.geojson.features.some(
                (feature) => feature.properties.id_ter == territory.id_ter,
            ),
        );
        if (indicatorPlot) {
            const feature = indicatorPlot.geojson.features.find(
                (feature: GeoJSON.Feature) => feature.properties.id_ter == territory.id_ter,
            );
            if (feature) {
                this.mapService.zoomToFeature(feature);

                let layer: L.Layer;
                indicatorPlot.geojsonLayer.eachLayer((l: L.Layer) => {
                    if ((l as any).feature.properties.id_ter == territory.id_ter) {
                        layer = l;
                    }
                });
                if (layer) {
                    this.mapService.highlightLayer(layer);
                }
            }
        }
    }

    async selectAddress(feature: any) {
        this.setAddress(feature);
        this.addMarker(feature.geometry);
    }

    readGeolocation(successCallback: any, errorCallback: any) {
        if (!navigator.geolocation) {
            throw 'Geolocation is not available.';
        }

        navigator.geolocation.getCurrentPosition(
            async (position) => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                await successCallback(latitude, longitude);
            },
            (error) => {
                console.error('Error geolocation.getCurrentPosition', error);
                errorCallback();
                throw error;
            },
            {
                enableHighAccuracy: true,
                maximumAge: 0,
                timeout: 5000,
            },
        );
    }
}
